import { all, call, put, takeEvery, delay } from "redux-saga/effects";
import AdminAuthService from "../../services/adminAuth.service";
import CookieService from "../../services/cookie.service";
import {RESPONSE_LOADING_TIME} from "../../helpers/constant/coreConstant";
import {getAdminDataFailed, getAdminDataSuccess, loginAdminFailed, loginAdminSuccess} from "./adminAuthSlice";


function* adminAuthWatcher() {
    yield takeEvery("adminAuth/loginAdmin", loginAdminSaga);
    yield takeEvery("adminAuth/getAdminData", getAdminDataSaga);
}

function* loginAdminSaga(action) {
    try {
        const response = yield call(AdminAuthService.loginAdmin, action.payload);

        if (response.status && response.data?.token) {
            CookieService.setCookie(process.env.REACT_APP_ADMIN_ACCESS_TOKEN, response.data.token, "");

            yield delay(RESPONSE_LOADING_TIME);
            yield put(loginAdminSuccess(response.data.admin));
        } else {
            yield put(loginAdminFailed(response.message));
        }
    } catch (err) {
        console.log("Error: ", err);
        yield put(loginAdminFailed("Internal service error!"));
    }
}

function* getAdminDataSaga(action) {
    try {
        const response = yield call(AdminAuthService.getAdminData, action.payload);

        if (response.status) {
            yield put(getAdminDataSuccess(response.data.admin));
        } else {
            yield put(getAdminDataFailed(response.message));
        }
    } catch (err) {
        console.log("Error: ", err);
        yield put(loginAdminFailed("Internal service error!"));
    }
}

export default function* adminAuthSaga() {
    yield all([adminAuthWatcher()]);
}
