import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Collapse, List, ListItemText} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CategoriesActions from "./CategoriesActions";
import SubCategoriesActions from "./SubCategoriesActions";
import {ListItemButtonStyled} from "../common/ListItemButtonStyled";
import {addCategoryAndSubCategory} from "../../../state/assQuestion/assQuestionSlice";
import {getQuestionCategoryList} from "../../../state/questionCategory/questionCategorySlice";
import {selectQuestionCategoryState} from "../../../state/questionCategory/questionCategorySelector";
import {selectAssQuestionFilters} from "../../../state/assQuestion/assQuestionSelector";
import Styles from "../assessment.module.scss";

const MenuItem = ({category}) => {
    const dispatch = useDispatch();
    const {label, subCategories = []} = category || {};
    const hasSubCategories = subCategories?.length > 0;
    const {subCategoryId} = useSelector(selectAssQuestionFilters)

    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen(prevState => !prevState);

    const handleSelect = (subCategoryId) => {
        dispatch(addCategoryAndSubCategory({categoryId: category.value, subCategoryId: subCategoryId}))
    }

    return (
        <div className={Styles.categoryActions}>
            <ListItemButtonStyled onClick={toggleOpen}>
                <ListItemText primary={label} sx={{pl: 1, m: 0}}/>
                <div className={Styles.categoryActionsItem}>
                    <CategoriesActions category={category}/>
                </div>
                {open ? (
                    <ExpandLessIcon fontSize={"small"} color={"action"}/>
                ) : (
                    <ExpandMoreIcon fontSize={"small"} color={"action"}/>
                )}
            </ListItemButtonStyled>

            <Collapse in={open} timeout='auto'>
                <List
                    component='div'
                    disablePadding
                    sx={{
                        "& .MuiButtonBase-root": {
                            paddingLeft: "0px",
                            marginLeft: "15px"
                        },
                    }}
                >
                    {hasSubCategories && subCategories.map((subCategory) => (
                        <ListItemButtonStyled key={subCategory.value} onClick={() => handleSelect(subCategory.value)}
                                              selected={subCategoryId === subCategory.value}>
                            <ListItemText primary={subCategory.label} sx={{pl: 1}}/>
                            <div className={Styles.categoryActionsItem}>
                                <SubCategoriesActions categoryId={category.value} subCategory={subCategory}/>
                            </div>
                        </ListItemButtonStyled>
                    ))}
                </List>
            </Collapse>
        </div>
    )
};

export const QuestionsSidebar = () => {
    const dispatch = useDispatch();
    const {data: categoryList, isLoading} = useSelector(selectQuestionCategoryState);

    useEffect(() => {
        dispatch(getQuestionCategoryList())
    }, []);

    return (
        <List component='nav' disablePadding sx={{pr: 1}}>
            {categoryList.map((category) => <MenuItem key={category.value} category={category}/>)}
        </List>
    );
};


