import React from "react";
import {useDispatch} from "react-redux";
import {IconButton, Menu, MenuItem} from "@mui/material";
import {CustomTooltip} from "../../../common/Tooltip/CustomTooltip";
import WarningModalContent from "../../../global/Modal/ModalContent/WarningModalContent";
import BasicModalWithHeader from "../../../global/Modal/BasicModalWithHeader";
import CloseModal from "../../../global/Modal/CloseModal";
import AddOrEditQuestion from "./AddOrEditQuestion";
import useBoolean from "../../../hooks/useBoolean";
import icons from "../../../assets/Icons/icons";
import {deleteAssQuestion} from "../../../state/assQuestion/assQuestionSlice";

const ITEM_HEIGHT = 48;

const QuestionsListActions = ({questionInfo}) => {
    const dispatch = useDispatch();
    const {value: openModal, setTrue: onOpenModal, setFalse: onCloseModal} = useBoolean(false);
    const {value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal} = useBoolean(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleDeleteConfirm = () => {
        dispatch(deleteAssQuestion({id: questionInfo.id}));
        onCloseDeleteModal();
    };

    const open = Boolean(anchorEl);
    return (
        <>
            <CustomTooltip title='More options' arrow placement={"top"}>
                <IconButton onClick={handleMenuClick}>{icons.ellipsisVertical}</IconButton>
            </CustomTooltip>
            <Menu
                id='long-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "15ch",
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        onOpenDeleteModal();
                        handleMenuClose();
                    }}
                >
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onOpenModal();
                        handleMenuClose();
                    }}
                >
                    Settings
                </MenuItem>
            </Menu>
            <CloseModal open={openDeleteModal} onClose={onCloseDeleteModal} width={550}>
                <WarningModalContent
                    title={"Warning"}
                    subTitle={"Are you sure to delete this assessment"}
                    description={"If you delete this assessment, you’ll not be able to recover it again."}
                    buttonName={"Okay, Delete"}
                    onConfirm={handleDeleteConfirm}
                    onClose={onCloseDeleteModal}
                />
            </CloseModal>
            <BasicModalWithHeader
                title={"Edit Question"}
                open={openModal}
                onClose={onCloseModal}
                width={900}
                autoScroll={true}
            >
                <AddOrEditQuestion onClose={onCloseModal} questionInfo={questionInfo} isEdit={true}/>
            </BasicModalWithHeader>
        </>
    );
};

export default QuestionsListActions;