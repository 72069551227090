import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    page: 1,
    perPage: 10,
    count: 0,
    isLoading: false,
    isError: false,
    error: "",

    isAdding: false,
    isAddSuccess: false,

    isUpdating: false,
    isUpdateSuccess: false,

    isDeleting: false,
    isDeleteSuccess: false,

    filters: {
        categoryId: "",
        subCategoryId: "",
    }
};

const templateAssessmentSlice = createSlice({
    name: "templateAssessment",
    initialState: initialState,
    reducers: {
        getTemplateAssessmentList: (state) => {
            state.isLoading = true;
        },
        getTemplateAssessmentListSuccess: (state, action) => {
            state.data = action.payload.rows;
            state.page = +action.payload.page;
            state.perPage = +action.payload.perPage;
            state.count = +action.payload.count;
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        },
        getTemplateAssessmentListFailed: (state, action) => {
            state.data = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        createTemplateAssessment: (state) => {
            state.isAdding = true;
        },
        createTemplateAssessmentSuccess: (state, action) => {
            state.isAdding = false;
            state.isAddSuccess = true;
            state.data.unshift(action.payload);
            state.count += 1;
        },
        resetCreateTemplateAssessment: (state) => {
            state.isAdding = false;
            state.isAddSuccess = false;
        },

        editTemplateAssessment: (state) => {
            state.isUpdating = true;
        },
        editTemplateAssessmentSuccess: (state, action) => {
            state.isUpdating = false;
            state.isUpdateSuccess = true;
            state.data = state.data.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload };
                }
                return item;
            });
        },
        resetEditTemplateAssessment: (state) => {
            state.isUpdating = false;
            state.isUpdateSuccess = false;
        },

        updateTemplateAssessmentStatus: (state) => {
            state.isUpdating = true;
        },
        updateTemplateAssessmentStatusSuccess: (state, action) => {
            state.data = state.data.map(item => {
                if(item.id === action.payload.id) {
                    return {...item, status: action.payload.status}
                }
                return item;
            });
            state.isUpdating = false;
        },
        updateTemplateAssessmentStatusFailed: (state) => {
            state.isUpdating = false;
        },

        deleteTemplateAssessment: (state) => {
            state.isDeleting = true;
        },
        deleteTemplateAssessmentSuccess: (state, action) => {
            state.isDeleting = false;
            state.isDeleteSuccess = true;
            state.data = state.data.filter((item) => item.id !== action.payload.id);
            state.count -= 1;
        },
        resetDeleteTemplateAssessment: (state) => {
            state.isDeleting = false;
            state.isDeleteSuccess = false;
        },

        addTempAssCategoryAndSubCategory: (state, action) => {
            state.filters.categoryId = action.payload.categoryId;
            state.filters.subCategoryId = action.payload.subCategoryId;
        }
    },
});

export const {
    getTemplateAssessmentList,
    getTemplateAssessmentListSuccess,
    getTemplateAssessmentListFailed,

    createTemplateAssessment,
    createTemplateAssessmentSuccess,
    resetCreateTemplateAssessment,

    editTemplateAssessment,
    editTemplateAssessmentSuccess,
    resetEditTemplateAssessment,

    updateTemplateAssessmentStatus,
    updateTemplateAssessmentStatusSuccess,
    updateTemplateAssessmentStatusFailed,

    deleteTemplateAssessment,
    deleteTemplateAssessmentSuccess,
    resetDeleteTemplateAssessment,

    addTempAssCategoryAndSubCategory
} = templateAssessmentSlice.actions;

export default templateAssessmentSlice.reducer;
