import {all, call, put, takeLatest} from "redux-saga/effects";
import BlogsService from "../../services/blogs.service";
import {
    createBlogSuccess,
    editBlogSuccess,
    getBlogListFailed,
    getBlogListSuccess,
    resetCreateBlog,
    resetEditBlog, updateBlogStatusFailed, updateBlogStatusSuccess
} from "./blogsSlice";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utils/toaster";


function* blogsWatcher() {
    yield takeLatest("blogs/getBlogList", getBlogListSaga);
    yield takeLatest("blogs/createBlog", createBlogSaga);
    yield takeLatest("blogs/editBlog", editBlogSaga);
    yield takeLatest("blogs/updateBlogStatus", updateBlogStatusSaga);
}

function* getBlogListSaga(action) {
    try {
        const response = yield call(BlogsService.getBlogList, action.payload);

        if (response.status) {
            yield put(getBlogListSuccess(response.data));
        } else {
            yield put(getBlogListFailed(response.message));
        }
    } catch (err) {
        yield put(getBlogListFailed(err.message));
    }
}

function* createBlogSaga(action) {
    try {
        const response = yield call(BlogsService.createBlog, action.payload);

        if (response.status) {
            yield put(createBlogSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(resetCreateBlog(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* editBlogSaga(action) {
    try {
        const response = yield call(BlogsService.editBlog, action.payload);

        if (response.status) {
            yield put(editBlogSuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(resetEditBlog(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* updateBlogStatusSaga(action) {
    try {
        const response = yield call(BlogsService.updateBlogStatus, action.payload);

        if (response.status) {
            yield put(updateBlogStatusSuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(updateBlogStatusFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* blogsSaga() {
    yield all([blogsWatcher()]);
}
