export const typographyProperties = {
    htmlFontSize: 16,
    fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 700,
        fontSize: "55px",
        lineHeight: "64px",
        color: "#012D41",
    },
    h2: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 700,
        fontSize: "48px",
        lineHeight: "56px",
        color: "#012D41",
    },
    h3: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 700,
        fontSize: "34px",
        lineHeight: "48px",
        color: "#012D41",
    },
    h4: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "40px",
        color: "#012D41",
    },
    h5: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "36px",
        color: "#012D41",
    },
    h6: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "36px",
        color: "#012D41",
    },
    subtitle1: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "22px",
        color: "#012D41",
        margin: "5px 0",
    },
    subtitle2: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "22px",
        color: "#6F818F",
        margin: "5px 0",
    },
    body1: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "27px",
        fontStyle: "normal",
        color: "#3F5467",
    },
    body2: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 400,
        fontSize: "14px",
        fontStyle: "normal",
        lineHeight: "25px",
        color: "#3F5467",
    },
    button: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "26px",
        letterSpacing: "0.46px",
        textTransform: "capitalize",
        borderRadius: "4px",
    },
    caption: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
    },
    overline: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "1px",
        textTransform: "uppercase",
        color: "#000000",
    },
    avatarLetter: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px",
    },
    inputLevel: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "12px",
    },
    helperText: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "14px",
        letterSpacing: "0.4px",
    },
    inputText: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
    },
    tooltip: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "14px",
    },
    badge: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "20px",
    },
    TableHeader: {
        fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
    },
};

export const lightModePalette = {
    mode: "light",
    common: {
        black: "#333",
        white: "#FFFFFF",
        gray10: "#F2F5FF",
        gray9: "#0E263E",
        gray8: "#3F5467",
        gray7: "#6F818F",
        gray6: "#A0AFB7",
        gray5: "#C0CBD1",
        gray4: "#D7DFE2",
        gray3: "#E7EBEE",
        gray2: "#EFF2F3",
        gray1: "#F7F8F9",
        lightShade: "#d4d4d4",
    },
    background: {
        paper: "#FFFFFF",
        default: "#FFFFFF",
    },
    text: {
        primary: "#012d41",
        secondary: "#F26F21",
        warning: "red",
    },
    primary: {
        main: "#012d41", // default
        contrastText: "#FFFFFF",
    },
    secondary: {
        main: "#F26F21",
        contrastText: "#FFFFFF",
    },
    warning: {
        main: "#c03029",
        contrastText: "#FFFFFF",
    },
    error: {
        main: "#F20100",
        light: "#ffdad9",
        contrastText: "#FFFFFF",
    },
    info: {
        main: "#0288d1",
        contrastText: "#FFFFFF",
    },
    success: {
        main: "#008945",
        light: "#baf0cb",
        contrastText: "#FFFFFF",
    },
    other: {
        outlinedBorder: "rgba(0, 0, 0, 0.11)",
        brandPrimary: "#012D41",
        brandSecondary: "#F26F21",
        brandSecondaryLight: "#FFEBDF",
        lightSkyBlue: "#F7F8F9",
    },
    action: {
        active: "#949DB2",
        actionHover: "rgba(98, 88, 255, 0.06)",
        hover: "rgba(100, 56, 188, 0.04)",
        hoverOpacity: 0.03,
        selected: "rgba(28, 15, 19, 0.08)",
        selectedOpacity: 0.08,
        disabled: "rgba(3, 6, 11, 0.26)",
        disabledBackground: "rgba(3, 6, 11, 0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(28, 15, 19, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
    },
};

export const componentOverRide = {
    MuiPaper: {
        styleOverrides: {
            root: {
                borderColor: `${lightModePalette.common.gray3}!important`,
                borderRadius: 10,
                marginBottom: 0,
            },
        },
    },
    MuiLink: {
        styleOverrides: {
            root: {
                color: lightModePalette.text.primary,
                textDecoration: "none",
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: ({ ownerState }) => ({
                boxShadow: "none",
                textTransform: "inherit",
                transition: "all 0.5s",
                letterSpacing: 0,
                "&:hover": {
                    boxShadow: "none",
                    transition: "all 0.5s",
                },
                ...(ownerState.variant === "contained" &&
                    ownerState.color === "primary" && {
                        height: 50,
                        padding: "13px 24px",
                        backgroundColor: "#f26f21",
                        borderRadius: 30,
                        fontSize: 14,
                        fontWeight: 700,
                        color: "#fff",
                        boxShadow: "none",
                        textTransform: "inherit",
                        transition: "background-color 0.2s ease 0s, box-shadow 0.2s ease 0s",
                        "&:hover": {
                            backgroundColor: "#DC641C",
                            boxShadow: "none",
                        },
                    }),
                ...(ownerState.size === "large" && {
                    minWidth: 516,
                }),
                ...(ownerState.size === "medium" && {
                    minWidth: 210,
                }),
                ...(ownerState.size === "small" && {
                    minWidth: 117,
                }),
                ...(ownerState.variant === "text" && {
                    height: 50,
                    padding: "13px 24px",
                    fontSize: 14,
                    fontWeight: 700,
                    borderRadius: 30,
                    boxShadow: "none",
                    textTransform: "inherit",
                    backgroundColor: "transparent",
                    borderColor: lightModePalette.secondary.main,
                    borderWidth: 1,
                    borderStyle: "solid",
                    transition: "background-color 0.2s ease 0s, box-shadow 0.2s ease 0s",
                    color: lightModePalette.secondary.main,
                    "&:hover": {
                        backgroundColor: lightModePalette.secondary.main,
                        color: lightModePalette.common.white,
                        boxShadow: "none",
                    },
                }),
                ...(ownerState.variant === "outlined" && {
                    height: 50,
                    padding: 25,
                    borderRadius: 30,
                    paddingLeft: 14,
                    paddingRight: 14,
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    textTransform: "inherit",
                    borderColor: "#012D41",
                    transition: "background-color 0.2s ease 0s, box-shadow 0.2s ease 0s",
                    color: "#012D41",
                    "&:hover, &:active, &.outlined-active": {
                        color: "#FFFFFF",
                        backgroundColor: "#012D41",
                        borderColor: "#012D41",
                    },
                }),
            }),
        },
    },
    MuiCard: {
        styleOverrides: {
            root: {
                borderRadius: 7,
                border: "1px solid rgb(229, 229, 229)",
                marginBottom: 15,
            },
        },
    },
    MuiCardHeader: {
        styleOverrides: {
            root: {
                paddingTop: 5,
                paddingBottom: 0,
                span: {
                    "&:nth-child(1)": {
                        fontSize: 17,
                    },
                    "&:nth-child(2)": {
                        fontSize: 14,
                    },
                },
            },
        },
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                padding: "15px!important",
            },
        },
    },
    MuiFormControl: {
        styleOverrides: {
            root: {
                label: {
                    color: "#012d41",
                    fontWeight: typographyProperties.subtitle1.fontWeight,
                    fontSize: typographyProperties.inputText.fontSize,
                    marginBottom: 5,
                },
                ".MuiInputBase-formControl": {
                    borderRadius: 10,
                    textarea: {
                        fontSize: 16,
                    },
                    ".MuiOutlinedInput-root": {
                        "&:hover": {
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: lightModePalette.common.gray1,
                                borderWidth: 1,
                            },
                        },
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                        borderColor: lightModePalette.common.gray4,
                        borderWidth: 1,
                    },
                },
                ".MuiCheckbox-root": {
                    svg: {
                        // fill: lightModePalette.common.gray4,
                    },
                    "&.Mui-checked": {
                        svg: {
                            // fill: lightModePalette.secondary.main,
                        },
                    },
                },
                input: {
                    height: "40px !important",
                    borderRadius: 10,
                    fontFamily: typographyProperties.fontFamily,
                    fontSize: 16,
                    borderColor: lightModePalette.common.gray4,
                    fontWeight: typographyProperties.body1.fontWeight,
                    color: lightModePalette.primary.main,
                    "&:focus, &:focus-visible": {
                        outline: "none",
                        borderColor: lightModePalette.common.gray6,
                    },
                    "&:hover": {
                        fieldset: {
                            borderColor: lightModePalette.common.gray6,
                        },
                    },
                },
                textarea: {
                    padding: 5,
                    borderRadius: 12,
                    borderColor: lightModePalette.common.gray4,
                    fontFamily: typographyProperties.fontFamily,
                    fontSize: typographyProperties.body1.fontSize,
                    fontWeight: typographyProperties.body1.fontWeight,
                    "&:hover": {
                        borderColor: lightModePalette.primary.main,
                    },
                },
            },
        },
    },
    MuiRadio: {
        styleOverrides: {
            root: {
                color: lightModePalette.common.gray4,
                "&.Mui-checked": {
                    color: lightModePalette.secondary.main,
                },
                "&.Mui-disabled": {
                    //color: lightModePalette.common.gray5,
                },
                "&.MuiRadio-colorError": {
                    color: lightModePalette.error.main,
                },
                "&.MuiRadio-colorSuccess": {
                    color: lightModePalette.success.main,
                },
                "&.MuiRadio-colorWarning": {
                    color: lightModePalette.warning.main,
                },
            },
        },
    },
    MuiCheckbox: {
        styleOverrides: {
            root: {
                // color: lightModePalette.common.gray4,
                // "&.Mui-checked": {
                //     color: lightModePalette.secondary.main,
                // },
                // "&.Mui-disabled": {
                //     color: lightModePalette.common.gray5,
                // },
            },
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                marginLeft: 0,
            },
        },
    },
    MuiTableContainer: {
        styleOverrides: {
            root: {
                borderRadius: 10,
                border: "1px solid #E7EBEE",
            },
        },
    },
    MuiTable: {
        styleOverrides: {
            root: {
                thead: {
                    tr: {
                        th: {
                            color: "#6F818F",
                            fontWeight: 500,
                            fontSize: 14,
                            letterSpacing: "0.15px",
                            borderBottomColor: "#F7F8F9",
                        },
                    },
                },
                tbody: {
                    tr: {
                        td: {
                            color: "#012D41",
                            borderColor: lightModePalette.common.gray3,
                            fontWeight: typographyProperties.body1.fontWeight,
                        },
                        "&:last-child td": {
                            borderBottom: "none",
                        },
                    },
                },
            },
        },
    },
    MuiTablePagination: {
        styleOverrides: {
            root: {
                marginTop: "10px",
                ".MuiSelect-select": {
                    fontSize: "14px",
                    color: "#3F5467",
                },
            },
        },
    },
    MuiAccordion: {
        styleOverrides: {
            root: {
                boxShadow: "none",
                borderColor: lightModePalette.common.gray90,
                borderStyle: "solid",
                borderWidth: 1,
                borderRadius: "7px!important",
                "&:before": {
                    display: "none",
                },
            },
        },
    },
    MuiPopover: {
        styleOverrides: {
            root: {
                ".MuiPaper-root": {
                    boxShadow: " 0px 4px 90px rgba(0, 0, 0, 0.07)",
                    borderRadius: "10px",
                },
            },
        },
    },
    MuiChip: {
        styleOverrides: {
            root: {
                borderRadius: 30,
                fontSize: 12,
                fontWeight: 700,
                letterSpacing: 0.5,
            },
        },
    },
    MuiAvatar: {
        styleOverrides: {
            root: {
                "&.MuiAvatar-root": {
                    backgroundColor: lightModePalette.primary.main,
                },
            },
        },
    },
    MuiTabs: {
        styleOverrides: {
            root: {
                marginBottom: 20,
                ".Mui-selected": {
                    backgroundColor: lightModePalette.common.white,
                    color: lightModePalette.primary.main,
                },
                ".MuiTab-root": {
                    fontSize: 14,
                    fontWeight: 500,
                    color: lightModePalette.primary.main,
                    padding: "15px 50px!important",
                },
                ".MuiTabs-flexContainer": {
                    padding: "10px 13px",
                    borderRadius: 8,
                    backgroundColor: lightModePalette.common.gray3,
                },
                ".MuiTabs-indicator": {
                    display: "none",
                },
            },
        },
    },
    MuiAccordionSummary: {
        styleOverrides: {
            root: {
                ".MuiAccordionSummary-content": {
                    margin: "5px !important",
                    minHeight: "30px",
                },
            },
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                lineHeight: "20px",
            },
        },
    },
    MuiDivider: {
        styleOverrides: {
            root: {
                borderColor: lightModePalette.common.gray4,
            },
        },
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                backgroundColor: "transparent",
                "&.Mui-selected": {
                    backgroundColor: lightModePalette.common.gray3,
                },
                "&:hover": {
                    backgroundColor: lightModePalette.common.gray3,
                },
                ".MuiListItemText-primary": {
                    fontWeight: 500,
                },
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                "&:hover": {
                    color: lightModePalette.common.gray8,
                    backgroundColor: lightModePalette.common.gray3,
                },
            },
        },
    },
    MuiAutocomplete: {
        styleOverrides: {
            root: {
                borderColor: lightModePalette.common.gray4,
                height: "60px",
                fontSize: "168px",
            },
            listbox: {
                padding: "10px",
                backgroundColor: "hsl(0, 0%, 100%)",
                boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
                ".MuiAutocomplete-option": {
                    color: lightModePalette.primary.main,
                    borderRadius: "8px",
                    fontSize: "16px",
                    padding: "8px 12px",
                    "&:hover": {
                        color: lightModePalette.primary.main,
                        backgroundColor: "#f6f6f6",
                    },
                },
            },
        },
    },
    MuiSelect: {
        styleOverrides: {
            root: {
                ".MuiSelect-select": {
                    height: "40px",
                    lineHeight: "36px",
                },
            },
        },
    },
};
