import React from 'react';
import Reports from "./Reports";

const Report = () => {
    return (
        <>
            <Reports/>
        </>
    );
};

export default Report;