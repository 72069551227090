import React from "react";
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

const TableSkeletonColumn = ({ ...rest }) => {
    return (
        <TableCell {...rest}>
            <Skeleton variant='text' style={{ width: "100px", height: "20px" }} />
        </TableCell>
    );
};

const TableLoaderSkeleton = ({ columns = 6, numberOfRows = 10 }) => {
    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {Array.from({ length: numberOfRows }).map((rowNum, i) => (
                        <TableRow key={i}>
                            {Array.from({ length: columns }).map((column, index) => (
                                <TableSkeletonColumn key={index} style={{ padding: "16px 0 16px 32px" }} />
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default TableLoaderSkeleton;
