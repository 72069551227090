import httpRequestAdmin from "./httpRequest/httpRequestAdmin";
class AdminAuthService {
    loginAdmin(body) {
        return httpRequestAdmin.post(process.env.REACT_APP_BACKEND_API_URL + "admin/login", body);
    }

    async getAdminData() {
        return await httpRequestAdmin.get(process.env.REACT_APP_BACKEND_API_URL + "admin/login/success");
    }

    forceLoginEmployerAccount(body) {
        return httpRequestAdmin.get(process.env.REACT_APP_BACKEND_API_URL + "employer/login/by/admin", body);
    }
}

export default new AdminAuthService();
