import React from "react";
import { Stack, styled } from "@mui/material";
import CustomPopOver from "./CustomPopOver";
import TriggerPersonalizedAccordion from "./TriggerPersonalizedAccordion";
import {PERSONALIZED_TAGS} from "../../helpers/constant/personalizeTagConstant";

const StyledListWrapper = styled(Stack)(() => ({
    gap: "8px",
    maxWidth: "208px",
    width: "100%",
    padding: "8px",
}));

const StyledList = styled("ul")(({ theme }) => ({
    paddingLeft: "6px",
    marginTop: 0,
    marginBottom: "10px",
    "& li": {
        listStyleType: "none",
        margin: theme.spacing(0.5),
        cursor: "pointer",
        padding: "4px 10px",
        fontSize: "15px",
        color: theme.palette.text.primary,
        textTransform: "capitalize",
        transition: "all 0.3s ease",
        borderRadius: theme.spacing(0.5),
        "&:hover": {
            borderColor: "transparent",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
}));

const PersonalizedTag = ({ onClick, personalizeTagList = [] }) => {
    const tagList = personalizeTagList?.length > 0 ? personalizeTagList : PERSONALIZED_TAGS;

    return (
        <CustomPopOver buttonText={"Personalized Tags"} color={"inherit"}>
            <StyledListWrapper>
                {tagList.map((eachGroup, eachGroupIndex) => (
                    <TriggerPersonalizedAccordion
                        key={eachGroupIndex}
                        title={eachGroup.label}
                        expanded={eachGroupIndex === 0}
                    >
                        <StyledList>
                            {eachGroup.tags.map((eachData, eachDataIndex) => (
                                <li key={eachGroupIndex + "_" + eachDataIndex} onClick={() => onClick(eachData.value)}>
                                    {eachData.label}
                                </li>
                            ))}
                        </StyledList>
                    </TriggerPersonalizedAccordion>
                ))}
            </StyledListWrapper>
        </CustomPopOver>
    );
};

export default PersonalizedTag;
