import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    page: 1,
    perPage: 10,
    total: 0,
    isLoading: false,
    isError: false,
    error: "",

    isUpdating: false,
};

const companyListSlice = createSlice({
    name: "companyList",
    initialState: initialState,
    reducers: {
        getCompanyList: (state) => {
            state.isLoading = true;
        },
        getCompanyListSuccess: (state, action) => {
            state.data = action.payload.rows;
            state.page = +action.payload.page;
            state.perPage = +action.payload.perPage;
            state.count = action.payload.count;
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        },
        getCompanyListFailed: (state, action) => {
            state.data = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        updateCompanyStatus: (state) => {
            state.isUpdating = true;
        },
        updateCompanyStatusSuccess: (state, action) => {
            state.data = state.data.map(item => {
                if(item.id === action.payload.companyId) {
                    return {...item, status: action.payload.status}
                }
                return item;
            });
            state.isUpdating = false;
        },
        updateCompanyStatusFailed: (state) => {
            state.allUserData = [];
            state.isUpdating = false;
        },
    },
});

export const {
    getCompanyList,
    getCompanyListSuccess,
    getCompanyListFailed,

    updateCompanyStatus,
    updateCompanyStatusSuccess,
    updateCompanyStatusFailed
} = companyListSlice.actions;

export default companyListSlice.reducer;
