import React from "react";
import {Outlet} from "react-router-dom";
import {Box, Drawer, Stack} from "@mui/material";
import DashboardSidebar from "./dashboard/DashboardSidebar";

const drawerWidth = 250;

const DashboardLayout = () => {
    return (
        <>
            <Stack direction={"row"} spacing={1}>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            boxSizing: "border-box",
                        },
                        zIndex: (theme) => theme.zIndex.drawer - 2,
                    }}
                >
                    <Box sx={{overflow: "auto"}}>
                        <DashboardSidebar/>
                    </Box>
                </Drawer>
                <Box component="main" sx={{flexGrow: 1, p: 3}}>
                    <Outlet/>
                </Box>
            </Stack>
        </>
    );
};

export default DashboardLayout;
