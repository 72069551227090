const PT_EMPLOYER_NAME = "{{employer_name}}";
const PT_EMPLOYER_EMAIL = "{{employer_email}}";
const PT_COMPANY_NAME = "{{company_name}}";

const PT_JOB_SEEKER_NAME = "{{job_seeker_name}}";
const PT_JOB_SEEKER_EMAIL = "{{job_seeker_email}}";

export const PERSONALIZE_TAGS_EMPLOYER = [
    {
        label: "Employer name",
        value: PT_EMPLOYER_NAME,
    },
    {
        label: "Employer email",
        value: PT_EMPLOYER_EMAIL,
    },
]

export const PERSONALIZE_TAGS_COMPANY = [
    {
        label: "Company name",
        value: PT_COMPANY_NAME,
    }
]

export const PERSONALIZE_TAGS_JOB_SEEKER = [
    {
        label: "Job seeker name",
        value: PT_JOB_SEEKER_NAME,
    },
    {
        label: "Job seeker email",
        value: PT_JOB_SEEKER_EMAIL,
    },
]