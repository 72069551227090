import React from "react";
import {Divider, Fade, Modal, Paper, Stack, styled, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";

const PaperStyle = styled(Paper)(({theme}) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: theme.shadows[24],
}));

const BasicModalWithHeader = ({
                                  title = "",
                                  open,
                                  onClose,
                                  width = 700,
                                  height = "auto",
                                  autoScroll = false,
                                  disableOutSideClick = false,
                                  children,
                                  ...rest
                              }) => {
    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={!disableOutSideClick ? onClose : () => {
                }}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                {...rest}
            >
                <Fade in={open}>
                    <PaperStyle sx={{width: width, height: height}}>
                        {title && (
                            <>
                                <Stack
                                    direction={"row"}
                                    justifyContent='space-between'
                                    alignContent='center'
                                    sx={{px: 3, pt: 2, pb: 1}}
                                >
                                    <Typography variant='h5' color='text.primary'>
                                        {title}
                                    </Typography>
                                    <IconButton color={"error"} onClick={onClose}>
                                        <Close fontSize={"medium"}/>
                                    </IconButton>
                                </Stack>
                                <Divider/>
                            </>
                        )}
                        {!title && (
                            <IconButton color={"error"} onClick={onClose} sx={{float: "right"}}>
                                <Close fontSize={"medium"}/>
                            </IconButton>
                        )}
                        <Box
                            sx={{
                                p: 3,
                                maxHeight: "calc(100vh - 100px)",
                                overflowY: autoScroll ? "auto" : "unset",
                                height: "100%",
                            }}
                        >
                            {children}
                        </Box>
                    </PaperStyle>
                </Fade>
            </Modal>
        </React.Fragment>
    );
};

export default BasicModalWithHeader;