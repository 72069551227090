import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Divider, Grid, ListItemButton, Stack, styled} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {TemplatesSidebar} from "./TemplatesSidebar";
import AddOrEditCategory from "./AddOrEditCategory";
import TemplateAssessmentTable from "./TemplateAssessmentTable";
import AddOrEditTemplateAssessment from "./AddOrEditTemplateAssessment";
import BasicModalWithHeader from "../../../global/Modal/BasicModalWithHeader";
import {selectTemplateAssessmentFilters} from "../../../state/templateAssessment/templateAssessmentSelector";
import {addTempAssCategoryAndSubCategory} from "../../../state/templateAssessment/templateAssessmentSlice";
import {getQuestionCategoryList} from "../../../state/questionCategory/questionCategorySlice";
import useBoolean from "../../../hooks/useBoolean";
import Styles from "../assessment.module.scss";

const ListItemButtonStyled = styled(ListItemButton)(({theme}) => ({
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    padding: 5,
    borderRadius: 6,
    "& .MuiListItemIcon-root": {
        minWidth: theme.spacing(4),
        padding: 3,
        borderRadius: 4,
        "&:hover": {
            backgroundColor: "red"
        }
    },
}));

const Templates = () => {
    const dispatch = useDispatch();
    const {subCategoryId} = useSelector(selectTemplateAssessmentFilters);

    const {value: openModal, setTrue: onOpenModal, setFalse: onCloseModal} = useBoolean(false);
    const {
        value: openAddCategoryModal, setTrue: onOpenAddCategoryModal, setFalse: onCloseAddCategoryModal,
    } = useBoolean(false);
    const {
        value: openCustomAssessmentModal, setTrue: onOpenCustomAssessmentModal, setFalse: onCloseCustomAssessmentModal,
    } = useBoolean(false);

    useEffect(() => {
        dispatch(getQuestionCategoryList())
    }, []);

    const handleSelect = () => {
        dispatch(addTempAssCategoryAndSubCategory({categoryId: '', subCategoryId: ''}))
    }

    return (
        <div className={Styles.assessmentsListWrapper}>
            <Grid container spacing={2} rowGap={" auto auto 1fr 1fr 1fr auto auto"}>
                <Grid item xs={3} sm={3} md={2.5} pr={4}>
                    <Stack direction={'column'} spacing={2} alignItems={'flex-start'} mb={2}>
                        <Button
                            variant={'contained'}
                            size={'small'}
                            endIcon={<AddIcon fontSize={'large'}/>}
                            sx={{width: '100%', height: "45px", borderRadius: "5px!important"}}
                            onClick={onOpenCustomAssessmentModal}
                        >
                            Add Template
                        </Button>
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            size={'small'}
                            endIcon={<AddIcon fontSize={'medium'}/>}
                            sx={{width: '100%', height: "35px", padding: "15px", borderRadius: "5px!important"}}
                            onClick={onOpenAddCategoryModal}
                        >
                            Add Category
                        </Button>
                    </Stack>
                    <ListItemButtonStyled onClick={handleSelect} selected={subCategoryId === ''}>
                        <span style={{paddingLeft: "10px"}}>All Templates</span>
                    </ListItemButtonStyled>
                    <Divider sx={{my: 1}}/>
                    <Box sx={{height: "calc(100vh - 300px)", overflowY: "scroll"}}>
                        <TemplatesSidebar/>
                    </Box>
                </Grid>

                <Grid container xs={9} sm={9} md={9.5}>
                    <TemplateAssessmentTable/>
                </Grid>
            </Grid>

            <BasicModalWithHeader
                title={"Create New template"}
                open={openCustomAssessmentModal}
                onClose={onCloseCustomAssessmentModal}
                width={800}
            >
                <AddOrEditTemplateAssessment onClose={onCloseCustomAssessmentModal} isEdit={false}/>
            </BasicModalWithHeader>

            <BasicModalWithHeader
                title={"Create Template Category"}
                open={openAddCategoryModal}
                onClose={onCloseAddCategoryModal}
                autoScroll={true}
                width={500}
            >
                <AddOrEditCategory onClose={onCloseAddCategoryModal}/>
            </BasicModalWithHeader>
        </div>
    );
};

export default Templates;