import React from "react";
import {Box, Typography} from "@mui/material";

const PageTitle = ({title, subTitle = '', mb = 3, subTitleMb = 0}) => {
    return (
        <Box sx={{mb: mb}}>
            <Typography variant='subtitle1'>
                {title}
            </Typography>
            {subTitle &&
                <Typography variant={'subtitle2'} sx={{m: subTitleMb}}>{subTitle}</Typography>
            }
        </Box>
    );
};

export default PageTitle;