import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import useHasAdminAuth from "../../hooks/useHasAdminAuth";
import {adminLogOut} from "../../state/adminAuth/adminAuthSlice";
import CircleLoader from "../../global/Loader/CircleLoader";

const PrivateLayoutAdmin = ({children}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isHasAuth, setHasAuth] = useState(false);

    useEffect(() => {
        const isHasAuth = useHasAdminAuth();
        if (!isHasAuth) {
            dispatch(adminLogOut());
            navigate("/");
        } else setHasAuth(true);
    }, [dispatch, navigate]);

    // ## global logout function
    window.employerLogOut = () => {
        navigate("/");
        dispatch(adminLogOut());
    };

    if (!isHasAuth) return <CircleLoader/>;
    return children;
};

export default PrivateLayoutAdmin;
