import {
    Chip,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import { format } from "date-fns";
import React from 'react';
import Select from "react-select";
import EmptyBoxLogo from "../../assets/images/inbox-empty.png";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import TableLoaderSkeleton from "../../global/Loader/TableLoaderSkeleton";
import GlobalTablePagination from "../../global/Pagination/GlobalTablePagination";
import {
    JOB_POST_PACKAGE_TYPE,
    JOB_POST_PUBLISH_STATUS,
    JOB_POST_PUBLISH_STATUS_CONTENT
} from "../../helpers/constant/jobPostConstant";
import HOCJobPostList from "./HOCJobPostList";
import JobDetails from "./JobDetails";

const headCells = [
    {
        id: "Job Details",
        label: "Job Details",
    },
    {
        id: "Job Status",
        label: "Job Status",
    },
    {
        id: "Company name",
        label: "Company Name",
    },
    {
        id: "Job Submit Date",
        label: "Job Submit Date",
    },
    {
        id: "Job Expiry Date",
        label: "Job Expiry Date",
    },
    {
        id: "Total Candidate",
        label: "Total Candidate",
    },
    {
        id: "Job Actions",
        label: "Job Actions",
    },
];

const getPackageLabelByType = (packageType) => {
    if (packageType === JOB_POST_PACKAGE_TYPE.BEST) return "Best";
    else if (packageType === JOB_POST_PACKAGE_TYPE.BETTER) return "Better";
    return "Good";
}

const JobPostListTable = ({
    data: jobPostList = [],
    isLoading = false,
    page = 1,
    perPage = 10,
    count = 0,
    onChangePage,
    onChangePerPage,
    updatedPublishStatusId,
    handleChangePublishStatus,
}) => {

    // decide what to render
    let content = null;
    if (isLoading) {
        content = <TableLoaderSkeleton columns={7} numberOfRows={10} />;
    } else if (jobPostList?.length === 0) {
        content = <GlobalEmptyPage image={EmptyBoxLogo} title={"No Job Post found!"} />;
    } else if (jobPostList?.length > 0) {
        content = (
            <>
                <TableContainer>
                    <Table sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell key={headCell.id} align='left' padding={"normal"}>
                                        {headCell.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jobPostList.map(jobPost => {
                                const {
                                    id,
                                    publishStatus,
                                    publishDate,
                                    expiredDate,
                                    companyInfo,
                                    totalApplyCandidate
                                } = jobPost || {};
                                const { name: companyName } = companyInfo || {};
                                let jobExpired = expiredDate ? new Date().getTime() - new Date(expiredDate).getTime() > 0 : false
                                const formattedPublishDate = publishDate ? format(new Date(publishDate), "MMM dd, yyyy") : "-";
                                const formattedExpiredDate = expiredDate ? format(new Date(expiredDate), "MMM dd, yyyy") : "-";
                                return (
                                    <TableRow key={id} hover>
                                        <TableCell align='left'>
                                            <JobDetails jobPost={jobPost} />
                                        </TableCell>
                                        <TableCell>
                                            {jobExpired && <Chip label={'Expired'} color={'error'} size={'small'} />}
                                            {publishStatus === JOB_POST_PUBLISH_STATUS.PENDING && <Chip sx={{ ml: 1 }} label={'Pending'} color={'info'} size={'small'} />}
                                            {publishStatus === JOB_POST_PUBLISH_STATUS.REJECTED && <Chip sx={{ ml: 1 }} label={'Rejected'} color={'warning'} size={'small'} />}
                                            {!jobExpired ?
                                                publishStatus === JOB_POST_PUBLISH_STATUS.PUBLISHED && <Chip sx={{ ml: 1 }} label={'Published'} color={'success'} size={'small'} /> : false
                                            }
                                        </TableCell>
                                        <TableCell align='left'>
                                            <a rel="noreferrer" target={'_blank'}
                                                href={companyInfo?.website}>
                                                <Typography variant={'body1'} title={companyName} className={'text-truncate'}
                                                    sx={{ width: 150 }}>{companyName}</Typography>
                                            </a>
                                        </TableCell>
                                        <TableCell align='left'>{formattedPublishDate}</TableCell>
                                        <TableCell align='left'>{formattedExpiredDate}</TableCell>
                                        <TableCell align='left'>{totalApplyCandidate}</TableCell>
                                        <TableCell align='left' sx={{ width: "200px" }}>
                                            {updatedPublishStatusId !== id ? (
                                                <Select
                                                    isMulti={false}
                                                    isSearchable={false}
                                                    menuPlacement='bottom'
                                                    options={Object.values(JOB_POST_PUBLISH_STATUS_CONTENT)}
                                                    value={JOB_POST_PUBLISH_STATUS_CONTENT[publishStatus || 0]}
                                                    onChange={(newValue) => handleChangePublishStatus(id, newValue.value)}
                                                />
                                            ) : (
                                                <Skeleton variant={"rounded"} height={60} />
                                            )}

                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <GlobalTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={perPage}
                    onChangePage={onChangePage}
                    onChangePerPage={onChangePerPage}
                />
            </>
        )
    }
    return content;
};
export default HOCJobPostList(JobPostListTable);