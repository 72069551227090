import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    page: 1,
    perPage: 10,
    count: 0,
    isLoading: false,
    isError: false,
    error: "",

    isAdding: false,
    isAddSuccess: false,

    isUpdating: false,
    isUpdateSuccess: false,

    isDeleting: false,
    isDeleteSuccess: false,
};

const messageTemplateSlice = createSlice({
    name: "messageTemplate",
    initialState: initialState,
    reducers: {
        getMessageTemplates: (state) => {
            state.isLoading = true;
        },
        getMessageTemplatesSuccess: (state, action) => {
            state.data = action.payload.rows;
            state.page = +action.payload.page;
            state.perPage = +action.payload.perPage;
            state.count = action.payload.count;
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        },
        getMessageTemplatesFailed: (state, action) => {
            state.data = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        createMessageTemplate: (state) => {
            state.isAdding = true;
        },
        createMessageTemplateSuccess: (state, action) => {
            state.isAdding = false;
            state.isAddSuccess = true;
            state.data.push(action.payload);
            state.count += 1;
        },
        resetCreateMessageTemplate: (state) => {
            state.isAdding = false;
            state.isAddSuccess = false;
        },

        editMessageTemplate: (state) => {
            state.isUpdating = true;
        },
        editMessageTemplateSuccess: (state, action) => {
            state.isUpdating = false;
            state.isUpdateSuccess = true;
            state.data = state.data.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload };
                }
                return item;
            });
        },
        resetEditMessageTemplate: (state) => {
            state.isUpdating = false;
            state.isUpdateSuccess = false;
        },

        deleteMessageTemplate: (state) => {
            state.isDeleting = true;
        },
        deleteMessageTemplateSuccess: (state, action) => {
            state.isDeleting = false;
            state.isDeleteSuccess = true;
            state.data = state.data.filter((item) => item.id !== action.payload.id);
            state.count -= 1;
        },
        resetDeleteMessageTemplate: (state) => {
            state.isDeleting = false;
            state.isDeleteSuccess = false;
        },
    },
});

export const {
    getMessageTemplates,
    getMessageTemplatesSuccess,
    getMessageTemplatesFailed,

    createMessageTemplate,
    createMessageTemplateSuccess,
    resetCreateMessageTemplate,

    editMessageTemplate,
    editMessageTemplateSuccess,
    resetEditMessageTemplate,

    deleteMessageTemplate,
    deleteMessageTemplateSuccess,
    resetDeleteMessageTemplate,

} = messageTemplateSlice.actions;

export default messageTemplateSlice.reducer;
