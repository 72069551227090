import httpRequestAdmin from "./httpRequest/httpRequestAdmin";

class AssQuestionService {
    getAssQuestionList(params) {
        return httpRequestAdmin.get(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/sys/question/list", params);
    }
    createAssQuestion(params) {
        return httpRequestAdmin.post(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/sys/question/create", params);
    }
    editAssQuestion(params) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/sys/question/update", params);
    }

    updateAssQuestionStatus(body) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/sys/question/update/status", body);
    }

    deleteAssQuestion(params) {
        return httpRequestAdmin.delete(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/sys/question/delete", params);
    }
}

export default new AssQuestionService();
