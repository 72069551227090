import {all, call, put, takeLatest} from "redux-saga/effects";
import EmployerTemplateService from "../../services/employerTemplate.service";
import {
    createEmployerTemplateSuccess,
    deleteEmployerTemplateSuccess,
    editEmployerTemplateSuccess,
    getEmployerTemplatesFailed,
    getEmployerTemplatesSuccess,
    resetCreateEmployerTemplate,
    resetDeleteEmployerTemplate,
    resetEditEmployerTemplate,
    toggleEmployerTemplateStatusSuccess
} from "./employerTemplateSlice";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utils/toaster";

function* employerTemplateWatcher() {
    yield takeLatest("employerTemplate/getEmployerTemplates", getEmployerTemplatesSaga);
    yield takeLatest("employerTemplate/createEmployerTemplate", createEmployerTemplateSaga);
    yield takeLatest("employerTemplate/editEmployerTemplate", editEmployerTemplateSaga);
    yield takeLatest("employerTemplate/toggleEmployerTemplateStatus", toggleEmployerTemplateStatusSaga);
    yield takeLatest("employerTemplate/deleteEmployerTemplate", deleteEmployerTemplateSaga);
}

function* getEmployerTemplatesSaga(action) {
    try {
        const response = yield call(EmployerTemplateService.getEmployerTemplates, action.payload);

        if (response.status) {
            yield put(getEmployerTemplatesSuccess(response.data));
        } else {
            yield put(getEmployerTemplatesFailed(response.message));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* createEmployerTemplateSaga(action) {
    try {
        const response = yield call(EmployerTemplateService.createEmployerTemplate, action.payload);

        if (response.status) {
            yield put(createEmployerTemplateSuccess(response.data));
            showSuccessToaster(response.message);
        } else {
            yield put(resetCreateEmployerTemplate(response.data));
            showErrorToaster(response.message);
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* editEmployerTemplateSaga(action) {
    try {
        const response = yield call(EmployerTemplateService.editEmployerTemplate, action.payload);

        if (response.status) {
            yield put(editEmployerTemplateSuccess(action.payload));
            showSuccessToaster(response.message);
        } else {
            yield put(resetEditEmployerTemplate(response.data));
            showErrorToaster(response.message);
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}
function* toggleEmployerTemplateStatusSaga(action) {
    try {
        const response = yield call(EmployerTemplateService.toggleEmployerTemplateStatus, action.payload);

        if (response.status) {
            yield put(toggleEmployerTemplateStatusSuccess(action.payload));
            showSuccessToaster(response.message);
        } else {
            showErrorToaster(response.message);
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* deleteEmployerTemplateSaga(action) {
    try {
        const response = yield call(EmployerTemplateService.deleteEmployerTemplate, action.payload);

        if (response.status) {
            yield put(deleteEmployerTemplateSuccess(action.payload));
            showSuccessToaster(response.message);
        } else {
            yield put(resetDeleteEmployerTemplate(response.data));
            showErrorToaster(response.message);
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* employerTemplateSaga() {
    yield all([employerTemplateWatcher()]);
}
