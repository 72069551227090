import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import HOCQuestions from "./HOCQuestions";
import SingleQuestionRaw from "./SingleQuestionRaw";
import GlobalTablePagination from "../../../global/Pagination/GlobalTablePagination";
import TableLoaderSkeleton from "../../../global/Loader/TableLoaderSkeleton";
import GlobalEmptyPage from "../../../common/GlobalEmptyPage/GlobalEmptyPage";
import EmptyBoxLogo from "../../../assets/images/inbox-empty.png";

const QuestionTable = ({
                       data: questionList = [],
                       isLoading = false,
                       page = 1,
                       perPage = 10,
                       count = 0,
                       onChangePage,
                       onChangePerPage,
                   }) => {

    // decide what to render
    let content = null;
    if (isLoading) {
        content = <TableLoaderSkeleton columns={4} numberOfRows={10}/>;
    } else if (questionList?.length === 0) {
        content = <GlobalEmptyPage image={EmptyBoxLogo} title={"No Question found!"}/>;
    } else if (questionList?.length > 0) {
        console.log(questionList);
        content = (
            <>
                <TableContainer>
                    <Table sx={{minWidth: 650}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Questions</TableCell>
                                <TableCell>Questions Weight</TableCell>
                                <TableCell>Questions Type</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell>Updated Date</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {questionList.map(question => (
                                <SingleQuestionRaw key={question.id} questionInfo={question}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <GlobalTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={perPage}
                    onChangePage={onChangePage}
                    onChangePerPage={onChangePerPage}
                />
            </>
        )
    }

    return content;
}

export default HOCQuestions(QuestionTable);