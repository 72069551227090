import React, {useState} from "react";
import {useDispatch} from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {JOB_SEEKER_STATUS, JOB_SEEKER_STATUS_TEXT} from "../../helpers/constant/coreConstant";
import {updateJobSeekerStatus} from "../../state/jobSeeker/jobSeekerSlice";


const ITEM_HEIGHT = 48;

const JsMenuAction = ({jobSeeker}) => {
    const dispatch = useDispatch();
    const {id, status} = jobSeeker || {};
    const targetStatus = status === JOB_SEEKER_STATUS.ACTIVE ? JOB_SEEKER_STATUS.INACTIVE : JOB_SEEKER_STATUS.ACTIVE;

    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleStatusChange = () => {
        dispatch(updateJobSeekerStatus({id: id, status: targetStatus}));
        handleMenuClose();
    }

    const open = Boolean(anchorEl);
    return (
        <>
            <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup='true'
                onClick={handleMenuClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id='long-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                    },
                }}
            >
                <MenuItem onClick={handleStatusChange}>
                    {JOB_SEEKER_STATUS_TEXT[targetStatus]}
                </MenuItem>
            </Menu>
        </>
    );
};

export default JsMenuAction;
