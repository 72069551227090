import {createSlice, nanoid} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    page: 1,
    perPage: 10,
    count: 0,
    isLoading: false,
    isError: false,
    error: "",

    renderId: "",
    isUpdating: false,
    updatedPublishStatusId: "",
    filters: {
        publishStatus: "",
        packageType: "",
    }
};

const jobPostListSlice = createSlice({
    name: "jobPostList",
    initialState: initialState,
    reducers: {
        getJobPostList: (state) => {
            state.isLoading = !state.renderId;

        },
        getJobPostListSuccess: (state, action) => {
            state.data = action.payload.rows;
            state.page = +action.payload.page;
            state.perPage = +action.payload.perPage;
            state.count = action.payload.count;
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        },
        getJobPostListFailed: (state, action) => {
            state.data = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        updateJobPostPublishStatus: (state, action) => {
            state.isUpdating = true;
            state.updatedPublishStatusId = action.payload.id;
        },
        updateJobPostPublishStatusSuccess: (state, action) => {
            state.data = state.data.map(item => {
                if(item.id === action.payload.id) {
                    return {...item, publishStatus: action.payload.publishStatus}
                }
                return item;
            });
            state.isUpdating = false;
            state.updatedPublishStatusId = "";
            state.renderId = nanoid(5);
        },
        updateJobPostPublishStatusFailed: (state) => {
            state.isUpdating = false;
            state.updatedPublishStatusId = "";
        },

        addFilterPublishStatus: (state, action) => {
            state.filters.publishStatus = action.payload
        },
        addFilterPackageType: (state, action) => {
            state.filters.packageType = action.payload
        }

    },
});

export const {
    getJobPostList,
    getJobPostListSuccess,
    getJobPostListFailed,

    updateJobPostPublishStatus,
    updateJobPostPublishStatusSuccess,
    updateJobPostPublishStatusFailed,

    addFilterPublishStatus,
    addFilterPackageType
} = jobPostListSlice.actions;

export default jobPostListSlice.reducer;
