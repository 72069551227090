import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, FormControl, FormLabel, Stack, TextField} from "@mui/material";
import {tempAssPattern} from "../../../helpers/constant/registerPattern";
import {
    createTempAssSubCategory,
    editTempAssSubCategory,
    resetCreateTempAssSubCategory,
    resetEditTempAssSubCategory
} from "../../../state/tempAssCategory/tempAssCategorySlice";
import {selectTempAssCategoryState} from "../../../state/tempAssCategory/tempAssCategorySelector";

const AddOrEditSubCategory = ({onClose, subCategoryInfo, categoryId, isEdit = false}) => {
    const dispatch = useDispatch();
    const {isAdding, isAddSuccess, isUpdating, isUpdateSuccess} = useSelector(selectTempAssCategoryState);

    const {
        control,
        handleSubmit,
        setValue,
    } = useForm({
        mode: "onSubmit",
    });

    useEffect(() => {
        if (subCategoryInfo?.value && isEdit) {
            setValue("name", subCategoryInfo.label);
        }
    }, [subCategoryInfo]);


    useEffect(() => {
        if (isAddSuccess || isUpdateSuccess) {
            dispatch(resetCreateTempAssSubCategory());
            dispatch(resetEditTempAssSubCategory());
            onClose();
        }
    }, [dispatch, isAddSuccess, isUpdateSuccess, onClose]);


    const onSubmit = (data) => {
        data.categoryId = categoryId;
        if (isEdit) {
            data.id = subCategoryInfo.value;
            dispatch(editTempAssSubCategory(data));
        } else {
            dispatch(createTempAssSubCategory(data));
        }
    };

    const isLoading = !!(isAdding || isUpdating);
    return (

        <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name='name'
                defaultValue=''
                rules={tempAssPattern.name}
                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                    <FormControl fullWidth required error={!!(invalid && error.message)}>
                        <FormLabel>Sub Category Name</FormLabel>
                        <TextField
                            autoFocus
                            type='text'
                            size={'small'}
                            placeholder='Enter a name'
                            value={value}
                            onChange={onChange}
                            error={!!(invalid && error.message)}
                            helperText={invalid && error.message}
                        />
                    </FormControl>
                )}
            />

            <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'flex-end'} mt={2} spacing={1}>
                <Button variant={'text'} size={'small'} onClick={onClose}>Cancel</Button>
                <Button
                    type={"submit"}
                    size={'small'}
                    variant={'contained'}
                    disabled={isLoading}
                >
                    {isEdit ? "Update" : "Add"}
                </Button>
            </Stack>
        </Box>
    );
};

export default AddOrEditSubCategory;