import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {FormControl, FormLabel, Stack} from "@mui/material";
import PageTitle from "../../common/PageTitle";
import {JOB_POST_PACKAGE_TYPE_LIST, JOB_POST_PUBLISH_STATUS_CONTENT} from "../../helpers/constant/jobPostConstant";
import {addFilterPackageType, addFilterPublishStatus} from "../../state/jobPostList/jobPostListSlice";
import {selectJobPostFilters} from "../../state/jobPostList/jobPostListSelector";

const JobPostListHeader = () => {
    const dispatch = useDispatch();
    const {publishStatus, packageType} = useSelector(selectJobPostFilters);

    const handleChangePublishOption = (newValue) => {
        dispatch(addFilterPublishStatus(newValue.value))
    }

    const handleChangePackageType = (newValue) => {
        dispatch(addFilterPackageType(newValue.value))
    }

    const AllOptionObj = {"": {value: "", label: "All"}};
    const publishStatusOptions = {...AllOptionObj, ...JOB_POST_PUBLISH_STATUS_CONTENT};

    const packageTypeOptions = {...AllOptionObj, ...JOB_POST_PACKAGE_TYPE_LIST};

    return (
        <div>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
                <PageTitle title={'Jobs'} mb={0} subTitleMb={0}/>
                <Stack direction={'row'} spacing={2}>
                    <FormControl sx={{width: 300, mb: 3}}>
                        <FormLabel>Filter By Package:</FormLabel>
                        <Select
                            className={"select-dropdown-menu select-dropdown-menu-custom"}
                            classNamePrefix={"select-dropdown-styles"}
                            isMulti={false}
                            isSearchable={false}
                            menuPlacement='bottom'
                            options={Object.values(packageTypeOptions)}
                            value={packageTypeOptions[packageType]}
                            onChange={handleChangePackageType}
                        />
                    </FormControl>
                    <FormControl sx={{width: 300, mb: 3}}>
                        <FormLabel>Filter By Status:</FormLabel>
                        <Select
                            className={"select-dropdown-menu select-dropdown-menu-custom"}
                            classNamePrefix={"select-dropdown-styles"}
                            isMulti={false}
                            isSearchable={false}
                            menuPlacement='bottom'
                            value={publishStatusOptions[publishStatus]}
                            options={Object.values(publishStatusOptions)}
                            onChange={handleChangePublishOption}
                        />
                    </FormControl>
                </Stack>
            </Stack>
        </div>
    );
};

export default JobPostListHeader;