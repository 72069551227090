import React from 'react';
import {Paper} from "@mui/material";
import JobPostListHeader from "./JobPostListHeader";
import JobPostListTable from "./JobPostListTable";

const Jobs = () => {
    return (
        <Paper elevation={0}>
            <JobPostListHeader />
            <JobPostListTable />
        </Paper>
    );
};

export default Jobs;