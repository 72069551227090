import httpRequestAdmin from "./httpRequest/httpRequestAdmin";

class JobSeekerService {
    getJobSeekersData(params) {
        return httpRequestAdmin.get(process.env.REACT_APP_BACKEND_API_URL + "admin/jobSeeker/list", params);
    }
    updateJobSeekerStatus(body) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/jobSeeker/update/status", body);
    }
}

export default new JobSeekerService();
