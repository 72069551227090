import {all, call, put, takeLatest} from "redux-saga/effects";
import MessageTemplateService from "../../services/MessageTemplate.service";
import {
    createMessageTemplateSuccess, deleteMessageTemplateSuccess, editMessageTemplateSuccess,
    getMessageTemplatesFailed,
    getMessageTemplatesSuccess, resetCreateMessageTemplate, resetDeleteMessageTemplate, resetEditMessageTemplate
} from "./messageTemplateSlice";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utils/toaster";


function* messageTemplateWatcher() {
    yield takeLatest("messageTemplate/getMessageTemplates", getMessageTemplatesSaga);
    yield takeLatest("messageTemplate/createMessageTemplate", createMessageTemplateSaga);
    yield takeLatest("messageTemplate/editMessageTemplate", editMessageTemplateSaga);
    yield takeLatest("messageTemplate/deleteMessageTemplate", deleteMessageTemplateSaga);
}

function* getMessageTemplatesSaga(action) {
    try {
        const response = yield call(MessageTemplateService.getMessageTemplates, action.payload);

        if (response.status) {
            yield put(getMessageTemplatesSuccess(response.data));
        } else {
            yield put(getMessageTemplatesFailed(response.message));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* createMessageTemplateSaga(action) {
    try {
        const response = yield call(MessageTemplateService.createMessageTemplate, action.payload);

        if (response.status) {
            yield put(createMessageTemplateSuccess(response.data));
            showSuccessToaster(response.message);
        } else {
            yield put(resetCreateMessageTemplate(response.data));
            showErrorToaster(response.message);
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* editMessageTemplateSaga(action) {
    try {
        const response = yield call(MessageTemplateService.editMessageTemplate, action.payload);

        if (response.status) {
            yield put(editMessageTemplateSuccess(action.payload));
            showSuccessToaster(response.message);
        } else {
            yield put(resetEditMessageTemplate(response.data));
            showErrorToaster(response.message);
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* deleteMessageTemplateSaga(action) {
    try {
        const response = yield call(MessageTemplateService.deleteMessageTemplate, action.payload);

        if (response.status) {
            yield put(deleteMessageTemplateSuccess(action.payload));
            showSuccessToaster(response.message);
        } else {
            yield put(resetDeleteMessageTemplate(response.data));
            showErrorToaster(response.message);
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* messageTemplateSaga() {
    yield all([messageTemplateWatcher()]);
}
