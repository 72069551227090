import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {selectMessageTemplateState} from "../../state/messageTemplate/messageTemplateSelector";
import {getMessageTemplates} from "../../state/messageTemplate/messageTemplateSlice";

const HOCTemplateList =
    (Component) =>
        // eslint-disable-next-line react/display-name
    ({ ...rest }) => {
        const dispatch = useDispatch();

        const { data = [], isLoading, page, perPage, count, filters } = useSelector(selectMessageTemplateState);
        const { type } = filters || {};
        console.log({type})

        useEffect(() => {
            dispatch(getMessageTemplates({ page: page, perPage }));
        }, [dispatch, page, perPage]);

        const onChangePerPage = (perPage, newPage) => {
            dispatch(getMessageTemplates({ page: newPage, perPage: perPage }));
        };

        const onChangePage = (newPage) => {
            dispatch(getMessageTemplates({ page: newPage, perPage: perPage }));
        };

        return (
            <Component
                {...rest}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
                isLoading={isLoading}
                data={data}
                page={page}
                perPage={perPage}
                count={count}
            />
        );
    };

export default HOCTemplateList;
