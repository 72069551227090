export const RESPONSE_LOADING_TIME = 1000;
export const DEBOUNCE_DELAY_TIME = 1000;

export const USER_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
};

export const JOB_SEEKER_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
};

export const JOB_SEEKER_STATUS_TEXT = {
    [JOB_SEEKER_STATUS.ACTIVE]: "Active",
    [JOB_SEEKER_STATUS.INACTIVE]: "Block",
}

export const COMPANY_STATUS = {
    INACTIVE: 0,
    ACTIVE: 1,
    PENDING: 2,
};

export const COMPANY_STATUS_TEXT = {
    [COMPANY_STATUS.ACTIVE]: "Active",
    [COMPANY_STATUS.INACTIVE]: "Blocked",
    [COMPANY_STATUS.PENDING]: "Pending",
}

export const COMPANY_STATUS_COLOR = {
    [COMPANY_STATUS.ACTIVE]: "success",
    [COMPANY_STATUS.INACTIVE]: "warning",
    [COMPANY_STATUS.PENDING]: "secondary",
}

export const COMPANY_STATUS_LIST = [
    {label: "Active", value: 1},
    {label: "Blocked", value: 0},
]
export const EMPLOYEE_ROLE__ADMIN = 1;
export const EMPLOYEE_ROLE__ADMIN_TITLE = "Administrator";
export const EMPLOYEE_ROLE__TEAM_MEMBER = 2;

export const BLOG_CATEGORIES = [
    {label: "Job Search Tips", value: "Job Search Tips"},
    {label: "Hiring Remotely", value: "Hiring Remotely"},
    {label: "Working Remotely", value: "Working Remotely"},
    {label: "News", value: "News"},
    {label: "Archive", value: "Archive"},
];

export const TEMPLATES_CATEGORIES = [
    {label: "Interview", value: "Interview"},
    {label: "Messages", value: "Messages"},
];

export const ASSESSMENTS_TAGS = [
    {label: "PHP", value: "PHP"},
    {label: "REACT", value: "REACT"},
    {label: "Angular", value: "Angular"},
    {label: "JAVA", value: "JAVA"},
    {label: "PYTHON", value: "PYTHON"},
];