import { styled } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }}/>
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#012D41",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#012D41",
        fontSize: 14,
    },
}));
