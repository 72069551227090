import {all, call, put, takeLatest} from "redux-saga/effects";
import QuestionCategoryService from "../../services/questionCategory.service";
import {
    createQuestionCategorySuccess,
    createQuestionSubCategorySuccess,
    deleteQuestionCategorySuccess,
    deleteQuestionSubCategorySuccess,
    editQuestionCategorySuccess,
    editQuestionSubCategorySuccess,
    getQuestionCategoryListFailed,
    getQuestionCategoryListSuccess,
    resetCreateQuestionCategory,
    resetCreateQuestionSubCategory, resetDeleteQuestionCategory, resetDeleteQuestionSubCategory,
    resetEditQuestionCategory,
    resetEditQuestionSubCategory,
    updateQuestionCategoryStatusFailed,
    updateQuestionCategoryStatusSuccess
} from "./questionCategorySlice";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utils/toaster";

function* questionCategoryWatcher() {
    yield takeLatest("questionCategory/getQuestionCategoryList", getQuestionCategoryListSaga);
    yield takeLatest("questionCategory/createQuestionCategory", createQuestionCategorySaga);
    yield takeLatest("questionCategory/editQuestionCategory", editQuestionCategorySaga);
    yield takeLatest("questionCategory/updateQuestionCategoryStatus", updateQuestionCategoryStatusSaga);
    yield takeLatest("questionCategory/deleteQuestionCategory", deleteQuestionCategorySaga);

    yield takeLatest("questionCategory/createQuestionSubCategory", createQuestionSubCategorySaga);
    yield takeLatest("questionCategory/editQuestionSubCategory", editQuestionSubCategorySaga);
    yield takeLatest("questionCategory/deleteQuestionSubCategory", deleteQuestionSubCategorySaga);
}

function* getQuestionCategoryListSaga(action) {
    try {
        const response = yield call(QuestionCategoryService.getQuestionCategoryList, action.payload);

        if (response.status) {
            yield put(getQuestionCategoryListSuccess(response.data));
        } else {
            yield put(getQuestionCategoryListFailed(response.message));
        }
    } catch (err) {
        yield put(getQuestionCategoryListFailed(err.message));
    }
}

function* createQuestionCategorySaga(action) {
    try {
        const response = yield call(QuestionCategoryService.createQuestionCategory, action.payload);

        if (response.status) {
            yield put(createQuestionCategorySuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(resetCreateQuestionCategory(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* editQuestionCategorySaga(action) {
    try {
        const response = yield call(QuestionCategoryService.editQuestionCategory, action.payload);

        if (response.status) {
            yield put(editQuestionCategorySuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(resetEditQuestionCategory(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* updateQuestionCategoryStatusSaga(action) {
    try {
        const response = yield call(QuestionCategoryService.updateQuestionCategoryStatus, action.payload);

        if (response.status) {
            yield put(updateQuestionCategoryStatusSuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(updateQuestionCategoryStatusFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* deleteQuestionCategorySaga(action) {
    try {
        const response = yield call(QuestionCategoryService.deleteQuestionCategory, action.payload);

        if (response.status) {
            yield put(deleteQuestionCategorySuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(resetDeleteQuestionCategory(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* createQuestionSubCategorySaga(action) {
    try {
        const response = yield call(QuestionCategoryService.createQuestionSubCategory, action.payload);

        if (response.status) {
            yield put(createQuestionSubCategorySuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(resetCreateQuestionSubCategory(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* editQuestionSubCategorySaga(action) {
    try {
        const response = yield call(QuestionCategoryService.editQuestionSubCategory, action.payload);

        if (response.status) {
            yield put(editQuestionSubCategorySuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(resetEditQuestionSubCategory(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* deleteQuestionSubCategorySaga(action) {
    try {
        const response = yield call(QuestionCategoryService.deleteQuestionSubCategory, action.payload);

        if (response.status) {
            yield put(deleteQuestionSubCategorySuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(resetDeleteQuestionSubCategory(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}



export default function* questionCategorySaga() {
    yield all([questionCategoryWatcher()]);
}
