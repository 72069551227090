import React from "react";
import TablePagination from "@mui/material/TablePagination";

const GlobalTablePagination = ({
    component = "div",
    count = 10,
    page = 1,
    rowsPerPage = 10,
    rowsPerPageOptions = [10, 20, 30, 50, 100],
    onChangePage,
    onChangePerPage,
    ...rest
}) => {
    const handleChangePage = (event, newPage) => {
        if (onChangePage) onChangePage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        const perPage = parseInt(event.target.value, 10);
        if (onChangePerPage) onChangePerPage(perPage, 1);
    };

    return (
        <TablePagination
            component={component}
            count={count}
            page={page - 1}
            onPageChange={handleChangePage}
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            {...rest}
        />
    );
};

export default GlobalTablePagination;
