import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    page: 1,
    perPage: 10,
    count: 0,
    isLoading: false,
    isError: false,
    error: "",

    isUpdating: false,
};

const jobSeekerSlice = createSlice({
    name: "jobSeeker",
    initialState: initialState,
    reducers: {
        getJobSeekersData: (state) => {
            state.isLoading = true;
        },
        getJobSeekersDataSuccess: (state, action) => {
            state.data = action.payload.rows;
            state.page = +action.payload.page;
            state.perPage = +action.payload.perPage;
            state.count = action.payload.count;
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        },
        getJobSeekersDataFailed: (state, action) => {
            state.data = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        updateJobSeekerStatus: (state) => {
            state.isUpdating = true;
        },
        updateJobSeekerStatusSuccess: (state, action) => {
            state.data = state.data.map(item => {
                if(item.id === action.payload.id) {
                    return {...item, status: action.payload.status}
                }
                return item;
            });
            state.isUpdating = false;
        },
        updateJobSeekerStatusFailed: (state) => {
            state.allUserData = [];
            state.isUpdating = false;
        },
    },
});

export const {
    getJobSeekersData,
    getJobSeekersDataSuccess,
    getJobSeekersDataFailed,

    updateJobSeekerStatus,
    updateJobSeekerStatusSuccess,
    updateJobSeekerStatusFailed
} = jobSeekerSlice.actions;

export default jobSeekerSlice.reducer;
