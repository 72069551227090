import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

const CustomEditor = ({ theme = 'snow', height = 150, placeholder = 'Write your message...', forwardedRef, ...rest }) => {
    const modules = {
        toolbar: [
            // [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'link', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['code-block', 'clean'],
        ],
    };

    return (
        <ReactQuill
            ref={forwardedRef}
            theme={theme}
            placeholder={placeholder}
            modules={modules}
            style={{ height: height, marginBottom: '48px' }}
            {...rest}
        />
    );
};

export default CustomEditor;
