import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    page: 1,
    perPage: 10,
    count: 0,
    isLoading: false,
    isError: false,
    error: "",

    isUpdating: false,
    isDeleting: false,
    isDeleteSuccess: false,

    filters: {
        categoryId: "",
        subCategoryId: "",
        difficulty: "",
        questionType: ""
    }
};

const assQuestionSlice = createSlice({
    name: "assQuestion",
    initialState: initialState,
    reducers: {
        getAssQuestionList: (state) => {
            state.isLoading = true;
        },
        getAssQuestionListSuccess: (state, action) => {
            state.data = action.payload.rows;
            state.page = +action.payload.page;
            state.perPage = +action.payload.perPage;
            state.count = +action.payload.count;
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        },
        getAssQuestionListFailed: (state, action) => {
            state.data = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        createAssQuestion: (state) => {
            state.isAdding = true;
        },
        createAssQuestionSuccess: (state, action) => {
            state.isAdding = false;
            state.isAddSuccess = true;
            state.data.unshift(action.payload);
            state.count += 1;
        },
        resetCreateAssQuestion: (state) => {
            state.isAdding = false;
            state.isAddSuccess = false;
        },

        editAssQuestion: (state) => {
            state.isUpdating = true;
        },
        editAssQuestionSuccess: (state, action) => {
            state.isUpdating = false;
            state.isUpdateSuccess = true;
            state.data = state.data.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload };
                }
                return item;
            });
        },
        resetEditAssQuestion: (state) => {
            state.isUpdating = false;
            state.isUpdateSuccess = false;
        },

        updateAssQuestionStatus: (state) => {
            state.isUpdating = true;
        },
        updateAssQuestionStatusSuccess: (state, action) => {
            state.data = state.data.map(item => {
                if(item.id === action.payload.id) {
                    return {...item, status: action.payload.status}
                }
                return item;
            });
            state.isUpdating = false;
        },
        updateAssQuestionStatusFailed: (state) => {
            state.isUpdating = false;
        },

        deleteAssQuestion: (state) => {
            state.isDeleting = true;
        },
        deleteAssQuestionSuccess: (state, action) => {
            state.isDeleting = false;
            state.isDeleteSuccess = true;
            state.data = state.data.filter((item) => item.id !== action.payload.id);
            state.count -= 1;
        },
        resetDeleteAssQuestion: (state) => {
            state.isDeleting = false;
            state.isDeleteSuccess = false;
        },

        addFilterDifficulty: (state, action) => {
            state.filters.difficulty = action.payload;
        },
        addFilterQuestionType: (state, action) => {
            state.filters.questionType = action.payload;
        },
        addCategoryAndSubCategory: (state, action) => {
            state.filters.categoryId = action.payload.categoryId;
            state.filters.subCategoryId = action.payload.subCategoryId;
        }
    },
});

export const {
    getAssQuestionList,
    getAssQuestionListSuccess,
    getAssQuestionListFailed,

    createAssQuestion,
    createAssQuestionSuccess,
    resetCreateAssQuestion,

    editAssQuestion,
    editAssQuestionSuccess,
    resetEditAssQuestion,

    updateAssQuestionStatus,
    updateAssQuestionStatusSuccess,
    updateAssQuestionStatusFailed,

    deleteAssQuestion,
    deleteAssQuestionSuccess,
    resetDeleteAssQuestion,

    addFilterDifficulty,
    addFilterQuestionType,
    addCategoryAndSubCategory
} = assQuestionSlice.actions;

export default assQuestionSlice.reducer;
