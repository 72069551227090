import React from 'react';
import Select from "react-select";
import {FormControl, FormLabel, Stack, TextField} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {COMPANY_STATUS_LIST} from "../../helpers/constant/coreConstant";
import PageTitle from "../../common/PageTitle";

const JobSeekerHeader = () => {
    const AllOptionObj = {"": {value: "", label: "All"}};
    const companyStatusOptions = {...AllOptionObj, ...COMPANY_STATUS_LIST};
    return (
        <>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
                <PageTitle title={'Job Seeker'} mb={0} subTitleMb={0}/>
                <Stack direction={'row'} spacing={2}>
                    <FormControl sx={{width: 330, mb: 3}}>
                        <FormLabel>Search By Job Seeker Email:</FormLabel>
                        <TextField
                            size={"small"}
                            type={"search"}
                            placeholder={"Search By Job Seeker Email"}
                            sx={{"& .MuiInputBase-root": {height: 48}}}
                            InputProps={{
                                startAdornment: <SearchRoundedIcon fontSize={"small"} color={"action"} sx={{mr: 0.5}}/>,
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{width: 300, mb: 3}}>
                        <FormLabel>Filter By Job Seeker Status:</FormLabel>
                        <Select
                            className={"select-dropdown-menu select-dropdown-menu-custom"}
                            classNamePrefix={"select-dropdown-styles"}
                            isMulti={false}
                            isSearchable={false}
                            menuPlacement='bottom'
                            options={Object.values(companyStatusOptions)}
                        />
                    </FormControl>
                </Stack>
            </Stack>
        </>
    );
};

export default JobSeekerHeader;