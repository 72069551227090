import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import useBoolean from "../../hooks/useBoolean";
import BasicDrawer from "../../global/Drawer/BasicDrawer";

const JobDetails = ({jobPost}) => {
    const {
        title,
        description,
        jobType,
        salary,
        regions,
        openWorldWide,
        jobCategory,
        language,
        timeZones,
        numberOfVacancy,
        noteForCandidate,
        tags,
        departmentName,
        experiences,
        externalFormLink,
        responsibleGroupId,
        totalApplyCandidate,
        companyInfo,
    } = jobPost || {};

    const {value: openDrawer, setTrue: onOpenDrawer, setFalse: onCloseDrawer} = useBoolean(false);
    const regionStatus = openWorldWide
        ? "/worldwide"
        : regions && typeof regions === "string" && "/" + regions.split(",").join("/");

    return (
        <>
            <Stack direction={'row'} alignItems={'center'}>
                <Typography
                    title={title}
                    variant={"subtitle2"}
                    className={'text-truncate'}
                    onClick={onOpenDrawer}
                    sx={{width: '300px', cursor: "pointer", "&:hover": {textDecoration: "underline"}}}>
                    {title}
                </Typography>
            </Stack>
            <BasicDrawer title={"Job details of " + title} open={openDrawer} onClose={onCloseDrawer}>
                <Stack direction={'column'} spacing={1} flexWrap={'wrap'} mb={2}>
                    <Typography variant={'body1'}><b>{jobType || 'Full time'}{regionStatus}</b></Typography>
                    <Typography variant={'body1'}>Job Category - <b>{jobCategory}</b></Typography>
                    <Typography variant={'body1'}>Company Logo -</Typography>
                    {companyInfo.companyLogo ?
                        <img alt={''} src={companyInfo.companyLogo} width={200}/> : 'not defined'
                    }
                    <Typography variant={'body1'}>Language - <b>{language || 'not defined'}</b></Typography>
                    <Typography variant={'body1'}>Salary - <b>{salary || 'not defined'}</b></Typography>
                    <Typography variant={'body1'}>Time Zones - <b>{timeZones || 'not defined'}</b></Typography>
                    <Typography variant={'body1'}>Relevant Job Tags - <b>{tags || 'not defined'}</b></Typography>
                    <Typography variant={'body1'}>Department
                        - <b>{departmentName || 'not defined'}</b></Typography>
                    <Typography variant={'body1'}>Experience Level - <b>{experiences || 'not defined'}</b></Typography>
                    <Typography variant={'body1'} className={'text-truncate'} sx={{width: 650}}
                                title={externalFormLink}>External Form Link
                        - <b><a rel="noreferrer" href={externalFormLink}
                                target={'_blank'}>{externalFormLink || 'No'}</a></b></Typography>
                    <Typography variant={'body1'}>Responsible Group - <b>{responsibleGroupId || 'No'}</b></Typography>
                    <Typography variant={'body1'}>Total Candidate
                        - <b>{totalApplyCandidate || 'No Candidate'}</b> {totalApplyCandidate > 10 && '🔥'}</Typography>
                    <Typography variant={'body1'}>Number Of Vacancy
                        - <b>{numberOfVacancy || 'not defined'}</b></Typography>
                </Stack>
                <Stack direction={'column'} spacing={2}>
                    <Box>
                        <Typography variant={'h6'}>Job Description</Typography>
                        <div dangerouslySetInnerHTML={{__html: description || 'Not defined'}}/>
                    </Box>
                    <Box>
                        <Typography variant={'h6'}>Notes For Candidate</Typography>
                        <div dangerouslySetInnerHTML={{__html: noteForCandidate || 'Not defined'}}/>
                    </Box>
                </Stack>
            </BasicDrawer>
        </>
    );
};

export default JobDetails;