import React from 'react';
import Box from "@mui/material/Box";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import HOCTemplateAssessment from "./HOCTemplateAssessment";
import SingleTemplateAssessmentRaw from "./SingleTemplateAssessmentRaw";
import TableLoaderSkeleton from "../../../global/Loader/TableLoaderSkeleton";
import GlobalEmptyPage from "../../../common/GlobalEmptyPage/GlobalEmptyPage";
import GlobalTablePagination from "../../../global/Pagination/GlobalTablePagination";
import EmptyBoxLogo from "../../../assets/images/inbox-empty.png";

const TemplateAssessmentTable = ({
                                     data: templateAssessmentList = [],
                                     isLoading = false,
                                     page = 1,
                                     perPage = 10,
                                     count = 0,
                                     onChangePage,
                                     onChangePerPage,
                                 }) => {

    // decide what to render
    let content = null;
    if (isLoading) {
        content = <TableLoaderSkeleton columns={4} numberOfRows={10}/>;
    } else if (templateAssessmentList?.length === 0) {
        content = <GlobalEmptyPage image={EmptyBoxLogo} title={"No Template found!"}/>;
    } else if (templateAssessmentList?.length > 0) {
        content = (
            <Box sx={{width: "100%"}}>
                <TableContainer>
                    <Table sx={{minWidth: 650}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Skills</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {templateAssessmentList.map(templateAssessment => (
                                <SingleTemplateAssessmentRaw key={templateAssessment.id} templateAssessmentInfo={templateAssessment} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <GlobalTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={perPage}
                    onChangePage={onChangePage}
                    onChangePerPage={onChangePerPage}
                />
            </Box>
        )
    }

    return content;
};

export default HOCTemplateAssessment(TemplateAssessmentTable);