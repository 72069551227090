export const loginRegisterPattern = {
    username: {
        required: "user name is required!",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    password: {
        required: "Password is required",
        minLength: {
            value: 6,
            message: "Password must have at least 6 characters",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
};

export const messageTemplatePattern = {
    name: {
        required: "Template name is required!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Template name is empty",
        },
    },
    subject: {
        required: "Subject is required!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Subject is empty",
        },
    },
    body: {
        required: "Message body is required!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Message body is empty",
        },
    },
};

export const blogPattern = {
    title: {
        required: "Blog title is required!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Blog title name is empty",
        },
        maxLength: {
            value: 120,
            message: "Blog title must have less than 120 characters!",
        },
    },
    description: {
        required: "Content is required!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Content is empty",
        },
    },
    category: {
        required: "category is required!",
    },

};

export const employerTemplatePattern = {
    name: {
        required: "Template name is required!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Template name is empty",
        },
        maxLength: {
            value: 120,
            message: "Template name should be less than 120 characters!",
        },
    },
    subject: {
        required: "Template subject is required!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Template subject is empty",
        },
        maxLength: {
            value: 120,
            message: "Template subject must have less than 120 characters!",
        },
    },
    body: {
        required: "Template content is required!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Template content is empty",
        },
    },
    type: {
        required: "Template category is required!",
    },
}

export const assQuestionCategoryPattern = {
    name: {
        required: "Category name is required!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Category name is empty",
        },
        maxLength: {
            value: 30,
            message: "Category name should be less than 30 characters!",
        },
    },
}

export const assQuestionSubCategoryPattern = {
    name: {
        required: "Sub-category is required!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Sub-category is empty",
        },
        maxLength: {
            value: 30,
            message: "Sub-category should be less than 30 characters!",
        },
    },
}


export const assQuestionPattern = {
    content: {
        required: "Question is required!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Question name is empty",
        },
        maxLength: {
            value: 100000,
            message: "Question maximum character limit exit!",
        },
    },
    category: {
        required: "Category is required!",
    },
    subCategory: {
        required: "Sub-category is required!",
    },
    difficulty: {
        required: "Weight is required!",
    },
    questionType: {
        required: "Question Type is required!",
    },
    optionContent: {
        required: "Option is empty!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Option is empty",
        },
        maxLength: {
            value: 100000,
            message: "Option maximum character limit exit!",
        },
    },
};


export const tempAssPattern = {
    name: {
        required: "Name is required!",
        pattern: {
            value: /^(?!\s*$).+/,
            message: "Name is empty",
        },
        maxLength: {
            value: 100000,
            message: "Name maximum character limit exit!",
        },
    },
    category: {
        required: "Category is required!",
    },
    subCategory: {
        required: "Sub-category is required!",
    },
    skills: {
        required: "Skills is required!",
    },
};