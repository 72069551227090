import httpRequestAdmin from "./httpRequest/httpRequestAdmin";

class TempAssCategoryService {
    getTempAssCategoryList(params) {
        return httpRequestAdmin.get(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/category/admin/all", params);
    }
    createTempAssCategory(params) {
        return httpRequestAdmin.post(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/category/admin/create", params);
    }
    editTempAssCategory(params) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/category/admin/update", params);
    }
    updateTempAssCategoryStatus(body) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/category/admin/update/status", body);
    }
    deleteTempAssCategory(params) {
        return httpRequestAdmin.delete(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/category/admin/delete", params);
    }

    createTempAssSubCategory(params) {
        return httpRequestAdmin.post(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/sub-category/admin/create", params);
    }
    editTempAssSubCategory(params) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/sub-category/admin/update", params);
    }
    deleteTempAssSubCategory(params) {
        return httpRequestAdmin.delete(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/sub-category/admin/delete", params);
    }
}

export default new TempAssCategoryService();
