import React from "react";
import { CircularProgress, Stack } from "@mui/material";

const CircleLoader = () => {
    return (
        <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{ height: "70vh" }}>
            <CircularProgress color='secondary' />
        </Stack>
    );
};

export default CircleLoader;
