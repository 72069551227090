import React from "react";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Clear } from "@mui/icons-material";
import Divider from "@mui/material/Divider";

const BasicDrawer = ({ open, onClose, title = "", width = 850, children, anchor = "right" , top, left = 'auto'}) => {
    return (
        <Drawer
            anchor={anchor}
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiPaper-root": {
                    maxWidth: width,
                    width: "100%",
                },
            }}
            PaperProps={{
                style: {
                    left: left,
                    top: top
                },
            }}
        >
            <Box sx={{ height: "100%" }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} px={3} py={2}>
                    <Typography variant={"h6"}>{title}</Typography>
                    <IconButton onClick={onClose} color={"error"}>
                        <Clear />
                    </IconButton>
                </Stack>

                <Divider />
                <Box sx={{ px: 3, py: 2 }}>{children}</Box>
            </Box>
        </Drawer>
    );
};

export default BasicDrawer;
