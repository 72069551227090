import httpRequestAdmin from "./httpRequest/httpRequestAdmin";

class MessageTemplateService {
    getMessageTemplates(params) {
        return httpRequestAdmin.get(process.env.REACT_APP_BACKEND_API_URL + "admin/message/template/list", params);
    }

    createMessageTemplate(body) {
        return httpRequestAdmin.post(process.env.REACT_APP_BACKEND_API_URL + "admin/message/template/create", body);
    }

    editMessageTemplate(body) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/message/template/update", body);
    }

    deleteMessageTemplate(body) {
        return httpRequestAdmin.delete(process.env.REACT_APP_BACKEND_API_URL + "admin/message/template/delete", body);
    }
}

export default new MessageTemplateService();
