import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectEmployerTemplateState} from "../../state/employerTemplate/employerTemplateSelector";
import {getEmployerTemplates} from "../../state/employerTemplate/employerTemplateSlice";

const HOCTemplateForEmployer =
    (Component) =>
        // eslint-disable-next-line react/display-name
    ({ ...rest }) => {
        const dispatch = useDispatch();

        const { data = [], isLoading, page, perPage, count, filters } = useSelector(selectEmployerTemplateState);
        const { type } = filters || {};

        useEffect(() => {
            dispatch(getEmployerTemplates({ page: 1, perPage, type }));
        }, [dispatch, type]);

        const onChangePerPage = (perPage, newPage) => {
            dispatch(getEmployerTemplates({ page: newPage, perPage: perPage, type }));
        };

        const onChangePage = (newPage) => {
            dispatch(getEmployerTemplates({ page: newPage, perPage: perPage, type }));
        };

        return (
            <Component
                {...rest}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
                isLoading={isLoading}
                data={data}
                page={page}
                perPage={perPage}
                count={count}
            />
        );
    };

export default HOCTemplateForEmployer;
