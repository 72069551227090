import React from "react";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import {componentOverRide, lightModePalette, typographyProperties} from "../../helpers/constant/themeConstant";

const ThemeLayout = ({ children }) => {
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: lightModePalette,
                typography: typographyProperties,
                components: componentOverRide
            }),
        []
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default ThemeLayout;