import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ThemeLayout from "./components/layouts/themeLayout";
import ClientProvider from "./state/ClientProvider";
import SignIn from "./components/SignIn";
import Report from "./components/report/Report";
import Jobs from "./components/jobs/Jobs";
import JobSeeker from "./components/jobSeeker/JobSeeker";
import Companies from "./components/companies/Companies";
import DashboardLayout from "./components/layouts/DashboardLayout";
import AuthCheckerLayout from "./components/layouts/authCheckerLayout";
import PrivateLayoutAdmin from "./components/layouts/privateLayoutAdmin";
import MessageContent from "./components/messageContent/MessageContent";
import TemplatesBody from "./components/teamplates/TemplatesBody";
import Templates from "./components/assessments/templates/Templates";
import Questions from "./components/assessments/questions/Questions";


const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <AuthCheckerLayout>
                <SignIn/>
            </AuthCheckerLayout>
        ),
    },
    {
        path: "/dashboard",
        element: (
            <PrivateLayoutAdmin>
                <DashboardLayout/>
            </PrivateLayoutAdmin>
        ),
        children: [
            {
                path: "",
                element: <Jobs/>,
            },
            {
                path: "jobs",
                element: <Jobs/>,
            },
            {
                path: "companies",
                element: <Companies/>,
            },
            {
                path: "job-seeker",
                element: <JobSeeker/>,
            },
            {
                path: "message-content",
                element: <MessageContent/>,
            },
            {
                path: "templates",
                element: <TemplatesBody/>,
            },
            {
                path: `assessments-templates`,
                element: <Templates/>,
            },
            {
                path: `assessments-questions`,
                element: <Questions/>,
            },
            {
                path: "report",
                element: <Report/>,
            },
        ],
    },
]);


function App() {
    return (
        <ThemeLayout>
            <ClientProvider>
                <RouterProvider router={router}/>
            </ClientProvider>
        </ThemeLayout>
    );
}

export default App;
