import { createSlice } from "@reduxjs/toolkit";
import cookieService from "../../services/cookie.service";

const initialState = {
    data: null,
    isLoading: false,
    isLogin: false,
    isError: false,
    error: "",
};

const adminAuthSlice = createSlice({
    name: "adminAuth",
    initialState,
    reducers: {
        loginAdmin: (state) => {
            state.isLoading = true;
        },
        loginAdminSuccess: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
            state.isLogin = true;
            state.isError = false;
            state.error = "";
        },
        loginAdminFailed: (state, action) => {
            state.isLoading = false;
            state.isLogin = false;
            state.isError = true;
            state.data = null;
            state.error = action.payload;
        },
        resetLoginAdmin: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        },

        getAdminData: (state) => {
            state.isLoading = true;
        },
        getAdminDataSuccess: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
            state.isLogin = true;
        },
        getAdminDataFailed: (state) => {
            state.data = null;
            state.isLoading = false;
            state.isLogin = false;
        },
        adminLogOut: (state) => {
            state.data = null;
            state.isLogin = false;
            state.isError = false;
            state.error = "";
            cookieService.setCookie(process.env.REACT_APP_ADMIN_ACCESS_TOKEN, "");
        },
    },
});

export const {
    loginAdmin,
    loginAdminSuccess,
    loginAdminFailed,
    resetLoginAdmin,

    getAdminData,
    getAdminDataSuccess,
    getAdminDataFailed,

    adminLogOut,
} = adminAuthSlice.actions;

export default adminAuthSlice.reducer;
