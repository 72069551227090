import React from 'react';
import {Grid} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import RateReviewIcon from "@mui/icons-material/RateReview";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import {green} from "@mui/material/colors";
import SingleReportCard from "./SingleReportCard";

const ReportHeader = () => {

    return (
        <Grid container spacing={2} sx={{mt:0}}>
            <Grid item xs={12} sm={6} lg={3}>
                <SingleReportCard
                    title={'Companies'}
                    subtitle={'Total Page'}
                    amount={22}
                    isLoading={false}
                    icon={<BusinessCenterIcon />}
                    color={'info.light'}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <SingleReportCard
                    title={'Job seeker'}
                    subtitle={'Total Job seeker'}
                    amount={44}
                    isLoading={false}
                    icon={<DynamicFormIcon />}
                    color={'secondary.light'}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <SingleReportCard
                    title={'Jobs'}
                    subtitle={'Total Jobs'}
                    amount={55}
                    isLoading={false}
                    icon={<PreviewIcon />}
                    color={'primary.light'}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <SingleReportCard
                    title={'Best jobs'}
                    subtitle={'Total Best jobs'}
                    amount={66}
                    isLoading={false}
                    icon={<RateReviewIcon />}
                    color={green[300]}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <SingleReportCard
                    title={'Better jobs'}
                    subtitle={'Total Better jobs'}
                    amount={66}
                    isLoading={false}
                    icon={<RateReviewIcon />}
                    color={green[300]}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <SingleReportCard
                    title={'Good jobs'}
                    subtitle={'Total Good jobs'}
                    amount={66}
                    isLoading={false}
                    icon={<RateReviewIcon />}
                    color={green[300]}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <SingleReportCard
                        title={'Lunabo (Company name)'}
                    subtitle={'Most job posted by'}
                    amount={66}
                    isLoading={false}
                    icon={<RateReviewIcon />}
                    color={green[300]}
                />
            </Grid>
        </Grid>
    );
};

export default ReportHeader;