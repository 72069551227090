import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {Box, Button, FormControl, FormHelperText, FormLabel, Grid, Stack, TextField} from "@mui/material";
import CustomEditorWithPersonalizeTag from "../../common/Editor/CustomEditorWithPersonalizeTag";
import {selectMessageTemplateState} from "../../state/messageTemplate/messageTemplateSelector";
import {
    createMessageTemplate,
    editMessageTemplate,
    resetCreateMessageTemplate,
    resetEditMessageTemplate
} from "../../state/messageTemplate/messageTemplateSlice";
import {messageTemplatePattern} from "../../helpers/constant/registerPattern";
import {
    PERSONALIZE_TAGS_COMPANY,
    PERSONALIZE_TAGS_EMPLOYER,
    PERSONALIZE_TAGS_JOB_SEEKER
} from "../../helpers/constant/personalizeTags";


const AddOrEditTemplates = ({onClose, templateInfo = {}, isEdit=false}) => {
    const dispatch = useDispatch();
    const { isAdding, isAddSuccess, isUpdating, isUpdateSuccess } = useSelector(selectMessageTemplateState);

    const {
        control,
        handleSubmit,
        setValue,
    } = useForm({
        mode: "onSubmit",
    });

    useEffect(() => {
        if (templateInfo?.id) {
            setValue("name", templateInfo.name);
            setValue("subject", templateInfo.subject);
            setValue("body", templateInfo.body);
        }
    }, [setValue, templateInfo]);

    useEffect(() => {
        if (isAddSuccess || isUpdateSuccess) {
            dispatch(resetCreateMessageTemplate());
            dispatch(resetEditMessageTemplate());
            onClose();
        }
    }, [dispatch, isAddSuccess, isUpdateSuccess, onClose]);

    const onSubmit = (data) => {
        if (isEdit) {
            data.id = templateInfo.id;
            dispatch(editMessageTemplate(data));
        } else {
            dispatch(createMessageTemplate(data));
        }
    };

    const isLoading = !!(isAdding || isUpdating);

    return (
        <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Controller
                        control={control}
                        name='name'
                        defaultValue=''
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                            <FormControl fullWidth required error={!!(invalid && error.message)}>
                                <FormLabel>Template Name</FormLabel>
                                <TextField
                                    autoFocus
                                    type='text'
                                    size={"small"}
                                    placeholder='Enter a name'
                                    value={value}
                                    onChange={onChange}
                                    error={!!(invalid && error.message)}
                                    helperText={invalid && error.message}
                                />
                            </FormControl>
                        )}
                        rules={messageTemplatePattern.name}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Controller
                        control={control}
                        name='subject'
                        defaultValue=''
                        render={({ field: { value, onChange }}) => (
                            <FormControl fullWidth>
                                <FormLabel>Subject</FormLabel>
                                <TextField
                                    type='text'
                                    size={"small"}
                                    placeholder='Enter a subject'
                                    value={value}
                                    onChange={onChange}
                                />
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Controller
                        control={control}
                        name='body'
                        defaultValue=''
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                            <FormControl fullWidth required error={!!(invalid && error.message)}>
                                <FormLabel>Message</FormLabel>
                                <CustomEditorWithPersonalizeTag
                                    theme={"snow"}
                                    placeholder={"Write your message"}
                                    personalizeTags={[...PERSONALIZE_TAGS_COMPANY, ...PERSONALIZE_TAGS_EMPLOYER, ...PERSONALIZE_TAGS_JOB_SEEKER]}
                                    value={value}
                                    onChange={(value) => {
                                        onChange(value);
                                    }}
                                />
                                {invalid && error.message && <FormHelperText>{error.message}</FormHelperText>}
                            </FormControl>
                        )}
                        rules={messageTemplatePattern.body}
                    />
                </Grid>
            </Grid>


            <Stack direction={"row"} justifyContent={"flex-end"} spacing={1} mt={2}>
                <Button variant={'text'} size={'small'} onClick={onClose}>
                    Cancel
                </Button>
                <Button variant={"contained"} type={"submit"} size={"small"} disabled={isLoading}>
                    {isEdit ? "Update template" : "Create template"}
                </Button>
            </Stack>
        </Box>
    );
};

export default AddOrEditTemplates;