import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Box, Button, FormControl, FormHelperText, FormLabel, Grid, Paper, Stack, TextField } from "@mui/material";
import CustomEditor from "../../common/Editor/CustomEditor";
import PersonalizedTag from "../../common/Personalized/PersonalizedTag";
import { insertPersonalizedTag } from "../../helpers/utils/insertPersonalizedTag";
import { employerTemplatePattern } from "../../helpers/constant/registerPattern";
import { EMPLOYER_TEMPLATE_TYPE_LIST } from "../../helpers/constant/employerTemplateConstant";
import { selectEmployerTemplateState } from "../../state/employerTemplate/employerTemplateSelector";
import {
    createEmployerTemplate,
    editEmployerTemplate,
    resetCreateEmployerTemplate,
    resetEditEmployerTemplate
} from "../../state/employerTemplate/employerTemplateSlice";

const AddOrEditTemplate = ({ onClose, templateInfo, isEdit = false }) => {
    const dispatch = useDispatch();
    const messageBodyRef = useRef();
    const subjectRef = useRef();

    const { isAdding, isAddSuccess, isUpdating, isUpdateSuccess } = useSelector(selectEmployerTemplateState);
    const {
        control,
        handleSubmit,
        setValue,
    } = useForm({
        mode: "onSubmit",
    });

    useEffect(() => {
        if (templateInfo?.id) {
            setValue("name", templateInfo.name);
            setValue("subject", templateInfo.subject);
            setValue("body", templateInfo.body);
            setValue("type", EMPLOYER_TEMPLATE_TYPE_LIST[templateInfo.type])
        }
    }, [templateInfo, setValue]);

    useEffect(() => {
        if (isAddSuccess || isUpdateSuccess) {
            dispatch(resetCreateEmployerTemplate());
            dispatch(resetEditEmployerTemplate());
            onClose();
        }
    }, [dispatch, isAddSuccess, isUpdateSuccess, onClose]);


    const onSubmit = (data) => {
        data.type = data.type.value;

        console.log("data::: ===> ", data)
        if (isEdit) {
            data.id = templateInfo.id;
            dispatch(editEmployerTemplate(data));
        } else {
            dispatch(createEmployerTemplate(data));
        }
    };

    const isLoading = !!(isAdding || isUpdating);
    const typeOptions = Object.values(EMPLOYER_TEMPLATE_TYPE_LIST)
    return (
        <Paper elevation={0}>
            <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)} mt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            control={control}
                            name='name'
                            defaultValue=''
                            rules={employerTemplatePattern.name}
                            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                                <FormControl fullWidth required error={!!(invalid && error.message)}>
                                    <FormLabel>Name</FormLabel>
                                    <TextField
                                        size={'small'}
                                        autoFocus
                                        type='text'
                                        placeholder='Enter a name'
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                        helperText={invalid && error.message}
                                    />
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            control={control}
                            name='subject'
                            defaultValue=''
                            rules={employerTemplatePattern.subject}
                            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                                <FormControl fullWidth required error={!!(invalid && error.message)}>
                                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mb={1}>
                                        <FormLabel>Subject</FormLabel>
                                        <PersonalizedTag
                                            onClick={(personalizedTag) =>
                                                insertPersonalizedTag(personalizedTag, subjectRef, (value) =>
                                                    onChange(value)
                                                )
                                            }
                                        />
                                    </Stack>
                                    <TextField
                                        inputRef={subjectRef}
                                        size={'small'}
                                        autoFocus
                                        type='text'
                                        placeholder='Enter a subject'
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                        helperText={invalid && error.message}
                                    />
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            control={control}
                            name='body'
                            defaultValue=''
                            rules={employerTemplatePattern.body}
                            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                                <FormControl fullWidth required error={!!(invalid && error.message)}>
                                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mb={1}>
                                        <FormLabel>Content</FormLabel>
                                        <PersonalizedTag
                                            onClick={(personalizedTag) =>
                                                insertPersonalizedTag(personalizedTag, messageBodyRef, (value) =>
                                                    onChange(value)
                                                )
                                            }
                                        />
                                    </Stack>
                                    <CustomEditor
                                        forwardedRef={messageBodyRef}
                                        theme={"snow"}
                                        placeholder={"Write your content"}
                                        value={value}
                                        onChange={(value) => {
                                            onChange(value);
                                        }}
                                    />
                                    <FormHelperText error={invalid}>{invalid && error.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            control={control}
                            name='type'
                            defaultValue=''
                            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                                <FormControl fullWidth required error={!!(invalid && error.message)}>
                                    <FormLabel>Category</FormLabel>
                                    <Select
                                        className={"select-dropdown-menu select-dropdown-menu-custom"}
                                        classNamePrefix={"select-dropdown-styles"}
                                        isMulti={false}
                                        isSearchable={false}
                                        menuPlacement='bottom'
                                        options={typeOptions}
                                        value={value}
                                        onChange={onChange}
                                    />
                                    <FormHelperText error={invalid}>{invalid && error.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={employerTemplatePattern.type}
                        />
                    </Grid>
                    <Box sx={{ marginLeft: "auto", mt: 2 }}>
                        <Button
                            type={"submit"}
                            size={'medium'}
                            variant={'contained'}
                            disabled={isLoading}
                        >
                            {isLoading ? "Saving..." : "Save"}
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </Paper>
    );
};

export default AddOrEditTemplate;