import httpRequestAdmin from "./httpRequest/httpRequestAdmin";

class BlogsService {
    getBlogList(params) {
        return httpRequestAdmin.get(process.env.REACT_APP_BACKEND_API_URL + "admin/blog/list", params);
    }
    createBlog(params) {
        return httpRequestAdmin.post(process.env.REACT_APP_BACKEND_API_URL + "admin/blog/add", params);
    }
    editBlog(params) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/blog/update", params);
    }

    updateBlogStatus(body) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/blog/update/status", body);
    }
}

export default new BlogsService();
