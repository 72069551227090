import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    page: 1,
    perPage: 10,
    count: 0,
    isLoading: false,
    isError: false,
    error: "",

    isUpdating: false,
    filters: {
        publishStatus: ""
    }
};

const blogsSlice = createSlice({
    name: "blogs",
    initialState: initialState,
    reducers: {
        getBlogList: (state) => {
            state.isLoading = true;
        },
        getBlogListSuccess: (state, action) => {
            state.data = action.payload.rows;
            state.page = +action.payload.page;
            state.perPage = +action.payload.perPage;
            state.count = +action.payload.count;
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        },
        getBlogListFailed: (state, action) => {
            state.data = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        createBlog: (state) => {
            state.isAdding = true;
        },
        createBlogSuccess: (state, action) => {
            console.log("action.payload:: ", action.payload);
            state.isAdding = false;
            state.isAddSuccess = true;
            state.data.unshift(action.payload);
            state.count += 1;
        },
        resetCreateBlog: (state) => {
            state.isAdding = false;
            state.isAddSuccess = false;
        },

        editBlog: (state) => {
            state.isUpdating = true;
        },
        editBlogSuccess: (state, action) => {
            state.isUpdating = false;
            state.isUpdateSuccess = true;
            state.data = state.data.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload };
                }
                return item;
            });
        },
        resetEditBlog: (state) => {
            state.isUpdating = false;
            state.isUpdateSuccess = false;
        },

        updateBlogStatus: (state) => {
            state.isUpdating = true;
        },
        updateBlogStatusSuccess: (state, action) => {
            state.data = state.data.map(item => {
                if(item.id === action.payload.id) {
                    return {...item, status: action.payload.status}
                }
                return item;
            });
            state.isUpdating = false;
        },
        updateBlogStatusFailed: (state) => {
            state.isUpdating = false;
        },

        addFilterPublishStatus: (state, action) => {
            state.filters.publishStatus = action.payload
        }

    },
});

export const {
    getBlogList,
    getBlogListSuccess,
    getBlogListFailed,

    createBlog,
    createBlogSuccess,
    resetCreateBlog,

    editBlog,
    editBlogSuccess,
    resetEditBlog,

    updateBlogStatus,
    updateBlogStatusSuccess,
    updateBlogStatusFailed,

    addFilterPublishStatus
} = blogsSlice.actions;

export default blogsSlice.reducer;
