import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, styled, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: 0,
    border: `1px solid ${theme.palette.divider}`,
    "&.Mui-expanded": { margin: 0 },
    "&:before": { content: "none" },
    "& .MuiAccordionSummary-root": {
        minHeight: "35px",
        "&.Mui-expanded": { minHeight: "35px", margin: 0 },
    },
    "& .MuiAccordionSummary-content": {
        margin: "5px 0",
        "&.Mui-expanded": {
            margin: "5px 0",
        },
    },
}));

export default function TriggerPersonalizedAccordion({ children, expanded, title }) {
    const [isExpanded, setIsExpanded] = useState(Boolean(expanded));

    return (
        <StyledAccordion elevation={0} expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='body2medium' mr={2.5}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
        </StyledAccordion>
    );
}
