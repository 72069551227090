import {all} from "redux-saga/effects";
import adminAuthSaga from "./adminAuth/adminAuthSaga";
import jobSeekerSaga from "./jobSeeker/jobSeekerSaga";
import companyListSaga from "./companyList/companyListSaga";
import messageTemplateSaga from "./messageTemplate/messageTemplateSaga";
import employerTemplateSaga from "./employerTemplate/employerTemplateSaga";
import jobPostListSaga from "./jobPostList/jobPostListSaga";
import blogsSaga from "./blogs/blogsSaga";
import assQuestionSaga from "./assQuestion/assQuestionSaga";
import questionCategorySaga from "./questionCategory/questionCategorySaga";
import tempAssCategorySaga from "./tempAssCategory/tempAssCategorySaga";
import templateAssessmentSaga from "./templateAssessment/templateAssessmentSaga";


export default function* rootSaga() {
    yield all([
        adminAuthSaga(),
        jobSeekerSaga(),
        companyListSaga(),
        messageTemplateSaga(),
        employerTemplateSaga(),
        jobPostListSaga(),
        blogsSaga(),
        assQuestionSaga(),
        questionCategorySaga(),
        tempAssCategorySaga(),
        templateAssessmentSaga(),
    ]);
}
