import React from "react";
import {useDispatch} from "react-redux";
import {MoreVert} from "@mui/icons-material";
import {IconButton, Menu, MenuItem} from "@mui/material";
import useBoolean from "../../../hooks/useBoolean";
import CloseModal from "../../../global/Modal/CloseModal";
import AddOrEditSubCategory from "./AddOrEditSubCategory";
import BasicModalWithHeader from "../../../global/Modal/BasicModalWithHeader";
import WarningModalContent from "../../../global/Modal/ModalContent/WarningModalContent";
import {deleteTempAssSubCategory} from "../../../state/tempAssCategory/tempAssCategorySlice";

const ITEM_HEIGHT = 48;

const TempAssSubCategoryActions = ({categoryId, subCategory}) => {
    const dispatch = useDispatch();

    const {value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal} = useBoolean(false);
    const {
        value: openAddSubCategoryModal, setTrue: onOpenAddSubCategoryModal, setFalse: onCloseAddSubCategoryModal,
    } = useBoolean(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleDeleteConfirm = () => {
        dispatch(deleteTempAssSubCategory({id: subCategory.value, categoryId: categoryId}))
        onOpenDeleteModal();
    };

    const open = Boolean(anchorEl);
    return (
        <>
            <IconButton onClick={handleMenuClick} size={'small'}>
                <MoreVert fontSize={"small"} />
            </IconButton>
            <Menu
                id='long-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "25ch",
                    },
                }}
            >
                <MenuItem onClick={() => {
                    onOpenAddSubCategoryModal();
                    handleMenuClose();
                }}>
                    Edit Sub Category
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onOpenDeleteModal();
                        handleMenuClose();
                    }}
                >
                    Delete
                </MenuItem>
            </Menu>
            <CloseModal open={openDeleteModal} onClose={onCloseDeleteModal} width={550}>
                <WarningModalContent
                    title={"Warning"}
                    subTitle={"Are you sure to delete this template Sub category"}
                    description={"If you delete this template Sub category, you’ll not be able to recover it again."}
                    buttonName={"Okay, Delete"}
                    onConfirm={handleDeleteConfirm}
                    onClose={onCloseDeleteModal}
                />
            </CloseModal>
            <BasicModalWithHeader
                title={"Edit Sub Category"}
                open={openAddSubCategoryModal}
                onClose={onCloseAddSubCategoryModal}
                width={500}
                autoScroll={true}
            >
                <AddOrEditSubCategory onClose={onCloseAddSubCategoryModal} categoryId={categoryId} subCategoryInfo={subCategory} isEdit={true} />
            </BasicModalWithHeader>
        </>
    );
};

export default TempAssSubCategoryActions;