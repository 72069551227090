import httpRequestAdmin from "./httpRequest/httpRequestAdmin";

class JobPostListService {
    getJobPostList(params) {
        return httpRequestAdmin.get(process.env.REACT_APP_BACKEND_API_URL + "admin/job/post/list", params);
    }
    updateJobPostPublishStatus(body) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/job/post/update/publish/status", body);
    }
}

export default new JobPostListService();
