import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAssQuestionList} from "../../../state/assQuestion/assQuestionSlice";
import {selectAssQuestionState} from "../../../state/assQuestion/assQuestionSelector";

const HOCQuestions =
    (Component) =>
        // eslint-disable-next-line react/display-name
        ({...rest}) => {
            const dispatch = useDispatch();
            const {data = [], isLoading, page, perPage, count, filters} = useSelector(selectAssQuestionState);
            const {subCategoryId, questionType, difficulty} = filters || {};

            useEffect(() => {
                dispatch(getAssQuestionList({page: 1, perPage, subCategoryId, questionType, difficulty}));
            }, [dispatch, subCategoryId, questionType, difficulty]);

            const onChangePerPage = (perPage, newPage) => {
                dispatch(getAssQuestionList({page: newPage, perPage: perPage, subCategoryId, questionType, difficulty}));
            };

            const onChangePage = (newPage) => {
                dispatch(getAssQuestionList({page: newPage, perPage: perPage, subCategoryId, questionType, difficulty}));
            };

            return (
                <Component
                    {...rest}
                    data={data}
                    page={page}
                    perPage={perPage}
                    count={count}
                    isLoading={isLoading}
                    onChangePage={onChangePage}
                    onChangePerPage={onChangePerPage}
                />
            );
        };

export default HOCQuestions;
