import {all, call, put, takeLatest, debounce} from "redux-saga/effects";
import AssQuestionsService from "../../services/assQuestion.service";
import {
    createAssQuestionSuccess,
    deleteAssQuestionSuccess,
    editAssQuestionSuccess,
    getAssQuestionListFailed,
    getAssQuestionListSuccess,
    resetCreateAssQuestion,
    resetDeleteAssQuestion,
    resetEditAssQuestion, updateAssQuestionStatusFailed, updateAssQuestionStatusSuccess
} from "./assQuestionSlice";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utils/toaster";


function* assQuestionWatcher() {
    yield debounce(1000, "assQuestion/getAssQuestionList", getAssQuestionListSaga);
    yield takeLatest("assQuestion/createAssQuestion", createAssQuestionSaga);
    yield takeLatest("assQuestion/editAssQuestion", editAssQuestionSaga);
    yield takeLatest("assQuestion/updateAssQuestionStatus", updateAssQuestionStatusSaga);
    yield takeLatest("assQuestion/deleteAssQuestion", deleteAssQuestionSaga);
}

function* getAssQuestionListSaga(action) {
    try {
        const response = yield call(AssQuestionsService.getAssQuestionList, action.payload);

        if (response.status) {
            yield put(getAssQuestionListSuccess(response.data));
        } else {
            yield put(getAssQuestionListFailed(response.message));
        }
    } catch (err) {
        yield put(getAssQuestionListFailed(err.message));
    }
}

function* createAssQuestionSaga(action) {
    try {
        const response = yield call(AssQuestionsService.createAssQuestion, action.payload);

        if (response.status) {
            yield put(createAssQuestionSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(resetCreateAssQuestion(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* editAssQuestionSaga(action) {
    try {
        const response = yield call(AssQuestionsService.editAssQuestion, action.payload);

        if (response.status) {
            yield put(editAssQuestionSuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(resetEditAssQuestion(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* updateAssQuestionStatusSaga(action) {
    try {
        const response = yield call(AssQuestionsService.updateAssQuestionStatus, action.payload);

        if (response.status) {
            yield put(updateAssQuestionStatusSuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(updateAssQuestionStatusFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* deleteAssQuestionSaga(action) {
    try {
        const response = yield call(AssQuestionsService.deleteAssQuestion, action.payload);

        if (response.status) {
            yield put(deleteAssQuestionSuccess(action.payload));
            showSuccessToaster(response.message);
        } else {
            yield put(resetDeleteAssQuestion(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* assQuestionSaga() {
    yield all([assQuestionWatcher()]);
}
