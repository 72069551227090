import React from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {WarningAmber} from "@mui/icons-material";

const WarningModalContent = ({
                                 title = "Warning",
                                 subTitle = "",
                                 description,
                                 buttonName = "Delete",
                                 onConfirm,
                                 onClose,
                             }) => {
    return (
        <Box>
            <WarningAmber sx={{fontSize: "72px"}} color={"warning"}/>
            <Typography variant={"h3"} color={"text.primary"} sx={{pt: 1}}>
                {title}
            </Typography>
            <Typography variant={"body1"} color={"text.primary"} fontWeight={500} sx={{pt: 1, pb: 1}}>
                {subTitle}
            </Typography>
            <Typography variant={"body1"} sx={{lineHeight: '24px'}}>{description}</Typography>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={2} sx={{mt: 3}}>
                <Button variant='text' onClick={onClose} size={"small"}>
                    Cancel
                </Button>
                <Button size='small' variant='contained' onClick={onConfirm}>
                    {buttonName}
                </Button>
            </Stack>
        </Box>
    );
};

export default WarningModalContent;