import {all, call, put, takeLatest} from "redux-saga/effects";
import TempAssCategoryService from "../../services/tempAssCategory.service";
import {
    createTempAssCategorySuccess,
    createTempAssSubCategorySuccess,
    deleteTempAssCategorySuccess,
    deleteTempAssSubCategorySuccess,
    editTempAssCategorySuccess,
    editTempAssSubCategorySuccess,
    getTempAssCategoryListFailed,
    getTempAssCategoryListSuccess,
    resetCreateTempAssCategory,
    resetCreateTempAssSubCategory,
    resetDeleteTempAssCategory,
    resetDeleteTempAssSubCategory,
    resetEditTempAssCategory,
    resetEditTempAssSubCategory,
    updateTempAssCategoryStatusFailed,
    updateTempAssCategoryStatusSuccess
} from "./tempAssCategorySlice";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utils/toaster";

function* tempAssCategoryWatcher() {
    yield takeLatest("tempAssCategory/getTempAssCategoryList", getTempAssCategoryListSaga);
    yield takeLatest("tempAssCategory/createTempAssCategory", createTempAssCategorySaga);
    yield takeLatest("tempAssCategory/editTempAssCategory", editTempAssCategorySaga);
    yield takeLatest("tempAssCategory/updateTempAssCategoryStatus", updateTempAssCategoryStatusSaga);
    yield takeLatest("tempAssCategory/deleteTempAssCategory", deleteTempAssCategorySaga);

    yield takeLatest("tempAssCategory/createTempAssSubCategory", createTempAssSubCategorySaga);
    yield takeLatest("tempAssCategory/editTempAssSubCategory", editTempAssSubCategorySaga);
    yield takeLatest("tempAssCategory/deleteTempAssSubCategory", deleteTempAssSubCategorySaga);
}

function* getTempAssCategoryListSaga(action) {
    try {
        const response = yield call(TempAssCategoryService.getTempAssCategoryList, action.payload);

        if (response.status) {
            yield put(getTempAssCategoryListSuccess(response.data));
        } else {
            yield put(getTempAssCategoryListFailed(response.message));
        }
    } catch (err) {
        yield put(getTempAssCategoryListFailed(err.message));
    }
}

function* createTempAssCategorySaga(action) {
    try {
        const response = yield call(TempAssCategoryService.createTempAssCategory, action.payload);

        if (response.status) {
            yield put(createTempAssCategorySuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(resetCreateTempAssCategory(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* editTempAssCategorySaga(action) {
    try {
        const response = yield call(TempAssCategoryService.editTempAssCategory, action.payload);

        if (response.status) {
            yield put(editTempAssCategorySuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(resetEditTempAssCategory(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* updateTempAssCategoryStatusSaga(action) {
    try {
        const response = yield call(TempAssCategoryService.updateTempAssCategoryStatus, action.payload);

        if (response.status) {
            yield put(updateTempAssCategoryStatusSuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(updateTempAssCategoryStatusFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* deleteTempAssCategorySaga(action) {
    try {
        const response = yield call(TempAssCategoryService.deleteTempAssCategory, action.payload);

        if (response.status) {
            yield put(deleteTempAssCategorySuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(resetDeleteTempAssCategory(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* createTempAssSubCategorySaga(action) {
    try {
        const response = yield call(TempAssCategoryService.createTempAssSubCategory, action.payload);

        if (response.status) {
            yield put(createTempAssSubCategorySuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(resetCreateTempAssSubCategory(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* editTempAssSubCategorySaga(action) {
    try {
        const response = yield call(TempAssCategoryService.editTempAssSubCategory, action.payload);

        if (response.status) {
            yield put(editTempAssSubCategorySuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(resetEditTempAssSubCategory(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* deleteTempAssSubCategorySaga(action) {
    try {
        const response = yield call(TempAssCategoryService.deleteTempAssSubCategory, action.payload);

        if (response.status) {
            yield put(deleteTempAssSubCategorySuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(resetDeleteTempAssSubCategory(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}



export default function* tempAssCategorySaga() {
    yield all([tempAssCategoryWatcher()]);
}
