import React from 'react';
import {Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import TemplatesMenuAction from "./TemplatesMenuAction";
import HOCTemplateForEmployer from "./HOCTemplateForEmployer";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import GlobalTablePagination from "../../global/Pagination/GlobalTablePagination";
import TableLoaderSkeleton from "../../global/Loader/TableLoaderSkeleton";
import {EMPLOYER_TEMPLATE_STATUS, EMPLOYER_TEMPLATE_TYPE_LIST} from "../../helpers/constant/employerTemplateConstant";
import EmptyBoxLogo from "../../assets/images/inbox-empty.png";

const headCells = [
    {
        id: "Name",
        label: "Name",
    },
    {
        id: "Category",
        label: "Category",
    },
    {
        id: "Status",
        label: "Status",
    },
    {
        id: "action",
        label: "",
    },
];

const TableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align='left' padding={"normal"}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const TemplatesTable = ({
                            onChangePage,
                            onChangePerPage,
                            isLoading = false,
                            data: templateList = [],
                            page = 1,
                            perPage = 10,
                            count = 0,
                        }) => {
    // decide what to render
    let content = null;
    if (isLoading) {
        content = <TableLoaderSkeleton columns={4} numberOfRows={10}/>;
    } else if (templateList?.length === 0) {
        content = <GlobalEmptyPage image={EmptyBoxLogo} title={"No message template found!"}/>;
    } else if (templateList?.length > 0) {
        content = (
            <>
                <TableContainer>
                    <Table sx={{minWidth: 750}} size={'small'}>
                        <TableHeader/>
                        <TableBody>
                            {templateList.map(template => {
                                return (
                                    <TableRow key={template.id}>
                                        <TableCell align='left' style={{width: '24rem'}}>
                                            {template.name}
                                        </TableCell>
                                        <TableCell align='left' style={{width: '24rem'}}>
                                            {EMPLOYER_TEMPLATE_TYPE_LIST[template.type]?.label}
                                        </TableCell>
                                        <TableCell align={'left'}>
                                            {template.status === EMPLOYER_TEMPLATE_STATUS.PUBLISH ? (
                                                <Chip label={'Published'} color={'success'} variant={'filled'}/>
                                            ): (
                                                <Chip label={'Unpublished'} color={'error'} variant={'filled'}/>
                                            )}

                                        </TableCell>
                                        <TableCell align='right'>
                                            <TemplatesMenuAction template={template}/>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <GlobalTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={perPage}
                    onChangePage={onChangePage}
                    onChangePerPage={onChangePerPage}
                />
            </>
        )
    }

    return content;
};

export default HOCTemplateForEmployer(TemplatesTable);