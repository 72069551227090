import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    error: "",

    isAdding: false,
    isAddSuccess: false,

    isUpdating: false,
    isUpdateSuccess: false,

    isDeleting: false,
    isDeleteSuccess: false,
};

const tempAssCategorySlice = createSlice({
    name: "tempAssCategory",
    initialState: initialState,
    reducers: {
        getTempAssCategoryList: (state) => {
            state.isLoading = true;
        },
        getTempAssCategoryListSuccess: (state, action) => {
            state.data = action.payload
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        },
        getTempAssCategoryListFailed: (state, action) => {
            state.data = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        createTempAssCategory: (state) => {
            state.isAdding = true;
        },
        createTempAssCategorySuccess: (state, action) => {
            state.isAdding = false;
            state.isAddSuccess = true;
            state.data.push({value: action.payload.id, label: action.payload.name});
        },
        resetCreateTempAssCategory: (state) => {
            state.isAdding = false;
            state.isAddSuccess = false;
        },

        editTempAssCategory: (state) => {
            state.isUpdating = true;
        },
        editTempAssCategorySuccess: (state, action) => {
            state.isUpdating = false;
            state.isUpdateSuccess = true;
            state.data = state.data.map((item) => {
                if (item.value === action.payload.id) {
                    return { ...item, label: action.payload.name };
                }
                return item;
            });
        },
        resetEditTempAssCategory: (state) => {
            state.isUpdating = false;
            state.isUpdateSuccess = false;
        },

        updateTempAssCategoryStatus: (state) => {
            state.isUpdating = true;
        },
        updateTempAssCategoryStatusSuccess: (state, action) => {
            state.data = state.data.map(item => {
                if(item.id === action.payload.id) {
                    return {...item, status: action.payload.status}
                }
                return item;
            });
            state.isUpdating = false;
        },
        updateTempAssCategoryStatusFailed: (state) => {
            state.isUpdating = false;
        },

        deleteTempAssCategory: (state) => {
            state.isDeleting = true;
        },
        deleteTempAssCategorySuccess: (state, action) => {
            state.isDeleting = false;
            state.isDeleteSuccess = true;
            state.data = state.data.filter((item) => item.value !== action.payload.id);
            state.count -= 1;
        },
        resetDeleteTempAssCategory: (state) => {
            state.isDeleting = false;
            state.isDeleteSuccess = false;
        },

        // sub category related actions
        createTempAssSubCategory: (state) => {
            state.isAdding = true;
        },
        createTempAssSubCategorySuccess: (state, action) => {
            state.isAdding = false;
            state.isAddSuccess = true;
            state.data = state.data.map(item => {
                if(item.value === action.payload.categoryId) {
                    item.subCategories = [...item.subCategories, {value: action.payload.id, label: action.payload.name}]
                }
                return item;
            });
        },
        resetCreateTempAssSubCategory: (state) => {
            state.isAdding = false;
            state.isAddSuccess = false;
        },

        editTempAssSubCategory: (state) => {
            state.isUpdating = true;
        },
        editTempAssSubCategorySuccess: (state, action) => {
            state.isUpdating = false;
            state.isUpdateSuccess = true;
            state.data = state.data.map((item) => {
                if (item.value === action.payload.categoryId) {
                    item.subCategories = item.subCategories.map(subCategoryItem => {
                        if(subCategoryItem.value === action.payload.id) {
                            return { ...item, label: action.payload.name };
                        } else {
                            return subCategoryItem;
                        }
                    })
                }
                return item;
            });
        },
        resetEditTempAssSubCategory: (state) => {
            state.isUpdating = false;
            state.isUpdateSuccess = false;
        },

        deleteTempAssSubCategory: (state) => {
            state.isDeleting = true;
        },
        deleteTempAssSubCategorySuccess: (state, action) => {
            state.isDeleting = false;
            state.isDeleteSuccess = true;
            state.data = state.data.map(item => {
                if(item.value === action.payload.categoryId) {
                    item.subCategories = item.subCategories.filter((subCategoryItem) => subCategoryItem.value !== action.payload.id)
                }
                return item;
            });
            state.count -= 1;
        },
        resetDeleteTempAssSubCategory: (state) => {
            state.isDeleting = false;
            state.isDeleteSuccess = false;
        },
    },
});

export const {
    getTempAssCategoryList,
    getTempAssCategoryListSuccess,
    getTempAssCategoryListFailed,

    createTempAssCategory,
    createTempAssCategorySuccess,
    resetCreateTempAssCategory,

    editTempAssCategory,
    editTempAssCategorySuccess,
    resetEditTempAssCategory,

    updateTempAssCategoryStatus,
    updateTempAssCategoryStatusSuccess,
    updateTempAssCategoryStatusFailed,

    deleteTempAssCategory,
    deleteTempAssCategorySuccess,
    resetDeleteTempAssCategory,

    createTempAssSubCategory,
    createTempAssSubCategorySuccess,
    resetCreateTempAssSubCategory,

    editTempAssSubCategory,
    editTempAssSubCategorySuccess,
    resetEditTempAssSubCategory,

    deleteTempAssSubCategory,
    deleteTempAssSubCategorySuccess,
    resetDeleteTempAssSubCategory,
} = tempAssCategorySlice.actions;

export default tempAssCategorySlice.reducer;
