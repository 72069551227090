import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    error: "",

    isAdding: false,
    isAddSuccess: false,

    isUpdating: false,
    isUpdateSuccess: false,

    isDeleting: false,
    isDeleteSuccess: false,
};

const questionCategorySlice = createSlice({
    name: "questionCategory",
    initialState: initialState,
    reducers: {
        getQuestionCategoryList: (state) => {
            state.isLoading = true;
        },
        getQuestionCategoryListSuccess: (state, action) => {
            state.data = action.payload
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        },
        getQuestionCategoryListFailed: (state, action) => {
            state.data = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        createQuestionCategory: (state) => {
            state.isAdding = true;
        },
        createQuestionCategorySuccess: (state, action) => {
            state.isAdding = false;
            state.isAddSuccess = true;
            state.data.push({value: action.payload.id, label: action.payload.name});
        },
        resetCreateQuestionCategory: (state) => {
            state.isAdding = false;
            state.isAddSuccess = false;
        },

        editQuestionCategory: (state) => {
            state.isUpdating = true;
        },
        editQuestionCategorySuccess: (state, action) => {
            state.isUpdating = false;
            state.isUpdateSuccess = true;
            state.data = state.data.map((item) => {
                if (item.value === action.payload.id) {
                    return { ...item, label: action.payload.name };
                }
                return item;
            });
        },
        resetEditQuestionCategory: (state) => {
            state.isUpdating = false;
            state.isUpdateSuccess = false;
        },

        updateQuestionCategoryStatus: (state) => {
            state.isUpdating = true;
        },
        updateQuestionCategoryStatusSuccess: (state, action) => {
            state.data = state.data.map(item => {
                if(item.id === action.payload.id) {
                    return {...item, status: action.payload.status}
                }
                return item;
            });
            state.isUpdating = false;
        },
        updateQuestionCategoryStatusFailed: (state) => {
            state.isUpdating = false;
        },

        deleteQuestionCategory: (state) => {
            state.isDeleting = true;
        },
        deleteQuestionCategorySuccess: (state, action) => {
            state.isDeleting = false;
            state.isDeleteSuccess = true;
            state.data = state.data.filter((item) => item.value !== action.payload.id);
            state.count -= 1;
        },
        resetDeleteQuestionCategory: (state) => {
            state.isDeleting = false;
            state.isDeleteSuccess = false;
        },

        // sub category related actions
        createQuestionSubCategory: (state) => {
            state.isAdding = true;
        },
        createQuestionSubCategorySuccess: (state, action) => {
            state.isAdding = false;
            state.isAddSuccess = true;
            state.data = state.data.map(item => {
                if(item.value === action.payload.categoryId) {
                    item.subCategories = [...item.subCategories, {value: action.payload.id, label: action.payload.name}]
                }
                return item;
            });
        },
        resetCreateQuestionSubCategory: (state) => {
            state.isAdding = false;
            state.isAddSuccess = false;
        },

        editQuestionSubCategory: (state) => {
            state.isUpdating = true;
        },
        editQuestionSubCategorySuccess: (state, action) => {
            state.isUpdating = false;
            state.isUpdateSuccess = true;
            state.data = state.data.map((item) => {
                if (item.value === action.payload.categoryId) {
                    item.subCategories = item.subCategories.map(subCategoryItem => {
                        if(subCategoryItem.value === action.payload.id) {
                            return { ...item, label: action.payload.name };
                        } else {
                            return subCategoryItem;
                        }
                    })
                }
                return item;
            });
        },
        resetEditQuestionSubCategory: (state) => {
            state.isUpdating = false;
            state.isUpdateSuccess = false;
        },

        deleteQuestionSubCategory: (state) => {
            state.isDeleting = true;
        },
        deleteQuestionSubCategorySuccess: (state, action) => {
            state.isDeleting = false;
            state.isDeleteSuccess = true;
            state.data = state.data.map(item => {
                if(item.value === action.payload.categoryId) {
                    item.subCategories = item.subCategories.filter((subCategoryItem) => subCategoryItem.value !== action.payload.id)
                }
                return item;
            });
            state.count -= 1;
        },
        resetDeleteQuestionSubCategory: (state) => {
            state.isDeleting = false;
            state.isDeleteSuccess = false;
        },
    },
});

export const {
    getQuestionCategoryList,
    getQuestionCategoryListSuccess,
    getQuestionCategoryListFailed,

    createQuestionCategory,
    createQuestionCategorySuccess,
    resetCreateQuestionCategory,

    editQuestionCategory,
    editQuestionCategorySuccess,
    resetEditQuestionCategory,

    updateQuestionCategoryStatus,
    updateQuestionCategoryStatusSuccess,
    updateQuestionCategoryStatusFailed,

    deleteQuestionCategory,
    deleteQuestionCategorySuccess,
    resetDeleteQuestionCategory,

    createQuestionSubCategory,
    createQuestionSubCategorySuccess,
    resetCreateQuestionSubCategory,

    editQuestionSubCategory,
    editQuestionSubCategorySuccess,
    resetEditQuestionSubCategory,

    deleteQuestionSubCategory,
    deleteQuestionSubCategorySuccess,
    resetDeleteQuestionSubCategory,
} = questionCategorySlice.actions;

export default questionCategorySlice.reducer;
