import httpRequestAdmin from "./httpRequest/httpRequestAdmin";

class QuestionCategoryService {
    getQuestionCategoryList(params) {
        return httpRequestAdmin.get(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/question/category/all", params);
    }
    createQuestionCategory(params) {
        return httpRequestAdmin.post(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/question/category/create", params);
    }
    editQuestionCategory(params) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/question/category/update", params);
    }
    updateQuestionCategoryStatus(body) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/question/category/update/status", body);
    }
    deleteQuestionCategory(params) {
        return httpRequestAdmin.delete(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/question/category/delete", params);
    }

    createQuestionSubCategory(params) {
        return httpRequestAdmin.post(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/question/sub-category/create", params);
    }
    editQuestionSubCategory(params) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/question/sub-category/update", params);
    }
    deleteQuestionSubCategory(params) {
        return httpRequestAdmin.delete(process.env.REACT_APP_BACKEND_API_URL + "admin/assessment/question/sub-category/delete", params);
    }
}

export default new QuestionCategoryService();
