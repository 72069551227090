import {ListItemButton, styled} from "@mui/material";

export const ListItemButtonStyled = styled(ListItemButton)(({theme}) => ({
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    padding: 1.5,
    borderRadius: 6,
    "& .MuiListItemIcon-root": {
        minWidth: theme.spacing(4),
        padding: 2,
        borderRadius: 4
    },
}));