import {all, call, put, takeLatest} from "redux-saga/effects";
import JobSeekerService from "../../services/jobSeeker.service";
import {
    getJobSeekersDataFailed,
    getJobSeekersDataSuccess,
    updateJobSeekerStatusFailed,
    updateJobSeekerStatusSuccess
} from "./jobSeekerSlice";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utils/toaster";


function* jobSeekerWatcher() {
    yield takeLatest("jobSeeker/getJobSeekersData", getJobSeekersDataSaga);
    yield takeLatest("jobSeeker/updateJobSeekerStatus", updateJobSeekerStatusSaga);
}

function* getJobSeekersDataSaga(action) {
    try {
        const response = yield call(JobSeekerService.getJobSeekersData, action.payload);

        if (response.status) {
            yield put(getJobSeekersDataSuccess(response.data));
        } else {
            yield put(getJobSeekersDataFailed(response.message));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* updateJobSeekerStatusSaga(action) {
    try {
        const response = yield call(JobSeekerService.updateJobSeekerStatus, action.payload);

        if (response.status) {
            yield put(updateJobSeekerStatusSuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(updateJobSeekerStatusFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* jobSeekerSaga() {
    yield all([jobSeekerWatcher()]);
}
