import React from 'react';
import {format} from "date-fns";
import {TableCell, TableRow} from "@mui/material";
import useBoolean from "../../../hooks/useBoolean";
import TemplateAssessmentActions from "./TemplateAssessmentActions";
import AddOrEditTemplateAssessment from "./AddOrEditTemplateAssessment";
import BasicModalWithHeader from "../../../global/Modal/BasicModalWithHeader";
import {useSelector} from "react-redux";
import {selectQuestionAllSubCategory} from "../../../state/questionCategory/questionCategorySelector";

const SingleTemplateAssessmentRaw = ({templateAssessmentInfo}) => {
    const {id, name, skills, createdAt, updatedAt} = templateAssessmentInfo || {};
    const {data: allSkills = []} = useSelector(selectQuestionAllSubCategory);

    const createdDate = createdAt ? format(new Date(createdAt), "MMM dd, yyyy") : "-";
    const {value: openModal, setTrue: onOpenModal, setFalse: onCloseModal} = useBoolean(false);
    const skillListView = allSkills.filter(skill => skills.includes(skill.value)).map(skill => skill.label).join(", ");

    return (
        <>
            <TableRow
                key={id}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
                <TableCell component="th" scope="row" onClick={onOpenModal}
                           sx={{cursor: "pointer"}}>
                    {name}
                </TableCell>
                <TableCell>{skillListView}</TableCell>
                <TableCell>{createdDate}</TableCell>
                <TableCell>
                    <TemplateAssessmentActions templateAssessmentInfo={templateAssessmentInfo}/>
                </TableCell>
            </TableRow>

            <BasicModalWithHeader
                title={"Edit Template"}
                open={openModal}
                onClose={onCloseModal}
                width={900}
                autoScroll={true}
            >
                <AddOrEditTemplateAssessment onClose={onCloseModal} templateAssessmentInfo={templateAssessmentInfo} isEdit={true}/>
            </BasicModalWithHeader>
        </>
    );
};

export default SingleTemplateAssessmentRaw;