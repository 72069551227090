import React from 'react';
import {format} from "date-fns";
import {TableCell, TableRow} from "@mui/material";
import QuestionsListActions from "./QuestionsActions";
import useBoolean from "../../../hooks/useBoolean";
import BasicModalWithHeader from "../../../global/Modal/BasicModalWithHeader";
import AddOrEditQuestion from "./AddOrEditQuestion";

const SingleQuestionRaw = ({questionInfo}) => {
    const {id, content, questionType, difficulty, createdAt, updatedAt} = questionInfo || {};
    const createdDate = createdAt ? format(new Date(createdAt), "MMM dd, yyyy") : "-";
    const updatedDate = updatedAt ? format(new Date(updatedAt), "MMM dd, yyyy") : "-";
    const {value: openModal, setTrue: onOpenModal, setFalse: onCloseModal} = useBoolean(false);

    return (
        <>
            <TableRow
                key={id}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
                <TableCell component="th" scope="row" onClick={onOpenModal}
                           sx={{cursor: "pointer"}}>
                    <div className={'multiline-text-truncate '}
                         dangerouslySetInnerHTML={{__html: content || '-'}}/>
                </TableCell>
                <TableCell>{difficulty}</TableCell>
                <TableCell>{questionType}</TableCell>
                <TableCell>{createdDate}</TableCell>
                <TableCell>{updatedDate}</TableCell>
                <TableCell>
                    <QuestionsListActions questionInfo={questionInfo}/>
                </TableCell>
            </TableRow>

            <BasicModalWithHeader
                title={"Edit Question"}
                open={openModal}
                onClose={onCloseModal}
                width={900}
                autoScroll={true}
            >
                <AddOrEditQuestion onClose={onCloseModal} questionInfo={questionInfo} isEdit={true}/>
            </BasicModalWithHeader>
        </>
    );
};

export default SingleQuestionRaw;