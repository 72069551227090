import React from "react";
import { useDispatch } from "react-redux";
import { IconButton, Stack } from "@mui/material";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {deleteMessageTemplate} from "../../state/messageTemplate/messageTemplateSlice";
import useBoolean from "../../hooks/useBoolean";
import CloseModal from "../../global/Modal/CloseModal";
import WarningModalContent from "../../global/Modal/ModalContent/WarningModalContent";
import AddOrEditTemplates from "./AddOrEditTemplates";
import BasicDrawer from "../../global/Drawer/BasicDrawer";

const MessageContentActions = ({ templateInfo }) => {
    const dispatch = useDispatch();
    const { value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);
    const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);

    const handleDeleteConfirm = () => {
        dispatch(deleteMessageTemplate({ id: templateInfo.id }));
        onCloseDeleteModal();
    };

    return (
        <>
            <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-end"}>
                <IconButton size={"small"} onClick={onOpenModal}>
                    <ModeEditOutlinedIcon fontSize={"medium"} />
                </IconButton>
                <IconButton size={"small"} onClick={onOpenDeleteModal}>
                    <DeleteOutlinedIcon fontSize={"medium"} />
                </IconButton>
            </Stack>
            <CloseModal open={openDeleteModal} onClose={onCloseDeleteModal} width={'30%'}>
                <WarningModalContent
                    title={"Warning"}
                    subTitle={"Are you sure to delete this template"}
                    description={"If you delete this template, you’ll not be able to recover it again."}
                    buttonName={"Okay, Delete"}
                    onConfirm={handleDeleteConfirm}
                    onClose={onCloseDeleteModal}
                />
            </CloseModal>
            <BasicDrawer title={"Edit Template"} open={openModal} onClose={onCloseModal}>
                <AddOrEditTemplates onClose={onCloseModal} templateInfo={templateInfo} isEdit={true} />
            </BasicDrawer>
        </>
    );
};

export default MessageContentActions;
