import React from "react";
import {Box, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";

const Wrapper = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(3),
    height: "100%",
    width: "100%",
    gap: theme.spacing(1),
}));

const GlobalEmptyPage = ({image, title, description}) => {
    return (
        <Wrapper>
            {image && (
                <img
                    src={image}
                    width="350px"
                    height="300px"
                    alt='Not Found'
                />
            )}
            <Typography variant='h5' mt={4} color={"secondary"}>
                {title}
            </Typography>
            <Typography variant='body1medium' color='text.secondary' sx={{maxWidth: "520px", textAlign: "center"}}>
                {description}
            </Typography>
        </Wrapper>
    );
};

export default GlobalEmptyPage;
