import httpRequestAdmin from "./httpRequest/httpRequestAdmin";

class EmployerTemplateService {
    getEmployerTemplates(params) {
        return httpRequestAdmin.get(process.env.REACT_APP_BACKEND_API_URL + "admin/message/template/list", params);
    }

    createEmployerTemplate(body) {
        return httpRequestAdmin.post(process.env.REACT_APP_BACKEND_API_URL + "admin/message/template/create", body);
    }

    editEmployerTemplate(body) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/message/template/update", body);
    }

    toggleEmployerTemplateStatus(body) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/message/template/update/status", body);
    }

    deleteEmployerTemplate(body) {
        return httpRequestAdmin.delete(process.env.REACT_APP_BACKEND_API_URL + "admin/message/template/delete", body);
    }
}

export default new EmployerTemplateService();
