import React from 'react';
import {Paper} from "@mui/material";
import TemplatesHeader from "./TemplatesHeader";
import TemplatesTable from "./TemplatesTable";

const TemplatesBody = () => {
    return (
        <Paper elevation={0}>
            <TemplatesHeader/>
            <TemplatesTable/>
        </Paper>
    );
};

export default TemplatesBody;