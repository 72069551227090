import {nanoid} from "@reduxjs/toolkit";

export const selectQuestionCategoryState = (state) => state.questionCategory;
export const selectQuestionAllSubCategory = (state) => {
    const categoryList = state.questionCategory.data || [];
    const subCategoryList = categoryList?.reduce((allSubCategories, category) => {
        const subCategoryList = category.subCategories || [];
        return [...allSubCategories, ...subCategoryList]
    }, []);

    return {
        data: subCategoryList,
        renderId: nanoid(5)
    };
};
