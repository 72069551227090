import React, {useEffect} from 'react';
import Select from "react-select";
import {useDispatch} from "react-redux";
import {Add} from "@mui/icons-material";
import {Button, FormControl, FormLabel, Stack} from "@mui/material";
import AddOrEditTemplate from "./AddOrEditTemplate";
import useBoolean from "../../hooks/useBoolean";
import PageTitle from "../../common/PageTitle";
import BasicDrawer from "../../global/Drawer/BasicDrawer";
import {EMPLOYER_TEMPLATE_TYPE_LIST} from "../../helpers/constant/employerTemplateConstant";
import {addEmployerTemplateType, resetEmployerTemplateFilter} from "../../state/employerTemplate/employerTemplateSlice";

const TemplatesHeader = () => {
    const dispatch = useDispatch();
    const {value: openDrawer, setTrue: onOpenDrawer, setFalse: onCloseDrawer} = useBoolean(false);

    const handleChangeType = (newValue) => {
        dispatch(addEmployerTemplateType(newValue.value))
    }

    useEffect(() => {
        return () => dispatch(resetEmployerTemplateFilter())
    }, []);

    let categoryOptions = Object.values(EMPLOYER_TEMPLATE_TYPE_LIST);
    categoryOptions = [{label: "All", value: ""}, ...categoryOptions];
    return (
        <>
            <Stack direction={'row'} alignItems={'end'} justifyContent={'space-between'} mb={3}>
                <PageTitle title={'Employer Templates'} subTitle={"Templates for employer. Any employer can use our template."} mb={0}/>
                <Stack direction={'row'} alignItems={'end'}  spacing={2}>
                    <FormControl sx={{width: 300}}>
                        <FormLabel>Filter By Category:</FormLabel>
                        <Select
                            className={"select-dropdown-menu select-dropdown-menu-custom"}
                            classNamePrefix={"select-dropdown-styles"}
                            isMulti={false}
                            isSearchable={false}
                            menuPlacement='bottom'
                            options={categoryOptions}
                            onChange={handleChangeType}
                        />
                    </FormControl>

                    <Button variant={'contained'} size={'small'} endIcon={<Add/>} onClick={onOpenDrawer}>
                        Add Template
                    </Button>
                </Stack>
            </Stack>

            <BasicDrawer title={'Add New Template'} open={openDrawer} onClose={onCloseDrawer}>
                <AddOrEditTemplate isEdit={false} onClose={onCloseDrawer}/>
            </BasicDrawer>
        </>
    );
};

export default TemplatesHeader;