import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectJobPostState} from "../../state/jobPostList/jobPostListSelector";
import {getJobPostList, updateJobPostPublishStatus} from "../../state/jobPostList/jobPostListSlice";

const HOCJobPostList =
    (Component) =>
        // eslint-disable-next-line react/display-name
    ({ ...rest }) => {
        const dispatch = useDispatch();
        const { data = [], isLoading, page, perPage, count, filters, updatedPublishStatusId, renderId } = useSelector(selectJobPostState);
        const { publishStatus, packageType } = filters || {};
        useEffect(() => {
            dispatch(getJobPostList({ page: page, perPage, publishStatus, packageType }));
        }, [dispatch, page, perPage, publishStatus, packageType, renderId]);

        const onChangePerPage = (perPage, newPage) => {
            dispatch(getJobPostList({ page: newPage, perPage: perPage, publishStatus, packageType }));
        };

        const onChangePage = (newPage) => {
            dispatch(getJobPostList({ page: newPage, perPage: perPage, publishStatus, packageType }));
        };

        const handleChangePublishStatus = (id, publishStatus) => {
            dispatch(updateJobPostPublishStatus({id, publishStatus}))
        }

        return (
            <Component
                {...rest}
                data={data}
                page={page}
                perPage={perPage}
                count={count}
                isLoading={isLoading}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
                updatedPublishStatusId={updatedPublishStatusId}
                handleChangePublishStatus={handleChangePublishStatus}
            />
        );
    };

export default HOCJobPostList;
