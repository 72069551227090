import React from 'react';
import {Add} from "@mui/icons-material";
import {Button, Stack} from "@mui/material";
import useBoolean from "../../hooks/useBoolean";
import PageTitle from "../../common/PageTitle";
import BasicModalWithHeader from "../../global/Modal/BasicModalWithHeader";
import AddOrEditTemplates from "./AddOrEditTemplates";

const MessageContentHeader = () => {
    const {value: openModal, setTrue: onOpenModal, setFalse: onCloseModal} = useBoolean(false);

    return (
        <>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={3}>
                <PageTitle title={'Message content Templates'}/>
                <Button onClick={onOpenModal} variant={'contained'} size={'medium'} endIcon={<Add/>}>
                    Add Template
                </Button>
            </Stack>

            <BasicModalWithHeader title={"Create new template"} open={openModal} onClose={onCloseModal} autoScroll={false}>
                <AddOrEditTemplates onClose={onCloseModal}/>
            </BasicModalWithHeader>
        </>
    );
};

export default MessageContentHeader;