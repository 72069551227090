import * as React from "react";
import {useState} from "react";
import Popover from "@mui/material/Popover";
import {Box, Button, styled} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    minWidth: "max-content",
    padding: "10px",
    height: "45px",
}));

const CustomPopOver = ({ buttonText, children, color = "secondary" }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <StyledButton
                size={"small"}
                variant={"contained"}
                color={color}
                aria-describedby={id}
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
            >
                {buttonText}
            </StyledButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                sx={{
                    "& .MuiPopover-paper": {
                        maxHeight: "245px",
                        height: "100%",
                        boxShadow:
                            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important",
                        ".MuiAccordionSummary-root": {
                            padding: "0px 5px",
                        },
                    },
                }}
            >
                <Box className={"ib_scroll_bar"}>
                    {children}
                </Box>
            </Popover>
        </>
    );
};
export default CustomPopOver;
