export const QUESTION_CATEGORY_LIST = [
    {label: "TECHNICAL", value: "TECHNICAL"},
    {label: "SALES", value: "SALES"},
    {label: "MARKETING", value: "MARKETING"},
    {label: "SUPPORT", value: "SUPPORT"},
    {label: "PRODUCT", value: "PRODUCT"},
    {label: "CUSTOMER SUCCESS", value: "CUSTOMER SUCCESS"},
    {label: "DESIGN", value: "DESIGN"},
    {label: "FINANCE", value: "FINANCE"},
    {label: "ADMINISTRATIVE", value: "ADMINISTRATIVE"},
    {label: "LANGUAGES", value: "LANGUAGES"},
    {label: "INTERNSHIPS", value: "INTERNSHIPS"},
]

export const TEMPLATE_SUB_CATEGORY_LIST = [
    {label: "FRONT-END", value: "REACT"},
    {label: "BACKEND", value: "ANGULAR"},
    {label: "FULL STACK", value: "FULL STACK"},
    {label: "MOBILE APP", value: "MOBILE APP"},
    {label: "PRODUCT", value: "PRODUCT"},
    {label: "DESIGN", value: "DESIGN"},
]
export const QUESTION_TYPE = [
    {label: "MULTIPLE CHOICE", value: "MULTIPLE_CHOICE"},
    {label: "SINGLE CHOICE", value: "SINGLE_CHOICE"},
    {label: "WRITTEN", value: "WRITTEN_ANSWER"},
    {label: "CODING", value: "CODING_ANSWER"}
];

export const QUESTION_WEIGHT = [
    {label: "EASY", value: "EASY"},
    {label: "STANDARD", value: "STANDARD"},
    {label: "HARD", value: "HARD"}
];