import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    Stack,
    TextField
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {pageRoutes} from "../helpers/constant/pageRoutes";
import {loginRegisterPattern} from "../helpers/constant/registerPattern";
import {selectAdminAuthState} from "../state/adminAuth/adminAuthSelector";
import {loginAdmin, resetLoginAdmin} from "../state/adminAuth/adminAuthSlice";

const SignIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isLoading, isLogin} = useSelector(selectAdminAuthState);

    const {handleSubmit, control} = useForm();

    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    useEffect(() => {
        if (isLogin) {
            navigate(pageRoutes.companies.path);
            dispatch(resetLoginAdmin());
        }
    }, [dispatch, isLogin, navigate]);

    // ## Form Submit Action
    const onSubmit = (data) => {
        dispatch(loginAdmin(data));
    };

    return (
        <Stack sx={{direction: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
            <Paper elevation={5} sx={{width: 600, p: 8}}>
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={12}>
                            <Controller
                                control={control}
                                name='username'
                                render={({field, fieldState: {invalid, error}}) => (
                                    <FormControl fullWidth
                                                 error={!!(invalid && error.message)}>
                                        <FormLabel>User Name</FormLabel>
                                        <TextField
                                            type='text'
                                            size={"small"}
                                            placeholder='username'
                                            {...field}
                                        />
                                        <FormHelperText>{invalid && error.message}</FormHelperText>
                                    </FormControl>
                                )}
                                rules={loginRegisterPattern.username}
                            />
                        </Grid>
                        <Grid item sm={12} md={12}>
                            <Controller
                                control={control}
                                name='password'
                                defaultValue=''
                                render={({field, fieldState: {invalid, error}}) => (
                                    <FormControl fullWidth
                                                 error={!!(invalid && error.message)}>
                                        <FormLabel>Password</FormLabel>
                                        <TextField
                                            size={"small"}
                                            type={showPassword ? "text" : "password"}
                                            placeholder='Password'
                                            {...field}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={toggleShowPassword} edge='end'>
                                                        {showPassword ? <VisibilityOff/> :
                                                            <Visibility/>}
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        <FormHelperText>{invalid && error.message}</FormHelperText>
                                    </FormControl>
                                )}
                                rules={loginRegisterPattern.password}
                            />
                        </Grid>
                        <Grid item sm={12} md={12} textAlign={'center'} mt={3}>
                            <Button
                                size={"medium"}
                                variant={"contained"}
                                type={"submit"}
                                disabled={isLoading}>
                                {isLoading ? "Sign In..." : "Sign In"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Stack>
    );
};

export default SignIn;