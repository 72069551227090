import React, {useEffect} from "react";
import Select from "react-select";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, FormControl, FormHelperText, FormLabel, Stack, TextField,} from "@mui/material";
import {tempAssPattern} from "../../../helpers/constant/registerPattern";
import {
    createTemplateAssessment,
    editTemplateAssessment,
    resetCreateTemplateAssessment,
    resetEditTemplateAssessment
} from "../../../state/templateAssessment/templateAssessmentSlice";
import {selectTemplateAssessmentState} from "../../../state/templateAssessment/templateAssessmentSelector";
import {selectQuestionAllSubCategory} from "../../../state/questionCategory/questionCategorySelector";
import {selectTempAssCategoryState} from "../../../state/tempAssCategory/tempAssCategorySelector";

const AddOrEditTemplateAssessment = ({onClose, templateAssessmentInfo, isEdit = false}) => {
    const dispatch = useDispatch();
    const {isAdding, isAddSuccess, isUpdating, isUpdateSuccess} = useSelector(selectTemplateAssessmentState);
    const {data: categoryList} = useSelector(selectTempAssCategoryState);
    const {data: questionSkills, renderId} = useSelector(selectQuestionAllSubCategory);

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        watch
    } = useForm({
        mode: "onSubmit",
    });

    useEffect(() => {
        if (isEdit && templateAssessmentInfo?.id) {
            setValue("name", templateAssessmentInfo.name);
            const findCategory = categoryList.find(category => category.value === templateAssessmentInfo.categoryId);
            const {value, subCategories = []} = findCategory || {};
            if(value) {
                setValue("category", findCategory);
                setValue("subCategory", subCategories?.find(subCategory => subCategory.value ===  templateAssessmentInfo.subCategoryId));
            }
        }
    }, [isEdit, templateAssessmentInfo]);

    useEffect(() => {
        if(renderId && templateAssessmentInfo?.id && questionSkills.length > 0) {
            const data = questionSkills.filter(item => templateAssessmentInfo.skills.includes(item.value));
            setValue("skills", data);
        }
    }, [renderId]);

    useEffect(() => {
        if (isAddSuccess || isUpdateSuccess) {
            dispatch(resetCreateTemplateAssessment());
            dispatch(resetEditTemplateAssessment());
            onClose();
        }
    }, [dispatch, isAddSuccess, isUpdateSuccess, onClose]);

    const onSubmit = (data) => {
        data.categoryId = data.category?.value;
        data.subCategoryId = data.subCategory?.value;
        data.skills = data.skills?.map(skill => skill.value);

        delete data.category;
        delete data.subCategory;

        if (isEdit) {
            data.id = templateAssessmentInfo.id;
            dispatch(editTemplateAssessment(data));
        } else {
            dispatch(createTemplateAssessment(data));
        }
    };

    watch("category");
    const subCategoryList = getValues("category")?.value ? getValues("category").subCategories : [];
    const isLoading = !!(isAdding || isUpdating);
    return (
        <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={"column"} spacing={3}>
                <Controller
                    control={control}
                    name='name'
                    defaultValue=''
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl fullWidth required error={!!(invalid && error.message)}>
                            <FormLabel>Template Name</FormLabel>
                            <TextField
                                type={'text'}
                                size={'small'}
                                placeholder={"write here.."}
                                value={value}
                                onChange={onChange}
                            />
                            {invalid && error.message && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                    )}
                    rules={tempAssPattern.name}
                />

                <Controller
                    control={control}
                    name='category'
                    defaultValue=''
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl fullWidth required error={!!(invalid && error.message)}>
                            <FormLabel>Category</FormLabel>
                            <Select
                                className={"select-dropdown-menu select-dropdown-menu-custom"}
                                classNamePrefix={"select-dropdown-styles"}
                                isMulti={false}
                                isSearchable={true}
                                menuPlacement='bottom'
                                options={categoryList}
                                value={value}
                                onChange={(newValue) => {
                                    onChange(newValue);
                                    setValue("subCategory", null);
                                }}
                            />
                            <FormHelperText error={invalid}>{invalid && error.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={tempAssPattern.category}
                />
                <Controller
                    control={control}
                    name='subCategory'
                    defaultValue=''
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl fullWidth required error={!!(invalid && error.message)}>
                            <FormLabel>Sub Category</FormLabel>
                            <Select
                                className={"select-dropdown-menu select-dropdown-menu-custom"}
                                classNamePrefix={"select-dropdown-styles"}
                                isMulti={false}
                                isSearchable={false}
                                menuPlacement='bottom'
                                options={subCategoryList}
                                value={value}
                                onChange={onChange}
                            />
                            <FormHelperText error={invalid}>{invalid && error.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={tempAssPattern.subCategory}
                />

                <Controller
                    control={control}
                    name='skills'
                    defaultValue=''
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl fullWidth required error={!!(invalid && error.message)}>
                            <FormLabel>Skills</FormLabel>
                            <Select
                                className={"select-dropdown-menu select-dropdown-menu-custom"}
                                classNamePrefix={"select-dropdown-styles"}
                                isMulti={true}
                                isSearchable={true}
                                menuPlacement='bottom'
                                options={questionSkills}
                                value={value}
                                onChange={onChange}
                            />
                            <FormHelperText error={invalid}>{invalid && error.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={tempAssPattern.skills}
                />

                <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} mt={2}>
                    <Button variant={"text"} onClick={onClose} size={"small"}>
                        Cancel
                    </Button>
                    <Button type={"submit"} disabled={isLoading} variant={"contained"} size={"small"}>
                        {isEdit ? "Update " : "Add "} Template {isLoading && "..."}
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
};

export default AddOrEditTemplateAssessment;