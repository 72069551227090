const PERSONALIZE_TAG = {
    MY_PROFILE_FIRST_NAME: "{{my_profile_first_name}}",
    MY_PROFILE_LAST_NAME: "{{my_profile_last_name}}",
    MY_PROFILE_EMAIL: "{{my_profile_email}}",
    MY_PROFILE_PHONE: "{{my_profile_phone}}",
    MY_PROFILE_ADDRESS: "{{my_profile_address}}",
    MY_PROFILE_CITY: "{{my_profile_city}}",
    MY_PROFILE_COUNTRY: "{{my_profile_country}}",
    MY_PROFILE_DESIGNATION: "{{my_profile_designation}}",

    CANDIDATE_NAME: "{{candidate_name}}",
    CANDIDATE_EMAIL: "{{candidate_email}}",
    CANDIDATE_RESUME: "{{candidate_resume}}",

    MY_COMPANY_NAME: "{{my_company_name}}",
    MY_COMPANY_EMAIL: "{{my_company_email}}",
    MY_COMPANY_WEBSITE: "{{my_company_website}}",
    MY_COMPANY_ADDRESS: "{{my_company_address}}",
    MY_COMPANY_ESTABLISH_YEAR: "{{my_company_establish_year}}",
    MY_COMPANY_SIZE: "{{my_company_size}}",
    MY_INDUSTRY_TYPE: "{{my_company_industry_type}}",
    MY_COMPANY_FACEBOOK_LINK: "{{my_company_facebook_link}}",
    MY_COMPANY_TWITTER_LINK: "{{my_company_twitter_link}}",
    MY_COMPANY_LINKEDIN_LINK: "{{my_company_linkedin_link}}",
    MY_COMPANY_INSTAGRAM_LINK: "{{my_company_instagram_link}}",
    MY_COMPANY_STACK_OVERFLOW_LINK: "{{my_company_stack_overflow}}",
    MY_COMPANY_GITHUB_LINK: "{{my_company_github_link}}",

    JOB_POST_TITLE: "{{job_post_title}}",
    JOB_POST_LINK: "{{job_post_link}}",
    JOB_POST_DESCRIPTION: "{{job_post_description}}",
    JOB_POST_CATEGORY: "{{job_post_category}}",
    JOB_POST_TYPE: "{{job_post_job_type}}",
    JOB_POST_DEPARTMENT: "{{job_post_department}}",
    JOB_POST_SKILLS: "{{job_post_skills}}",
    JOB_POST_REGIONS: "{{job_post_regions}}",
    JOB_POST_TIME_ZONES: "{{job_post_time_zones}}",
    JOB_POST_EXPERIENCES: "{{job_post_experiences}}",
    JOB_POST_NOTE_FOR_CANDIDATE: "{{job_post_note_for_candidate}}",
    JOB_POST_VACANCY: "{{job_post_vacancy}}",
    JOB_POST_SALARY: "{{job_post_salary}}",

    INTERVIEW_TITLE: "{{interview_title}}",
    INTERVIEW_START_DATE: "{{interview_start_date}}",
    INTERVIEW_START_TIME: "{{interview_start_time}}",
    INTERVIEW_END_TIME: "{{interview_end_time}}",
    INTERVIEW_ADDRESS: "{{interview_address}}",
};

const PERSONALIZED_TAG_ID = {
    MY_PROFILE: 1,
    CANDIDATE: 2,
    MY_COMPANY: 3,
    JOB_POST: 4,
    INTERVIEW: 5,
};

export const PERSONALIZED_TAGS_MY_PROFILE = {
    id: PERSONALIZED_TAG_ID.CANDIDATE,
    label: "My Profile",
    tags: [
        { value: PERSONALIZE_TAG.MY_PROFILE_FIRST_NAME, label: "First Name" },
        { value: PERSONALIZE_TAG.MY_PROFILE_LAST_NAME, label: "Last Name" },
        { value: PERSONALIZE_TAG.MY_PROFILE_EMAIL, label: "email" },
        { value: PERSONALIZE_TAG.MY_PROFILE_PHONE, label: "phone" },
        { value: PERSONALIZE_TAG.MY_PROFILE_ADDRESS, label: "address" },
        { value: PERSONALIZE_TAG.MY_PROFILE_CITY, label: "city" },
        { value: PERSONALIZE_TAG.MY_PROFILE_COUNTRY, label: "country" },
        { value: PERSONALIZE_TAG.MY_PROFILE_DESIGNATION, label: "designation" },
    ],
};

export const PERSONALIZED_TAGS_CANDIDATE = {
    id: PERSONALIZED_TAG_ID.CANDIDATE,
    label: "Candidate",
    tags: [
        { value: PERSONALIZE_TAG.CANDIDATE_NAME, label: "Name" },
        { value: PERSONALIZE_TAG.CANDIDATE_EMAIL, label: "Email" },
        // { value: PERSONALIZE_TAG.CANDIDATE_RESUME, label: "Resume" },
    ],
};

export const PERSONALIZED_TAGS_MY_COMPANY = {
    id: PERSONALIZED_TAG_ID.MY_COMPANY,
    label: "My Company",
    tags: [
        { value: PERSONALIZE_TAG.MY_COMPANY_NAME, label: "Name" },
        { value: PERSONALIZE_TAG.MY_COMPANY_EMAIL, label: "Email" },
        { value: PERSONALIZE_TAG.MY_COMPANY_WEBSITE, label: "Website" },
        { value: PERSONALIZE_TAG.MY_COMPANY_ADDRESS, label: "Address" },
        { value: PERSONALIZE_TAG.MY_COMPANY_ESTABLISH_YEAR, label: "Establish Year" },
        { value: PERSONALIZE_TAG.MY_COMPANY_SIZE, label: "Size" },
        { value: PERSONALIZE_TAG.MY_INDUSTRY_TYPE, label: "My Industry Type" },
        { value: PERSONALIZE_TAG.MY_COMPANY_FACEBOOK_LINK, label: "Facebook Link" },
        { value: PERSONALIZE_TAG.MY_COMPANY_TWITTER_LINK, label: "Twitter List" },
        { value: PERSONALIZE_TAG.MY_COMPANY_LINKEDIN_LINK, label: "LinkedIn Link" },
        { value: PERSONALIZE_TAG.MY_COMPANY_INSTAGRAM_LINK, label: "Instagram Link" },
        { value: PERSONALIZE_TAG.MY_COMPANY_STACK_OVERFLOW_LINK, label: "Stack Overflow Link" },
        { value: PERSONALIZE_TAG.MY_COMPANY_GITHUB_LINK, label: "Github Link" },
    ],
};

export const PERSONALIZED_TAGS_JOB_POST = {
    id: PERSONALIZED_TAG_ID.JOB_POST,
    label: "Job Post",
    tags: [
        { value: PERSONALIZE_TAG.JOB_POST_TITLE, label: "Title" },
        // { value: PERSONALIZE_TAG.JOB_POST_LINK, label: "Link" },
        { value: PERSONALIZE_TAG.JOB_POST_DESCRIPTION, label: "Description" },
        { value: PERSONALIZE_TAG.JOB_POST_CATEGORY, label: "Category" },
        { value: PERSONALIZE_TAG.JOB_POST_TYPE, label: "Job Type" },
        { value: PERSONALIZE_TAG.JOB_POST_DEPARTMENT, label: "Department" },
        { value: PERSONALIZE_TAG.JOB_POST_SKILLS, label: "Skills" },
        { value: PERSONALIZE_TAG.JOB_POST_REGIONS, label: "Regions" },
        { value: PERSONALIZE_TAG.JOB_POST_TIME_ZONES, label: "Timezones" },
        { value: PERSONALIZE_TAG.JOB_POST_EXPERIENCES, label: "Experiences" },
        { value: PERSONALIZE_TAG.JOB_POST_NOTE_FOR_CANDIDATE, label: "Note For Candidate" },
        { value: PERSONALIZE_TAG.JOB_POST_VACANCY, label: "Vacancy" },
        { value: PERSONALIZE_TAG.JOB_POST_SALARY, label: "Salary" },
    ],
};

export const PERSONALIZED_TAGS_INTERVIEW = {
    id: PERSONALIZED_TAG_ID.INTERVIEW,
    label: "Interview",
    tags: [
        { value: PERSONALIZE_TAG.INTERVIEW_TITLE, label: "Title" },
        { value: PERSONALIZE_TAG.INTERVIEW_START_DATE, label: "Start Date" },
        { value: PERSONALIZE_TAG.INTERVIEW_START_TIME, label: "Start Time" },
        { value: PERSONALIZE_TAG.INTERVIEW_END_TIME, label: "End Time" },
        { value: PERSONALIZE_TAG.INTERVIEW_ADDRESS, label: "Address" },
    ],
};

export const PERSONALIZED_TAGS = [
    PERSONALIZED_TAGS_MY_PROFILE,
    PERSONALIZED_TAGS_CANDIDATE,
    PERSONALIZED_TAGS_MY_COMPANY,
    PERSONALIZED_TAGS_JOB_POST,
];
