import {combineReducers} from "@reduxjs/toolkit";
import adminAuthSlice from "./adminAuth/adminAuthSlice";
import jobSeekerSlice from "./jobSeeker/jobSeekerSlice";
import companyListSlice from "./companyList/companyListSlice";
import messageTemplateSlice from "./messageTemplate/messageTemplateSlice";
import employerTemplateSlice from "./employerTemplate/employerTemplateSlice";
import jobPostListSlice from "./jobPostList/jobPostListSlice";
import blogsSlice from "./blogs/blogsSlice";
import assQuestionSlice from "./assQuestion/assQuestionSlice";
import questionCategorySlice from "./questionCategory/questionCategorySlice";
import tempAssCategorySlice from "./tempAssCategory/tempAssCategorySlice";
import templateAssessmentSlice from "./templateAssessment/templateAssessmentSlice";

const combinedReducer = combineReducers({
    adminAuth: adminAuthSlice,
    jobSeeker: jobSeekerSlice,
    companyList: companyListSlice,
    messageTemplate: messageTemplateSlice,
    employerTemplate: employerTemplateSlice,
    jobPostList: jobPostListSlice,
    blogs: blogsSlice,
    assQuestion: assQuestionSlice,
    questionCategory: questionCategorySlice,
    tempAssCategory: tempAssCategorySlice,
    templateAssessment: templateAssessmentSlice
});

const rootReducer = (state, action) => {
    return combinedReducer(state, action);
};

export default rootReducer;
