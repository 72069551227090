import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Divider, Grid, ListItemButton, Stack, styled} from "@mui/material";
import {selectAssQuestionFilters} from "../../../state/assQuestion/assQuestionSelector";
import {addCategoryAndSubCategory} from "../../../state/assQuestion/assQuestionSlice";
import BasicModalWithHeader from "../../../global/Modal/BasicModalWithHeader";
import {QuestionsSidebar} from "./QuestionsSidebar";
import useBoolean from "../../../hooks/useBoolean";
import AddOrEditQuestion from "./AddOrEditQuestion";
import AddOrEditCategory from "./AddOrEditCategory";
import QuestionsHeader from "./QuestionsHeader";
import QuestionTable from "./QuestionTable";
import AddIcon from "@mui/icons-material/Add";
import Styles from "../assessment.module.scss";

const ListItemButtonStyled = styled(ListItemButton)(({theme}) => ({
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    padding: 3,
    borderRadius: 6,
}));

const Questions = () => {
    const dispatch = useDispatch();
    const {subCategoryId} = useSelector(selectAssQuestionFilters)
    const {value: openModal, setTrue: onOpenModal, setFalse: onCloseModal} = useBoolean(false);
    const {
        value: openAddCategoryModal, setTrue: onOpenAddCategoryModal, setFalse: onCloseAddCategoryModal,
    } = useBoolean(false);

    const handleSelect = () => {
        dispatch(addCategoryAndSubCategory({categoryId: '', subCategoryId: ''}))
    }

    return (
        <>
            <div className={Styles.assessmentsListWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={3} sm={3} md={2.5} pr={4}>
                        <Stack direction={'column'} spacing={2} alignItems={'flex-start'} mb={2}>
                            <Button variant={'contained'} size={'small'}
                                    endIcon={<AddIcon fontSize={'large'}/>}
                                    sx={{width: '100%', height: "45px", borderRadius: "5px!important"}}
                                    onClick={onOpenModal}
                            >
                                Add Question
                            </Button>
                            <Button variant={'outlined'} color={'primary'} size={'small'}
                                    endIcon={<AddIcon fontSize={'medium'}/>}
                                    sx={{width: '100%', height: "35px", padding: "15px", borderRadius: "5px!important"}}
                                    onClick={onOpenAddCategoryModal}>Add
                                Category </Button>
                        </Stack>
                        <ListItemButtonStyled onClick={handleSelect} selected={subCategoryId === ''}>
                            <span style={{paddingLeft: "10px"}}>All Questions</span>
                        </ListItemButtonStyled>
                        <Divider sx={{my: 1}}/>
                        <Box sx={{height: "calc(100vh - 300px)", overflowY: "scroll"}}>
                            <QuestionsSidebar/>
                        </Box>
                    </Grid>
                    <Grid container xs={9} sm={9} md={9.5}>
                        <div style={{width: "100%"}}>
                            <QuestionsHeader/>
                            <QuestionTable/>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <BasicModalWithHeader
                title={"Add Question"}
                disableOutSideClick={true}
                open={openModal}
                onClose={onCloseModal}
                width={900}
                autoScroll={true}
            >
                <AddOrEditQuestion onClose={onCloseModal}/>
            </BasicModalWithHeader>

            <BasicModalWithHeader
                title={"Add New Category"}
                open={openAddCategoryModal}
                onClose={onCloseAddCategoryModal}
                width={500}
                autoScroll={true}
            >
                <AddOrEditCategory onClose={onCloseAddCategoryModal}/>
            </BasicModalWithHeader>
        </>
    );
};

export default Questions;