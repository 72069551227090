import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    page: 1,
    perPage: 10,
    count: 0,
    isLoading: false,
    isError: false,
    error: "",

    isAdding: false,
    isAddSuccess: false,

    isUpdating: false,
    isUpdateSuccess: false,

    isDeleting: false,
    isDeleteSuccess: false,

    filters: {
        status: null,
        type: null
    }
};

const employerTemplateSlice = createSlice({
    name: "employerTemplate",
    initialState: initialState,
    reducers: {
        getEmployerTemplates: (state) => {
            state.isLoading = true;
        },
        getEmployerTemplatesSuccess: (state, action) => {
            state.data = action.payload.rows;
            state.page = +action.payload.page;
            state.perPage = +action.payload.perPage;
            state.count = action.payload.count;
            state.isLoading = false;
            state.isError = false;
            state.error = "";
        },
        getEmployerTemplatesFailed: (state, action) => {
            state.data = [];
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },

        createEmployerTemplate: (state) => {
            state.isAdding = true;
        },
        createEmployerTemplateSuccess: (state, action) => {
            state.isAdding = false;
            state.isAddSuccess = true;
            state.data.push(action.payload);
            state.count += 1;
        },
        resetCreateEmployerTemplate: (state) => {
            state.isAdding = false;
            state.isAddSuccess = false;
        },

        editEmployerTemplate: (state) => {
            state.isUpdating = true;
        },
        editEmployerTemplateSuccess: (state, action) => {
            state.isUpdating = false;
            state.isUpdateSuccess = true;
            state.data = state.data.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload };
                }
                return item;
            });
        },
        resetEditEmployerTemplate: (state) => {
            state.isUpdating = false;
            state.isUpdateSuccess = false;
        },
        toggleEmployerTemplateStatus: () => {},
        toggleEmployerTemplateStatusSuccess: (state, action) => {
            state.data = state.data.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload };
                }
                return item;
            });
        },

        deleteEmployerTemplate: (state) => {
            state.isDeleting = true;
        },
        deleteEmployerTemplateSuccess: (state, action) => {
            state.isDeleting = false;
            state.isDeleteSuccess = true;
            state.data = state.data.filter((item) => item.id !== action.payload.id);
            state.count -= 1;
        },
        resetDeleteEmployerTemplate: (state) => {
            state.isDeleting = false;
            state.isDeleteSuccess = false;
        },

        addEmployerTemplateStatus: (state, action) => {
            state.filters.status = action.payload
        },
        addEmployerTemplateType: (state, action) => {
            state.filters.type = action.payload
        },
        resetEmployerTemplateFilter: (state) => {
            state.filters.status = null;
            state.filters.type = null;
        },
    },
});

export const {
    getEmployerTemplates,
    getEmployerTemplatesSuccess,
    getEmployerTemplatesFailed,

    createEmployerTemplate,
    createEmployerTemplateSuccess,
    resetCreateEmployerTemplate,

    editEmployerTemplate,
    editEmployerTemplateSuccess,
    resetEditEmployerTemplate,

    toggleEmployerTemplateStatus,
    toggleEmployerTemplateStatusSuccess,

    deleteEmployerTemplate,
    deleteEmployerTemplateSuccess,
    resetDeleteEmployerTemplate,

    addEmployerTemplateStatus,
    addEmployerTemplateType,
    resetEmployerTemplateFilter

} = employerTemplateSlice.actions;

export default employerTemplateSlice.reducer;
