import React, {useState} from "react";
import {useDispatch} from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddOrEditTemplate from "./AddOrEditTemplate";
import useBoolean from "../../hooks/useBoolean";
import CloseModal from "../../global/Modal/CloseModal";
import BasicDrawer from "../../global/Drawer/BasicDrawer";
import WarningModalContent from "../../global/Modal/ModalContent/WarningModalContent";
import {deleteEmployerTemplate, toggleEmployerTemplateStatus} from "../../state/employerTemplate/employerTemplateSlice";
import {EMPLOYER_TEMPLATE_STATUS} from "../../helpers/constant/employerTemplateConstant";

const ITEM_HEIGHT = 48;

const TemplatesMenuAction = ({template}) => {
    const dispatch = useDispatch();
    const {value: openDrawer, setTrue: onOpenDrawer, setFalse: onCloseDrawer} = useBoolean(false);
    const {value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal} = useBoolean(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleStatusChange = (status) => {
        dispatch(toggleEmployerTemplateStatus({id: template.id, status: status}));
        handleMenuClose();
    }

    const handleEdit = () => {
        onOpenDrawer();
        handleMenuClose();
    }

    const handleDeleteConfirm = () => {
        dispatch(deleteEmployerTemplate({id: template.id}))
    }

    const open = Boolean(anchorEl);
    const toggleStatus = template.status === EMPLOYER_TEMPLATE_STATUS.PUBLISH ? EMPLOYER_TEMPLATE_STATUS.UN_PUBLISH : EMPLOYER_TEMPLATE_STATUS.PUBLISH
    return (
        <>
            <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup='true'
                onClick={handleMenuClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id='long-menu'
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                    },
                }}>
                <MenuItem onClick={() => handleStatusChange(toggleStatus)}>
                    {toggleStatus === EMPLOYER_TEMPLATE_STATUS.PUBLISH ? "Publish" : "Unpublish" }
                </MenuItem>
                <MenuItem onClick={handleEdit} >
                    Edit
                </MenuItem>
                <MenuItem onClick={onOpenDeleteModal} >
                    Delete
                </MenuItem>
            </Menu>

            <BasicDrawer title={'Edit Template'} open={openDrawer} onClose={onCloseDrawer}>
                <AddOrEditTemplate isEdit={true} onClose={onCloseDrawer} templateInfo={template}/>
            </BasicDrawer>
            <CloseModal open={openDeleteModal} onClose={onCloseDeleteModal} width={'26%'}>
                <WarningModalContent
                    title={"Warning"}
                    subTitle={"Are you sure to delete this template"}
                    description={"If you delete this template, you’ll not be able to recover it again."}
                    buttonName={"Okay, Delete"}
                    onConfirm={handleDeleteConfirm}
                    onClose={onCloseDeleteModal}
                />
            </CloseModal>
        </>
    );
};

export default TemplatesMenuAction;