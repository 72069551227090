import {all, call, put, takeLatest, debounce} from "redux-saga/effects";
import TemplateAssessmentService from "../../services/templateAssessment.service";
import {
    createTemplateAssessmentSuccess,
    deleteTemplateAssessmentSuccess,
    editTemplateAssessmentSuccess,
    getTemplateAssessmentListFailed,
    getTemplateAssessmentListSuccess,
    resetCreateTemplateAssessment,
    resetDeleteTemplateAssessment,
    resetEditTemplateAssessment,
    updateTemplateAssessmentStatusFailed,
    updateTemplateAssessmentStatusSuccess
} from "./templateAssessmentSlice";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utils/toaster";


function* templateAssessmentWatcher() {
    yield debounce(1000, "templateAssessment/getTemplateAssessmentList", getTemplateAssessmentListSaga);
    yield takeLatest("templateAssessment/createTemplateAssessment", createTemplateAssessmentSaga);
    yield takeLatest("templateAssessment/editTemplateAssessment", editTemplateAssessmentSaga);
    yield takeLatest("templateAssessment/updateTemplateAssessmentStatus", updateTemplateAssessmentStatusSaga);
    yield takeLatest("templateAssessment/deleteTemplateAssessment", deleteTemplateAssessmentSaga);
}

function* getTemplateAssessmentListSaga(action) {
    try {
        const response = yield call(TemplateAssessmentService.getTemplateAssessmentList, action.payload);

        if (response.status) {
            yield put(getTemplateAssessmentListSuccess(response.data));
        } else {
            yield put(getTemplateAssessmentListFailed(response.message));
        }
    } catch (err) {
        yield put(getTemplateAssessmentListFailed(err.message));
    }
}

function* createTemplateAssessmentSaga(action) {
    try {
        const response = yield call(TemplateAssessmentService.createTemplateAssessment, action.payload);

        if (response.status) {
            yield put(createTemplateAssessmentSuccess(response.data));
            showSuccessToaster(response.message)
        } else {
            yield put(resetCreateTemplateAssessment(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* editTemplateAssessmentSaga(action) {
    try {
        const response = yield call(TemplateAssessmentService.editTemplateAssessment, action.payload);

        if (response.status) {
            yield put(editTemplateAssessmentSuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(resetEditTemplateAssessment(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* updateTemplateAssessmentStatusSaga(action) {
    try {
        const response = yield call(TemplateAssessmentService.updateTemplateAssessmentStatus, action.payload);

        if (response.status) {
            yield put(updateTemplateAssessmentStatusSuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(updateTemplateAssessmentStatusFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* deleteTemplateAssessmentSaga(action) {
    try {
        const response = yield call(TemplateAssessmentService.deleteTemplateAssessment, action.payload);

        if (response.status) {
            yield put(deleteTemplateAssessmentSuccess(action.payload));
            showSuccessToaster(response.message);
        } else {
            yield put(resetDeleteTemplateAssessment(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* templateAssessmentSaga() {
    yield all([templateAssessmentWatcher()]);
}
