import httpRequestAdmin from "./httpRequest/httpRequestAdmin";

class TemplateAssessmentService {
    getTemplateAssessmentList(params) {
        return httpRequestAdmin.get(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/admin/list", params);
    }
    createTemplateAssessment(params) {
        return httpRequestAdmin.post(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/admin/create", params);
    }
    editTemplateAssessment(params) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/admin/update", params);
    }
    updateTemplateAssessmentStatus(body) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/admin/update/status", body);
    }
    deleteTemplateAssessment(params) {
        return httpRequestAdmin.delete(process.env.REACT_APP_BACKEND_API_URL + "template/assessment/admin/delete", params);
    }
}

export default new TemplateAssessmentService();
