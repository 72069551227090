import httpRequestAdmin from "./httpRequest/httpRequestAdmin";

class CompanyListService {
    getCompanyList(params) {
        return httpRequestAdmin.get(process.env.REACT_APP_BACKEND_API_URL + "admin/companyInfo/list", params);
    }
    updateCompanyStatus(body) {
        return httpRequestAdmin.put(process.env.REACT_APP_BACKEND_API_URL + "admin/companyInfo/update/status", body);
    }
}

export default new CompanyListService();
