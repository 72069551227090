import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import TableLoaderSkeleton from "../../global/Loader/TableLoaderSkeleton";
import GlobalTablePagination from "../../global/Pagination/GlobalTablePagination";
import { USER_STATUS } from "../../helpers/constant/coreConstant";
import { getFullName } from "../../helpers/utils/getFullName";
import { selectJobSeekerState } from "../../state/jobSeeker/jobSeekerSelector";
import { getJobSeekersData } from "../../state/jobSeeker/jobSeekerSlice";
import JobSeekerHeader from "./JobSeekerHeader";
import JsMenuAction from "./JsMenuAction";

const headCells = [
    {
        id: "Name",
        label: "Name",
    },
    {
        id: "Linkedin",
        label: "Linkedin",
    },
    {
        id: "Email",
        label: "Email",
    },
    {
        id: "Image",
        label: "Image",
    },
    {
        id: "Joining Date",
        label: "Joining Date",
    },
    {
        id: "Status",
        label: "Status",
    },
    {
        id: "Actions",
        label: "Actions",
    },
];

const TableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align='left' padding={"normal"}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const JobSeeker = () => {
    const dispatch = useDispatch();
    const { data: jobSeekerList, isLoading, page, perPage, count } = useSelector(selectJobSeekerState);

    useEffect(() => {
        dispatch(getJobSeekersData({ page: 1, perPage: perPage }));
    }, [dispatch, perPage]);

    const onChangePage = (event, newPage) => {
        dispatch(getJobSeekersData({ page: newPage, perPage: perPage }));
    };

    const onChangePerPage = (perPage, newPage) => {
        dispatch(getJobSeekersData({ page: newPage, perPage: perPage }));
    };

    let content = null;
    if (isLoading) {
        content = <TableLoaderSkeleton columns={4} numberOfRows={10} />;
    } else if (jobSeekerList?.length === 0) {
        content = <GlobalEmptyPage title={"No job seeker found!"} />;
    } else if (jobSeekerList?.length > 0) {
        content = (
            <>
                <TableContainer>
                    <Table sx={{ minWidth: 750 }}>
                        <TableHeader />
                        <TableBody>
                            {jobSeekerList.map(jobSeeker => {
                                const { id, email, createdAt, status, externalLinks } = jobSeeker || {};
                                const joiningData = format(new Date(createdAt), "MMM dd, yyyy");
                                const links = externalLinks ? JSON.parse(externalLinks) : {};
                                return (
                                    <TableRow key={id} hover>
                                        <TableCell align='left' style={{ width: '24rem' }}>
                                            {getFullName(jobSeeker.firstName, jobSeeker.lastName) || "-"}
                                        </TableCell>
                                        <TableCell align='left' style={{ width: '24rem' }}>
                                            <a href={links.linkedin} target="_blank" rel="noreferrer">
                                                {links.linkedin}
                                            </a>
                                        </TableCell>
                                        <TableCell align='left' style={{ width: '24rem' }}>
                                            {email}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {jobSeeker.image ?
                                                <img src={jobSeeker.image} alt={''} width={40} height={40}
                                                    style={{ borderRadius: "50%" }} />
                                                : "-"}
                                        </TableCell>
                                        <TableCell align='left'>{joiningData}</TableCell>
                                        <TableCell align='left'>
                                            <Chip label={USER_STATUS.ACTIVE === status ? "Active" : "Blocked"}
                                                color={status === 0 ? 'warning' : 'success'} />
                                        </TableCell>
                                        <TableCell align='left'><JsMenuAction jobSeeker={jobSeeker} /></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <GlobalTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={perPage}
                    onChangePerPage={onChangePerPage}
                    onChangePage={onChangePage}
                />
            </>
        );
    }

    return (
        <>
            <Paper elevation={0}>
                <JobSeekerHeader />
                {content}
            </Paper>
        </>
    );
};
export default JobSeeker;