import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Box, Button } from "@mui/material";

const CustomEditorWithPersonalizeTag = ({
    theme = "snow",
    height = 150,
    placeholder = "Write your message...",
    personalizeTags = [],
    ...rest
}) => {
    let reactQuillRef = useRef(null);

    const insertText = (quillRef, newValue) => {
        const { editor } = quillRef || {};
        if (editor) {
            const range = quillRef.editor.getSelection();
            let position = range ? range.index : 0;
            quillRef.editor.insertText(position, newValue + " ");
        }
    };

    return (
        <div>
            <ReactQuill
                forwardedRef={reactQuillRef}
                theme={theme}
                placeholder={placeholder}
                style={{ height: height, marginBottom: "48px" }}
                {...rest}
            />

            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", pt: 1 }}>
                {Array.isArray(personalizeTags) &&
                    personalizeTags.map((tag, index) => (
                        <Button
                            key={index}
                            variant={"text"}
                            size={"small"}
                            type={"button"}
                            sx={{ height: 28, p: 1 }}
                            onClick={() => insertText(reactQuillRef.current, tag.value)}
                        >
                            {tag.label}
                        </Button>
                    ))}
            </Box>
        </div>
    );
};

export default CustomEditorWithPersonalizeTag;
