import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import TableLoaderSkeleton from "../../global/Loader/TableLoaderSkeleton";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import MessageContentActions from "./MessageContentActions";
import GlobalTablePagination from "../../global/Pagination/GlobalTablePagination";
import HOCTemplateList from "./HOCTemplateList";
import EmptyBoxLogo from "../../assets/images/inbox-empty.png";

const MessageContentList = ({
                                onChangePage,
                                onChangePerPage,
                                isLoading = false,
                                data: templateList = [],
                                page = 1,
                                perPage = 10,
                                count = 0,
                            }) => {

    // decide what to render
    let content = null;
    if (isLoading) {
        content = <TableLoaderSkeleton columns={2} numberOfRows={10}/>;
    } else if (templateList?.length === 0) {
        content = <GlobalEmptyPage image={EmptyBoxLogo} title={"No message template found!"}/>;
    } else if (templateList?.length > 0) {
        content = (
            <>
                <TableContainer>
                    <Table sx={{minWidth: 750}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Template Name</TableCell>
                                <TableCell align={"left"}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templateList.map(template => {
                                return (
                                    <TableRow key={template.id}>
                                        <TableCell align='left' style={{width: '24rem'}}>
                                            {template.name}
                                        </TableCell>
                                        <TableCell align='right'>
                                            <MessageContentActions templateInfo={template}/>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <GlobalTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={perPage}
                    onChangePage={onChangePage}
                    onChangePerPage={onChangePerPage}
                />
            </>
        )
    }
    return (
        <div>
            {content}
        </div>
    );
};

export default HOCTemplateList(MessageContentList);