export const insertPersonalizedTag = (personalizedTag, inputRef, onChange) => {
    let input = inputRef.current;

    if (input.editor) {
        // find the save index for quill editor
        const saveRange = input.editor.selection?.savedRange || { index: 0 };
        let position = saveRange.index ? saveRange.index : 0;
        input.editor.insertText(position, personalizedTag + " ");

        // set the cursor position
        input.editor.setSelection(saveRange.index + personalizedTag.length + 1, 0);
    } else {
        //Inserting to input
        let value = input.value;
        let selectionStart = input.selectionStart;
        onChange(value.substring(0, selectionStart) + personalizedTag + value.substring(selectionStart));
        setTimeout(() => {
            input.setSelectionRange(selectionStart + personalizedTag.length, selectionStart + personalizedTag.length);
        }, 100);
    }
};
