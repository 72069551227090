import React from "react";
import {useDispatch} from "react-redux";
import {MoreVert} from "@mui/icons-material";
import {IconButton, Menu, MenuItem} from "@mui/material";
import AddOrEditSubCategory from "./AddOrEditSubCategory";
import AddOrEditCategory from "./AddOrEditCategory";
import CloseModal from "../../../global/Modal/CloseModal";
import useBoolean from "../../../hooks/useBoolean";
import BasicModalWithHeader from "../../../global/Modal/BasicModalWithHeader";
import WarningModalContent from "../../../global/Modal/ModalContent/WarningModalContent";
import {deleteTempAssCategory} from "../../../state/tempAssCategory/tempAssCategorySlice";

const ITEM_HEIGHT = 48;

const CategoriesActions = ({category}) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const {value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal} = useBoolean(false);
    const {
        value: openCategoryModal, setTrue: onOpenCategoryModal, setFalse: onCloseCategoryModal,
    } = useBoolean(false);
    const {
        value: openSubCategoryModal, setTrue: onOpenSubCategoryModal, setFalse: onCloseSubCategoryModal,
    } = useBoolean(false);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
        event.stopPropagation();
    };

    const handleMenuClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };


    const handleDeleteConfirm = () => {
        dispatch(deleteTempAssCategory({id: category.value}))
        onOpenDeleteModal();
    };

    const open = Boolean(anchorEl);
    return (
        <>
            <IconButton onClick={handleMenuClick} size={'small'}>
                <MoreVert fontSize={"small"} />
            </IconButton>
            <Menu
                id='long-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "25ch",
                    },
                }}
            >
                <MenuItem onClick={(e) => {
                    handleMenuClose(e);
                    onOpenCategoryModal();
                }}>
                    Edit Category
                </MenuItem>
                <MenuItem onClick={(e) => {
                    handleMenuClose(e);
                    onOpenSubCategoryModal();
                }}>
                    Add Sub Category
                </MenuItem>
                <MenuItem onClick={(e) => {
                    handleMenuClose(e);
                    onOpenDeleteModal();
                }}>
                    Delete
                </MenuItem>
            </Menu>
            <CloseModal open={openDeleteModal} onClose={onCloseDeleteModal} width={550}>
                <WarningModalContent
                    title={"Warning"}
                    subTitle={"Are you sure to delete this template category"}
                    description={"If you delete this template category, you’ll not be able to recover it again."}
                    buttonName={"Okay, Delete"}
                    onConfirm={handleDeleteConfirm}
                    onClose={onCloseDeleteModal}
                />
            </CloseModal>
            <BasicModalWithHeader
                title={"Edit Category"}
                open={openCategoryModal}
                onClose={onCloseCategoryModal}
                autoScroll={true}
                width={500}
            >
                <AddOrEditCategory onClose={onCloseCategoryModal} categoryInfo={category} isEdit={true}/>
            </BasicModalWithHeader>
            <BasicModalWithHeader
                title={"Add Sub Category"}
                open={openSubCategoryModal}
                onClose={onCloseSubCategoryModal}
                autoScroll={true}
                width={500}
            >
                <AddOrEditSubCategory onClose={onCloseSubCategoryModal} categoryId={category.value} isEdit={false}/>
            </BasicModalWithHeader>
        </>
    );
};

export default CategoriesActions;