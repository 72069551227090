export const JOB_POST_STATUS_OPEN = 1;
export const JOB_POST_STATUS_DRAFT = 2;
export const JOB_POST_STATUS_ARCHIVED = 3;

export const JOB_POST_PUBLISH_STATUS = {
    PENDING: 0,
    PUBLISHED: 1,
    REJECTED: 2,
};

export const JOB_POST_PACKAGE_TYPE = {
    GOOD: 1,
    BETTER: 2,
    BEST: 3,
};

export const JOB_POST_PACKAGE_TYPE_LIST = {
    [JOB_POST_PACKAGE_TYPE.GOOD]: {value: JOB_POST_PACKAGE_TYPE.GOOD, label: "Good"},
    [JOB_POST_PACKAGE_TYPE.BETTER]: {value: JOB_POST_PACKAGE_TYPE.BETTER, label: "Better"},
    [JOB_POST_PACKAGE_TYPE.BEST]: {value: JOB_POST_PACKAGE_TYPE.BEST, label: "Best"},
};

export const JOB_POST_PUBLISH_STATUS_CONTENT = {
    [JOB_POST_PUBLISH_STATUS.PENDING]: {value: JOB_POST_PUBLISH_STATUS.PENDING, label: "Pending"},
    [JOB_POST_PUBLISH_STATUS.PUBLISHED]: {value: JOB_POST_PUBLISH_STATUS.PUBLISHED, label: "Published"},
    [JOB_POST_PUBLISH_STATUS.REJECTED]: {value: JOB_POST_PUBLISH_STATUS.REJECTED, label: "Rejected"},
};

