import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, FormControl, FormLabel, Stack, TextField} from "@mui/material";
import {assQuestionCategoryPattern} from "../../../helpers/constant/registerPattern";
import {
    createQuestionCategory,
    editQuestionCategory,
    resetCreateQuestionCategory,
    resetEditQuestionCategory
} from "../../../state/questionCategory/questionCategorySlice";
import {selectQuestionCategoryState} from "../../../state/questionCategory/questionCategorySelector";

const AddOrEditCategory = ({onClose, categoryInfo, isEdit = false}) => {
    const dispatch = useDispatch();
    const {isAdding, isAddSuccess, isUpdating, isUpdateSuccess} = useSelector(selectQuestionCategoryState);

    const {
        control,
        handleSubmit,
        setValue,
    } = useForm({
        mode: "onSubmit",
    });

    useEffect(() => {
        if (categoryInfo?.value && isEdit) {
            setValue("name", categoryInfo.label);
        }
    }, [categoryInfo]);


    useEffect(() => {
        if (isAddSuccess || isUpdateSuccess) {
            dispatch(resetCreateQuestionCategory());
            dispatch(resetEditQuestionCategory());
            onClose();
        }
    }, [dispatch, isAddSuccess, isUpdateSuccess, onClose]);


    const onSubmit = (data) => {
        if (isEdit) {
            data.id = categoryInfo.value;
            dispatch(editQuestionCategory(data));
        } else {
            dispatch(createQuestionCategory(data));
        }
    };

    const isLoading = !!(isAdding || isUpdating);
    return (
        <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name='name'
                defaultValue=''
                rules={assQuestionCategoryPattern.name}
                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                    <FormControl fullWidth required error={!!(invalid && error.message)}>
                        <FormLabel>Category Name</FormLabel>
                        <TextField
                            autoFocus
                            type='text'
                            size={'small'}
                            placeholder='Enter a name'
                            value={value}
                            onChange={onChange}
                            error={!!(invalid && error.message)}
                            helperText={invalid && error.message}
                        />
                    </FormControl>
                )}
            />

            <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'flex-end'} mt={2} spacing={1}>
                <Button variant={'text'} size={'small'} onClick={onClose}>Cancel</Button>
                <Button
                    type={"submit"}
                    size={'small'}
                    variant={'contained'}
                    disabled={isLoading}
                >
                    {isEdit ? "Update" : "Add"}
                </Button>
            </Stack>

        </Box>
    );
};

export default AddOrEditCategory;