import React from "react";
import CircleLoader from "../../global/Loader/CircleLoader";
import useGetAdminByAuthCheck from "./useGetAdminByAuthCheck";

// -----------------------------------------------
// ## check auth info
// ## admin auth verify
// ## until global Loader showing
// ------------------------------------------------

const AuthCheckerLayout = ({ children }) => {
    const hasCheckedJobSeekerAuth = useGetAdminByAuthCheck();

    if (hasCheckedJobSeekerAuth) {
        return children;
    } else {
        return <CircleLoader />;
    }
};

export default AuthCheckerLayout;
