import {all, call, put, takeLatest} from "redux-saga/effects";
import JobPostListService from "../../services/jobPostList.service";
import {
    getJobPostListFailed,
    getJobPostListSuccess,
    updateJobPostPublishStatusFailed,
    updateJobPostPublishStatusSuccess
} from "./jobPostListSlice";
import {showErrorToaster, showSuccessToaster} from "../../helpers/utils/toaster";

function* jobPostListWatcher() {
    yield takeLatest("jobPostList/getJobPostList", getJobPostListSaga);
    yield takeLatest("jobPostList/updateJobPostPublishStatus", updateJobPostPublishStatusSaga);
}

function* getJobPostListSaga(action) {
    try {
        const response = yield call(JobPostListService.getJobPostList, action.payload);

        if (response.status) {
            yield put(getJobPostListSuccess(response.data));
        } else {
            yield put(getJobPostListFailed(response.message));
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* updateJobPostPublishStatusSaga(action) {
    try {
        const response = yield call(JobPostListService.updateJobPostPublishStatus, action.payload);

        if (response.status) {
            yield put(updateJobPostPublishStatusSuccess(action.payload));
            showSuccessToaster(response.message)
        } else {
            yield put(updateJobPostPublishStatusFailed(response.message));
            showErrorToaster(response.message)
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* jobPostListSaga() {
    yield all([jobPostListWatcher()]);
}
