import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import PeopleIcon from "@mui/icons-material/People";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import { pageRoutes } from "../../../helpers/constant/pageRoutes";
import ArticleIcon from "@mui/icons-material/Article";
import AssessmentIcon from "@mui/icons-material/Assessment";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { ExitToApp } from "@mui/icons-material";
import Box from "@mui/material/Box";

export const sidebarMenuList = [
  {
    href: `${pageRoutes.companies.path}`,
    title: "Companies",
    icon: <BusinessOutlinedIcon fontSize="small" />,
  },
  {
    href: `${pageRoutes.jobSeeker.path}`,
    title: "Job seekers",
    icon: <PeopleIcon fontSize="small" />,
  },
  {
    href: `${pageRoutes.jobs.path}`,
    title: "Jobs",
    icon: <WorkHistoryIcon fontSize="small" />,
  },
  {
    href: `${pageRoutes.templates.path}`,
    title: "Templates",
    icon: <ArticleIcon fontSize="small" />,
  },
  {
    title: "Assessments",
    icon: <AssessmentIcon fontSize="small" />,
    href: `${pageRoutes.assessments.path}`,
    subMenus: [
      {
        href: `${pageRoutes.assessmentsTemplates.path}`,
        title: "Templates",
        icon: <AssignmentTurnedInIcon fontSize="small" />,
      },
      {
        href: `${pageRoutes.assessmentsQuestions.path}`,
        title: "Questions",
        icon: <QuestionMarkIcon fontSize="small" />,
      },
    ],
  },
];

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  paddingTop: theme.spacing(0.75),
  paddingBottom: theme.spacing(0.75),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginBottom: theme.spacing(0.5),
  "& .MuiListItemIcon-root": {
    minWidth: theme.spacing(4),
  },
}));

const hasChildren = (menu) => {
  const { subMenus: children } = menu || {};
  return Array.isArray(children);
};

const DashboardSidebar = () => {
  return (
    <List component="nav" disablePadding sx={{ pt: 0.5 }}>
      {sidebarMenuList.map((menu, index) => {
        return <MenuItem key={index} menu={menu} />;
      })}
    </List>
  );
};

const MenuItem = ({ menu }) => {
  const Component = hasChildren(menu) ? MultiLevel : SingleLevel;
  return <Component menu={menu} />;
};

const SingleLevel = ({ menu }) => {
  const { pathname } = useLocation();
  const active = pathname === menu.href;

  return (
    <Link to={menu.href}>
      <ListItemButtonStyled selected={active}>
        <ListItemIcon>{menu.icon}</ListItemIcon>
        <ListItemText primary={menu.title} />
      </ListItemButtonStyled>
    </Link>
  );
};

const MultiLevel = ({ menu }) => {
  const { subMenus: children } = menu;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleLogOut = () => {
    if (window.employerLogOut) window.employerLogOut();
  };

  return (
    <>
      <ListItemButtonStyled onClick={handleClick}>
        <ListItemIcon>{menu.icon}</ListItemIcon>
        <ListItemText primary={menu.title} />
        {open ? (
          <ExpandLessIcon fontSize={"small"} color={"action"} />
        ) : (
          <ExpandMoreIcon fontSize={"small"} color={"action"} />
        )}
      </ListItemButtonStyled>

      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} menu={child} />
          ))}
        </List>
      </Collapse>
      <Box sx={{ mt: 6 }}>
        <ListItemButtonStyled onClick={handleLogOut}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText>Sign Out</ListItemText>
        </ListItemButtonStyled>
      </Box>
    </>
  );
};

export default DashboardSidebar;
