import React from 'react';
import {Paper} from "@mui/material";
import MessageContentHeader from "./MessageContentHeader";
import MessageContentList from "./MessageContentList";

const MessageContent = () => {
    return (
        <Paper elevation={0}>
            <MessageContentHeader />
            <MessageContentList />
        </Paper>
    );
};

export default MessageContent;