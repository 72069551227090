import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAdminAuthData} from "../../state/adminAuth/adminAuthSelector";
import cookieService from "../../services/cookie.service";
import {getAdminData} from "../../state/adminAuth/adminAuthSlice";

const useGetAdminByAuthCheck = () => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    const adminData = useSelector(selectAdminAuthData);

    useEffect(() => {
        const accessToken = cookieService.getCookie(process.env.REACT_APP_ADMIN_ACCESS_TOKEN);

        if (accessToken && adminData === null) {
            dispatch(getAdminData());
        }

        setChecked(true);
    }, [adminData, dispatch]);

    return checked;
};

export default useGetAdminByAuthCheck;
