export const pageRoutes = {
    companies: {
        path: "/dashboard/companies",
    },
    jobSeeker: {
        path: "/dashboard/job-seeker",
    },
    blogs: {
        path: "/dashboard/blogs",
    },
    jobs: {
        path: "/dashboard/jobs",
    },
    report: {
        path: '/dashboard/report'
    },
    templates: {
      path: '/dashboard/templates'
    },
    assessments: {
        path: '/dashboard/assessments'
    },
    assessmentsTemplates: {
        path: "/dashboard/assessments-templates",
    },
    assessmentsQuestions: {
        path: "/dashboard/assessments-questions",
    },
    messageContent: {
        path: '/dashboard/message-content'
    },
    login: {
        path: '/'
    },
};
