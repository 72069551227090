import React from 'react';
import {Box, Paper} from '@mui/material';
import ReportHeader from "./ReportHeader";
import ReportChart from "./ReportChart";


const Reports = () => {
  return (
    <Box pb={0.5}>
      <ReportHeader />
      <Paper elevation={0} sx={{p: 2, width: '100%', my: 2}}>
        <ReportChart />
      </Paper>
    </Box>
  );
};

export default Reports;
