import React, {useEffect, useState} from "react";
import Select from "react-select";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import {DeleteOutline} from "@mui/icons-material"
import {
    Alert,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    Radio,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import CustomEditor from "../../../common/Editor/CustomEditor";
import {showErrorToaster} from "../../../helpers/utils/toaster";
import {assQuestionPattern} from "../../../helpers/constant/registerPattern";
import {QUESTION_TYPE, QUESTION_WEIGHT} from "../../../helpers/constant/assessmentConst";
import {
    createAssQuestion,
    editAssQuestion,
    resetCreateAssQuestion,
    resetEditAssQuestion
} from "../../../state/assQuestion/assQuestionSlice";
import {selectQuestionCategoryState} from "../../../state/questionCategory/questionCategorySelector";
import {selectAssQuestionState} from "../../../state/assQuestion/assQuestionSelector";

const createDefaultOption = () => {
    return {
        content: "",
        point: 0,
    };
};

const AddOrEditQuestion = ({questionInfo, onClose, isEdit = false}) => {
    const dispatch = useDispatch();
    const {isAdding, isAddSuccess, isUpdating, isUpdateSuccess} = useSelector(selectAssQuestionState);
    const {data: categoryList} = useSelector(selectQuestionCategoryState);
    const [correctOptionIndexes, setCorrectOptionIndexes] = useState([]);
    const [checkError, setCheckError] = useState("");

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        watch
    } = useForm({
        mode: "onSubmit",
    });

    const {
        fields = [],
        remove: onRemoveOption,
        insert: onInsertOption,
    } = useFieldArray({
        control,
        name: "options", // unique name for your Field Array
    });

    useEffect(() => {
        if (isEdit && questionInfo?.id) {
            setValue("content", questionInfo.content);
            setValue("difficulty", {value: questionInfo.difficulty, label: questionInfo.difficulty});
            setValue("questionType", {value: questionInfo.questionType, label: questionInfo.questionType});
            const findCategory = categoryList.find(category => category.value === questionInfo.categoryId);

            const {value, subCategories = []} = findCategory || {};
            if(value) {
                setValue("category", findCategory);
                setValue("subCategory", subCategories?.find(subCategory => subCategory.value ===  questionInfo.subCategoryId));
            }
            if(questionInfo.questionType === "SINGLE_CHOICE" || questionInfo.questionType === "MULTIPLE_CHOICE") {
                setValue("options", questionInfo.options);
                const correctIndexes = [];
                questionInfo.options?.forEach(option => {
                    if(option.point === 1) correctIndexes.push(option.orderIndex);
                });
                setCorrectOptionIndexes(correctIndexes);
            }

        } else {
            handleInsertOption(0);
            handleInsertOption(1);
        }
    }, [isEdit, questionInfo]);

    useEffect(() => {
        if (isAddSuccess || isUpdateSuccess) {
            dispatch(resetCreateAssQuestion());
            dispatch(resetEditAssQuestion());
            onClose();
        }
    }, [dispatch, isAddSuccess, isUpdateSuccess, onClose]);

    const handleInsertOption = (index = 0) => {
        const defaultOption = createDefaultOption();
        onInsertOption(index, defaultOption);
    };

    const handleCorrectOption = (index) => {
        const questionType = getValues("questionType")?.value;
        if (questionType === "SINGLE_CHOICE") {
            setCorrectOptionIndexes([index]);
            watch();
        }
        if (questionType === "MULTIPLE_CHOICE") {
            let cloneIndexes = correctOptionIndexes.slice(0);

            if (cloneIndexes.includes(index)) {
                cloneIndexes = cloneIndexes.filter(eachIndex => eachIndex !== index);
            } else {
                cloneIndexes.push(index)
            }

            setCorrectOptionIndexes(cloneIndexes);
            watch();
        }
    }

    const onSubmit = (data) => {
        data.categoryId = data.category?.value;
        data.subCategoryId = data.subCategory?.value;
        data.difficulty = data.difficulty?.value;
        data.questionType = data.questionType?.value;

        if(data.questionType === "SINGLE_CHOICE" || data.questionType === "MULTIPLE_CHOICE") {
            let hasAnyChecked = false;
            data.options = data.options.map((option, index) => {
                const point = correctOptionIndexes.includes(index) ? 1 : 0
                if(!hasAnyChecked && point) {
                    hasAnyChecked = true;
                }
                return {...option, point: point, orderIndex: index}
            })

            if(!hasAnyChecked) {
                setCheckError("Please choose the correct option");
                return false;
            } else {
                setCheckError("");
            }
        } else {
            delete data.options;
        }

        delete data.category;
        delete data.subCategory;

        if (isEdit) {
            data.id = questionInfo.id;
            dispatch(editAssQuestion(data));
        } else {
            dispatch(createAssQuestion(data));
        }

        // console.log("data::: ", data);
    };

    watch("category");
    watch("questionType");
    const subCategoryList = getValues("category")?.value ? getValues("category").subCategories : [];
    const isLoading = !!(isAdding || isUpdating);

    return (
        <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <Grid container item spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Controller
                        control={control}
                        name='content'
                        defaultValue=''
                        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                            <FormControl fullWidth required error={!!(invalid && error.message)}>
                                <FormLabel>Question</FormLabel>
                                <CustomEditor
                                    placeholder={"write here.."}
                                    value={value}
                                    onChange={onChange}
                                />
                                {invalid && error.message && <FormHelperText>{error.message}</FormHelperText>}
                            </FormControl>
                        )}
                        rules={assQuestionPattern.content}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Controller
                        control={control}
                        name='category'
                        defaultValue=''
                        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                            <FormControl fullWidth required error={!!(invalid && error.message)}>
                                <FormLabel>Category</FormLabel>
                                <Select
                                    className={"select-dropdown-menu select-dropdown-menu-custom"}
                                    classNamePrefix={"select-dropdown-styles"}
                                    isMulti={false}
                                    isSearchable={true}
                                    menuPlacement='bottom'
                                    options={categoryList}
                                    value={value}
                                    onChange={(newValue) => {
                                        onChange(newValue);
                                        setValue("subCategory", null);
                                    }}
                                />
                                <FormHelperText error={invalid}>{invalid && error.message}</FormHelperText>
                            </FormControl>
                        )}
                        rules={assQuestionPattern.category}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Controller
                        control={control}
                        name='subCategory'
                        defaultValue=''
                        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                            <FormControl fullWidth required error={!!(invalid && error.message)}>
                                <FormLabel>Sub Category</FormLabel>
                                <Select
                                    className={"select-dropdown-menu select-dropdown-menu-custom"}
                                    classNamePrefix={"select-dropdown-styles"}
                                    isMulti={false}
                                    isSearchable={true}
                                    menuPlacement='bottom'
                                    options={subCategoryList}
                                    value={value}
                                    onChange={onChange}
                                />
                                <FormHelperText error={invalid}>{invalid && error.message}</FormHelperText>
                            </FormControl>
                        )}
                        rules={assQuestionPattern.subCategory}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Controller
                        control={control}
                        name='difficulty'
                        defaultValue=''
                        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                            <FormControl fullWidth required error={!!(invalid && error.message)}>
                                <FormLabel>Weight</FormLabel>
                                <Select
                                    className={"select-dropdown-menu select-dropdown-menu-custom"}
                                    classNamePrefix={"select-dropdown-styles"}
                                    isMulti={false}
                                    isSearchable={false}
                                    menuPlacement='bottom'
                                    options={QUESTION_WEIGHT}
                                    value={value}
                                    onChange={onChange}
                                />
                                <FormHelperText error={invalid}>{invalid && error.message}</FormHelperText>
                            </FormControl>
                        )}
                        rules={assQuestionPattern.difficulty}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Controller
                        control={control}
                        name='questionType'
                        defaultValue=''
                        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                            <FormControl fullWidth required error={!!(invalid && error.message)}>
                                <FormLabel>Question Type</FormLabel>
                                <Select
                                    className={"select-dropdown-menu select-dropdown-menu-custom"}
                                    classNamePrefix={"select-dropdown-styles"}
                                    isMulti={false}
                                    isSearchable={false}
                                    menuPlacement='top'
                                    isDisabled={isEdit}
                                    options={QUESTION_TYPE}
                                    value={value}
                                    onChange={(newValue) => {
                                        onChange(newValue);
                                        if(newValue?.value === "SINGLE_CHOICE") setCorrectOptionIndexes([]);
                                    }}
                                />
                                <FormHelperText error={invalid}>{invalid && error.message}</FormHelperText>
                            </FormControl>
                        )}
                        rules={assQuestionPattern.questionType}
                    />
                </Grid>

                {getValues("questionType") && (getValues("questionType").value === "SINGLE_CHOICE" || getValues("questionType").value === "MULTIPLE_CHOICE") && (
                    <Grid item xs={12} sm={12}>
                        <Stack spacing={2}>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <Typography variant={"subtitle1"}>Manage Options</Typography>
                                <Button sx={{p: 2}} variant={"outlined"} size={"small"} endIcon={<AddIcon />} onClick={() => handleInsertOption(fields?.length)}>
                                    Add Option
                                </Button>
                            </Stack>
                            {checkError && <Alert severity="error">{checkError}</Alert>}
                            {fields.map((field, index) => {
                                const {id} = field || {};

                                const checked = correctOptionIndexes.includes(index);
                                return (
                                    <Stack key={id} direction={"row"} alignItems={"center"} spacing={1}>
                                        {getValues("questionType").value === "SINGLE_CHOICE" && (
                                            <Radio
                                                checked={checked}
                                                onClick={() => handleCorrectOption(index)}
                                                value={"index_" + index}
                                            />
                                        )}
                                        {getValues("questionType").value === "MULTIPLE_CHOICE" && (
                                            <Checkbox
                                                color={"secondary"}
                                                checked={checked}
                                                onChange={() => handleCorrectOption(index)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        )}


                                        <Controller
                                            control={control}
                                            name={`options.${index}.content`}
                                            render={({field, fieldState: {invalid, error}}) => (
                                                <FormControl fullWidth error={!!(invalid && error.message)}>
                                                    <TextField
                                                        type='text'
                                                        size={"small"}
                                                        placeholder='type option...'
                                                        multiline
                                                        maxRows={6}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <IconButton
                                                                    edge='end'
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (fields.length > 2) onRemoveOption(index);
                                                                        else showErrorToaster("Minimum 2 option is required!");
                                                                    }}
                                                                >
                                                                    <DeleteOutline fontSize={"small"}/>
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        {...field}
                                                    />
                                                    <FormHelperText>{invalid && error.message}</FormHelperText>
                                                </FormControl>
                                            )}
                                            rules={assQuestionPattern.optionContent}
                                        />
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </Grid>
                )}

            </Grid>

            <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} mt={2}>
                <Button variant={"text"} onClick={onClose} size={"small"}>
                    Cancel
                </Button>
                <Button type={"submit"} disabled={isLoading} variant={"contained"} size={"small"}>
                    {isEdit ? "Update" : "Add"} Question {isLoading && "..."}
                </Button>
            </Stack>
        </Box>
    );
};

export default AddOrEditQuestion;