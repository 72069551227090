import React from 'react';
import Select from "react-select";
import {Button, FormControl, FormLabel, Stack} from "@mui/material";
import CustomEditor from "./Editor/CustomEditor";

const MessageContentForm = ({close}) => {
    return (
        <Stack direction={'column'} spacing={3}>
            <FormControl fullWidth>
                <FormLabel>Template</FormLabel>
                <Select placeholder={'Select template'}
                        className={"select-dropdown-menu select-dropdown-menu-custom"}
                        classNamePrefix={"select-dropdown-styles"}
                        options={[
                    {
                        label: 'Company account block',
                        value: 'template 1'
                    },
                    {
                        label: 'Job seeker account block',
                        value: 'template 2'
                    },
                    {
                        label: 'Job Post Rejected',
                        value: 'template 3'
                    }
                ]} isSearchable={true}/>
            </FormControl>
            <FormControl fullWidth>
                <FormLabel required>Message</FormLabel>
                <CustomEditor/>
            </FormControl>
            <Stack direction={"row"} justifyContent={"flex-end"} mt={4} spacing={1.3}>
                <Button variant={"text"} onClick={close}>
                    Cancel
                </Button>
                <Button type={"submit"} variant={"contained"} size={'large'}>
                    Set Message
                </Button>
            </Stack>
        </Stack>
    );
};

export default MessageContentForm;