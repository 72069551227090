import React from "react";
import Select from "react-select";
import {useDispatch} from "react-redux";
import {FormControl, FormLabel, Stack} from "@mui/material";
import {addFilterDifficulty, addFilterQuestionType} from "../../../state/assQuestion/assQuestionSlice";
import {QUESTION_TYPE, QUESTION_WEIGHT} from "../../../helpers/constant/assessmentConst";


const QuestionsHeader = () => {
    const dispatch = useDispatch();

    const onSelectDifficulty = (option) => {
        dispatch(addFilterDifficulty(option?.value))
    }

    const onSelectQuestionType = (option) => {
        dispatch(addFilterQuestionType(option?.value))
    }

    return (
        <>
            <Stack direction={"row"} spacing={1} mb={4} justifyContent={"flex-end"} alignItems={"center"} mt={2} mr={2}>
                <FormControl sx={{width: 220}}>
                    <FormLabel>Filter By Weight</FormLabel>
                    <Select
                        className={"select-dropdown-menu select-dropdown-menu-custom"}
                        classNamePrefix={"select-dropdown-styles"}
                        isMulti={false}
                        isSearchable={false}
                        menuPlacement='bottom'
                        defaultValue={{label: "All", value: ""}}
                        options={[{label: "All", value: ""}, ...QUESTION_WEIGHT]}
                        onChange={onSelectDifficulty}
                    />
                </FormControl>
                <FormControl sx={{width: 220}}>
                    <FormLabel>Filter By Type</FormLabel>
                    <Select
                        className={"select-dropdown-menu select-dropdown-menu-custom"}
                        classNamePrefix={"select-dropdown-styles"}
                        isMulti={false}
                        isSearchable={false}
                        menuPlacement='bottom'
                        defaultValue={{label: "All", value: ""}}
                        options={[{label: "All", value: ""}, ...QUESTION_TYPE]}
                        onChange={onSelectQuestionType}
                    />
                </FormControl>
            </Stack>
        </>
    );
};

export default QuestionsHeader;